/* eslint-disable */
import React,{ useState } from "react";
import { Dialog, Grid, Button, SvgIcon, Box } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiTypography from "../../Shared/Typography/MuiTypography";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";


const buttonstyle = {
  color: "#FFFFFF",
  backgroundColor: "#142A39",
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: "none ",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: "column",
  },
}))(MuiDialogActions);

export default function OfflinePopup(props) {
  const { t } = useTranslation();
  const [open,setopen] = useState(props.open);
  const handleClose =()=>{
    setopen(false);
    props.closeofflinepopup();
  }
  return (
    <Dialog
      // fullWidth={true}
      onClose={()=>setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="false"
    >
       <div style={{width: 343}}>
      <DialogContent>
        <Grid container justify="center" alignItems="center">
          <SvgIcon viewBox="0 0 71 58" style={{ fontSize: 71 }}>
            <path
              d="M4.8958 0.199997L0.799805 4.264L5.5038 8.968C4.1278 9.8 2.7518 10.728 1.4398 11.72L7.1998 19.4C8.8958 18.12 10.6558 17 12.5118 15.976L19.6478 23.112C17.2798 24.2 15.0078 25.512 12.9598 27.08L18.7198 34.76C21.2158 32.904 24.0318 31.464 27.0398 30.504L35.1998 38.6C31.1998 38.824 27.4878 40.2 24.4798 42.44L35.9998 57.8L43.8718 47.336L54.3678 57.8L58.3998 53.704L4.8958 0.199997ZM35.9998 0.199997C29.1198 0.199997 22.5598 1.416 16.4798 3.624L24.1278 11.304C27.9998 10.312 31.9038 9.8 35.9998 9.8C46.8158 9.8 56.7998 13.352 64.7998 19.4L70.5598 11.72C60.9278 4.488 48.9918 0.199997 35.9998 0.199997ZM35.9998 19.4C34.7838 19.4 33.5998 19.4 32.4158 19.56L42.6238 29.8C46.5278 30.696 50.1758 32.424 53.2798 34.76L59.0398 27.08C52.6398 22.248 44.6398 19.4 35.9998 19.4Z"
              fill="#142A39"
            />
          </SvgIcon>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <MuiTypography
            fontSize="20px"
            fontFamily="Work Sans"
            lineHeight="24px"
            fontWeight={700}
            color="#142A39"
          >
            {t("You Are Offline")}
          </MuiTypography>
          
          <Box mt={3} />
          <MuiTypography
            fontSize="15px"
            fontFamily="Work Sans"
            lineHeight="24px"
            fontWeight={400}
            color="#142A39"
          >
            {t("Please check your network connection")}
          </MuiTypography>
          <MuiTypography
            fontSize="15px"
            fontFamily="Work Sans"
            lineHeight="24px"
            fontWeight={400}
            color="#142A39"
          >
            {t("and try again")}.
          </MuiTypography>
        </Grid>
      </DialogContent>
      <DialogActions disableSpacing={true}>
        <>
          <Button
            size="large"
            fullWidth
            variant="contained"
            onClick={handleClose}
            style={buttonstyle}
          >
            {t("Got it")}
          </Button>
        </>
      </DialogActions>
      </div>
    </Dialog>
  );
}
