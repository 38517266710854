/* eslint-disable */
import React, { useState, useEffect, useCallback ,useRef} from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, IconButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import KeyboardArrowLeftOutlinedIcon from "@material-ui/icons/KeyboardArrowLeftOutlined";
import Details_tab from "../Dashboard/details_tab";
import Overread_ReportDropdown from "../AppointmentDetails/overread_ReportDropdown";
import Followup_Dropdown from "../AppointmentDetails/followup_Dropdown";
import ConsulationSummary_tab from "../AppointmentDetails/consulationSummary_tab";
import OverReadReportNote from "../Appointments/Calendar/OverReadReportNote";
import { streamToPromise, getTimezoneName } from "../../Utilities/Utilities";
import apiservice from "../../api/services/apiservice.service";
import Alert from "../Shared/Alert/Alerts";
import * as API from '../../Services/API/actions';
import axios from "axios";
import { GoAPI } from "../../Axios/axios";
import { getToken } from "../../Auth/AuthUtilities";
import useResizeDetector from "use-resize-observer/polyfilled";
import _get from "lodash.get";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import Authorize from "../Authorize";
import debounce from "lodash.debounce";
import PreviewReportModal from "../AppointmentDetails/PreviewReportModal";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">&nbsp;</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

const AppointmentDetails = (props) => {
  let actions = null;
  const { t } = useTranslation();
  const [isEditable, setisEditable] = useState(false);
  const [previewReportModalOpen, setpreviewReportModalOpen] = useState(false);
  const [patientDetails,setpatientDetails] = useState({});
  const [tabvalue,settabvalue] = useState(0);
  const viewportRef2 = useRef(null);
  const [statistics, setstatistics] = useState([]);
  const [toggleRecordingInvert, setoggleRecordingInvert] = useState(false);
  const [appNotes, setappNotes] = useState("");
  const [consultationNote, setconsultationNote] = useState("");
  const [alertOn, setAlertOn] = useState(false);
  const [alert,setalert]  = useState(false);
  const [recording, setRecording] = useState([]);
  const [id, setid] = useState(0);
  const [isError,setisError] = useState(false);
  const [notesheight, setnotesheight] = useState(1000);
  const [overReadReport, setoverReadReport] = useState("");
  const [heartConditions, setheartConditions] = useState([]);
  const [followup, setfollowup] = useState("No Follow-up");
  const [consultationSummary, setconsultationSummary] = useState("");
  const [targetPlan, settargetPlan] = useState("");
  const [lastconsultationNote, setlastconsultationNote] = useState("");
  const [lastoverheadreportnote, setlastoverheadreportnote] = useState("");
  const [lasttreatmentPlannote, setlasttreatmentPlannote] = useState("");
  const [lastconsultationSummaryNotes, setlastconsultationSummaryNotes] = useState("");
  const mediawidth = useWidth();
  const memberId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const ekgRecordId = props.appointmentinfo !==null && props.appointmentinfo.ekgResults !==null ?
                      props.appointmentinfo.ekgResults[0].ekgRecordId : "";
  const [IsDisabled,setIsDisabled] = useState(false);
  const [ekgLoader, setekgLoader] = useState(false);
  const [isReportSubmitted,setReportStatus] = useState(false);
  let loading = useSelector((state) => state.appointments.loading);
  const timezoneValue = getTimezoneName();


  useEffect(()=>{
    if(isEditable){
      getConsulationNotes();
    }
  },[isEditable]);
  useEffect(()=>{
      if (
        ekgRecordId.length > 0 && isEditable) {
        loadEkgAction(ekgRecordId);
      }
    }
  ,[isEditable]);

  useEffect(()=>{
    let subscription = API.heartConditions().subscribe(res => {
      if(res.data.result!=null){
          let conditions = [{
              id:0,
              heartCondition:"Select EKG Interpretation",
              heartConditionAdditionalText:"",
              
            },...res.data.result];
            setheartConditions(conditions);
      }
        let heartConditions = res.data.result.sort(function(a, b){
          return a.id - b.id;
      });
    }, error => {
        console.log;
     })
return () => {
    if (subscription)
        subscription.unsubscribe()
}
  },[]);

  const handleResize = ({ height })=> {
    if (notesheight !== height) {
      setnotesheight(height);
    }
  }
  const updateconsultationSummary = (note) => {
    // auto save logic here
    setconsultationSummary(note.target.value);
  };

  const updatetargetplan = (note) => {
    // auto save logic here for update treatment plan
    settargetPlan(note.target.value);
  };
  const delay = 30;

  useResizeDetector({
    ref: viewportRef2,
    onResize: debounce(handleResize, delay, { maxWait: 1000 }),
  });


  const AUTOSAVE_INTERVAL = 3000;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastconsultationNote != consultationNote) {
             //consultation notes save
        API.setAppointmentNotes(
          teamId,
          memberId,
          props.appointmentId,
          decodeURI(consultationNote)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastconsultationNote(consultationNote);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer);
  }, [consultationNote]);

  useEffect(() => {
    const timer2 = setTimeout(() => {
      if (lastoverheadreportnote != overReadReport) {
        API.setOverReadReport(
          teamId,
          memberId,
          props.appointmentId,
          decodeURI(overReadReport)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastoverheadreportnote(overReadReport);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer2);
  }, [overReadReport]);

  useEffect(() => {
    const timer3 = setTimeout(() => {
      if (lasttreatmentPlannote != targetPlan) {
        API.settreatmentPlan(
          teamId,
          memberId,
          props.appointmentId,
          decodeURI(targetPlan)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlasttreatmentPlannote(targetPlan);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer3);
  }, [targetPlan]);


  useEffect(() => {
    const timer4 = setTimeout(() => {
      if (lastconsultationSummaryNotes != consultationSummary) {
             //consultation notes save
        API.setconsultationSummaryNotes(
          teamId,
          memberId,
          props.appointmentId,
          decodeURI(consultationSummary)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastconsultationSummaryNotes(consultationSummary);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer4);
  }, [consultationSummary]);

  const loadEkgAction = useCallback((recordingid) => {
    setekgLoader(true);
    let samps = GoAPI.get(`/i/v1/recordings/${recordingid}/samples`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    let recs = GoAPI.get(`/i/v1/recordings/${recordingid}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    axios
      .all([recs, samps])
      .then(
        axios.spread((...responses) => {
         // setloader(false);
         setekgLoader(false);
        setRecording({ ...responses[0].data, ...responses[1].data });
        setAlertOn(false);
        })
      )
      .catch((error) => {
        setAlertOn(true);
        setekgLoader(false);
        //setloader(false);
        setRecording({});
      });
  }, []);

  const getConsulationNotes =(() => {
    setconsultationNote("");
      let subscription = API.getAppointmentNotes(
        teamId,
        memberId,
        props.appointmentId
      ).subscribe(
        (res) => {
          if (res.data.appointmentNote) {
            setconsultationNote(decodeURI(res.data.appointmentNote));
            setlastconsultationNote(decodeURI(res.data.appointmentNote));
          } else setconsultationNote("");
        },
        // eslint-disable-next-line no-console
        (error) => console.log(error)
      );
      return () => {
        if (subscription)
            subscription.unsubscribe()
    }
  });

  const editReportHandler= async () => {
    setisEditable(true);
    loading = true;
    let offsetInMinutes = new Date().getTimezoneOffset();
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1.1/teams/${teamId}/clinicians/${memberId}/appointments/${props.appointmentId}/patient-detail?timeZoneOffset=${offsetInMinutes}&timeZone=${timezoneValue}`,
      })
    );
    const appointmentdetailsdata = await appointmentRequest;
    setpatientDetails(appointmentdetailsdata.data);
    loading = false;
  }

  const handlePreviewReportModalClose = () => {
    setpreviewReportModalOpen(false);
  };

  const changetabvalue =(event, newValue)=>{
    settabvalue(newValue);
  };

  const settoggleRecordingInvert = (prps) => {
    setoggleRecordingInvert(prps);
  };

  const updateConsultationNote = (note) => {
    setconsultationNote(note.target.value);
  };

  const handleidChange = (event) => {
    setid(event.target.value);
    if(event.target.value!==0){
      API.setheartConditionId(
        teamId,
        memberId,
        props.appointmentId,
        event.target.value
      ).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error)
      );
      let selectedCondition = heartConditions.filter(x=>x.id===event.target.value);
      setoverReadReport(selectedCondition.length!=0 && selectedCondition[0].heartConditionAdditionalText?selectedCondition[0].heartConditionAdditionalText:'');
    }
    else{
      setoverReadReport("");
    }
  };
  const updateoverReadReport = (note) => {
    setoverReadReport(note.target.value);
  };

  const handleperiodChange = (event) => {
    API.setdurationText(
      teamId,
      memberId,
      props.appointmentId,
      event.target.value
    ).subscribe(
      (res) => {
        // do nothing
      },
      (error) => console.log(error)
    );
    setfollowup(event.target.value);
  };
  const loadReport = () => {
    if(overReadReport.length!==0){
      setpreviewReportModalOpen(true);
      setisError(false);
      setalert("");
    } 
    else{
      setalert(
      <Alert
          date={new Date()}
          message={"Please select the EKG interpretation to proceed to the next step."}
        /> 
      );
      setisError(true);
    }
  };
  const handleClose =()=>{
    let body = {
      appointmentStatus:_get(props.appointmentinfo, "patientAppointment.appointmentStatusId"),
      consultationSummaryNotes: encodeURI(consultationSummary),
      followup: followup,
      heartConditionId: id,
      overReadReportNotes: encodeURI(overReadReport),
      patientId: _get(props.appointmentinfo, "patient.patientId"),
      treatmentPlan: encodeURI(targetPlan),
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone:getTimezoneName(),
    };
    
    if( consultationSummary.length===0){
      setalert(
        <Alert
            date={new Date()}
            message={"Please enter the Consultation Summary and Patient Note to proceed to the next step."}
          /> 
        );
    }
    else{
      setalert("");
      setIsDisabled(true);
      let subscription = API.updateReport_Physician(teamId,memberId,props.appointmentId,body).subscribe(res => {
        if(res.data.result!=null){
          swal({
            closeOnClickOutside: false,
            icon: "success",
            title:"Consultation Updated" ,
            text: "Consultation has been updated succesfully!",
            className:"telekardia",
            buttons: {
              confirm: {
                text: "OKAY",
                visible: true,
                className: "swal_ok swal_green",
                closeModal: true,
              },
            },
          }).then((value) => {
            setisEditable(false);
            setIsDisabled(false);
            setReportStatus(true);
            props.onReportSubmit();
          });
        };
      }, error => {
        setIsDisabled(false);
       });
    }    
return () => {
    if (subscription)
        subscription.unsubscribe()
  };
};
  const onClose =()=>{
    setisEditable(false);
    setIsDisabled(false);
    setReportStatus(false);
    
  }

  if (props.component === "popover") {
    actions = (
      <div className="telekardia">
        {(!isEditable || !isReportSubmitted) &&
          <button
            type="button"
            className="btn px-4 btn-outline-primary text-uppercase h40 f28_icon"
            onClick={isEditable && !isReportSubmitted ? onClose : props.handleClose}
          >
            <KeyboardArrowLeftOutlinedIcon /> Back
          </button>
        }
        {isEditable &&
          <>
          <button
            type="button"
            className="btn px-4 btn-outline-primary text-uppercase h40 ml-3"
            onClick={loadReport}
          >
            Preview REPORT
          </button>
          <button
            type="button"
            className="btn px-4 btn_teal text-uppercase h40 ml-3"
            onClick={handleClose}
            disabled = {IsDisabled}
          >
            SEND REPORT
          </button>
          </>
      }
        {(_get(props.appointmentinfo, "appointmentStatusId") === "appointmentStatusId5" || _get(props.appointmentinfo, "appointmentStatusId") === "appointmentStatusId10")
          &&  !isEditable && !isReportSubmitted &&
            <button
              type="button"
              className="btn px-4 text-camelcase h40 ml-3 swal_green" 
              onClick={editReportHandler}
            >
              {t("Edit Report")}
            </button>
        }
        {(props["isfuturescheduledappintment"] === undefined ||
          props.isfuturescheduledappintment === true) && (
          <Authorize>
            <button
              type="button"
              className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
              onClick={props.onSubmit}
            >
              REASSIGN CONSULTATION
            </button>
          </Authorize>
        )}
      </div>
    );
  }


  return (
    <>
      <Dialog 
        fullWidth={true}
        maxWidth={"xl"}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.show}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        {props.component === "popover" && (
          <DialogTitle
            id="customized-dialog-title"
            onClose={isEditable?null:props.handleClose}
          />
        )}

        <DialogContent dividers>
          <div className={loading ? "filtering" : null}>
            <div className={loading ? "spinner-border" : null}></div>
            {
            !isEditable?
              props.children
            :
              <div className="telekardia">
                <div className="m-4">{alert}</div>
                <div className="container-fluid">
                  {previewReportModalOpen && (
                    <PreviewReportModal
                    open={previewReportModalOpen}
                    memberId={memberId}
                    patientId={patientDetails.patient.patientId}
                    appointmentId={props.appointmentId}
                    handlePreviewReportModalClose={handlePreviewReportModalClose}
                  />
                  )}
                  <div className={ekgLoader ? "filtering" : null}>
                    <div className={ekgLoader ? "spinner-border" : null}/>
                    <div className="row m-0" style={{ display: "-ms-flexbox" }}>
                        <div className="col-12 col-xl-7">
                          <div ref={viewportRef2}>
                                    <Details_tab
                                      value={tabvalue}
                                      patientDetails={patientDetails}
                                      appointmentId={props.appointmentId}
                                      handleChange={changetabvalue}
                                      setoggleRecordingInvert={settoggleRecordingInvert}
                                      stats={statistics}
                                      toggleRecordingInvert={toggleRecordingInvert}
                                      alertOn={alertOn}
                                      appNotes={appNotes}
                                      recording={recording}
                                    />
                          </div>
                        </div>
                        <div
                          className={
                            mediawidth === "xl" || mediawidth === "lg"
                              ? "col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3"
                              : "col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3 order-first"
                          }
                        >
                          <div className="row no-gutters">
                              <div className="col-12 bg_white p-4">
                                <h5 className="head">{t("My Notes")}</h5>
                                <textarea
                                  onChange={updateConsultationNote}
                                  className={
                                    (mediawidth === "xl" || mediawidth === "lg")
                                      ? "form-control bg_grey border-0"
                                      : "form-control bg_grey height_230 border-0"
                                  }
                                  style={{
                                    height:
                                        (mediawidth === "xl" || mediawidth === "lg") &&
                                        notesheight - 457 - 381 - 77 - 30,
                                  }}
                                  placeholder="Write your personal notes here. They will not be shared with the patient."
                                  value={consultationNote}
                                />
                              </div>
                          </div>
                          <div className="row no-gutters">
                            <div className="col-12 height_381 bg_white p-4  mt-3">
                              <div className="row mb-4">
                                <div className="col-5 d-flex align-items-center">
                                  <h5 className="head">
                                      {t("EKG Review")}
                                  </h5>
                                </div>
                                <div className="col-7">
                                  <Overread_ReportDropdown
                                      appointmentId={props.appointmentId}
                                      id={id}
                                      error={isError}
                                      setError={setisError}
                                      handleidChange={handleidChange}
                                      heartConditions={heartConditions}
                                    />
                                </div>
                                <div className="col-12">
                                  <OverReadReportNote
                                      appointmentId={props.appointmentId}
                                      id={id}
                                      updateoverReadReport={updateoverReadReport}
                                      overReadReport={overReadReport}
                                    />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row no-gutters">
                            <div className="col-12 height_457 bg_white p-4 my-3">
                              <div className="row mb-4">
                                <div className="col-7 d-flex align-items-center">
                                  <h5 className="head">{t("Patient Note")}</h5>
                                </div>
                                <div className="col-5">
                                  <Followup_Dropdown
                                      period={followup}
                                      handleChange={handleperiodChange}
                                      appointmentId={props.appointmentId}
                                    />
                                </div>
                                <div className="col-12">
                                  <ConsulationSummary_tab
                                      consultationSummary={consultationSummary}
                                      updateconsultationSummary={
                                        updateconsultationSummary
                                      }
                                      treatmentPlan={targetPlan}
                                      updatetreatmentPlan={updatetargetplan}
                                      appointmentId={props.appointmentId}
                                    />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
              
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <div className="py-4 px-5">{actions}</div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppointmentDetails;
