import React, { useEffect, useState } from "react";
import Alert from "../Shared/Alert/AvailabilityAlert";
import * as API from "./../../Services/API/actions";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import swal from 'sweetalert'

const AppointmentNotes = (props) => {
  const { t } = useTranslation();
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const [, setloaded] = useState(true);
  const [version,setversion] = useState(0);
  const [consultationNote, setconsultationNote] = useState("");
  const [lastconsultationNote, setlastconsultationNote] = React.useState("");

  useEffect(async () => {
    if (Object(props.id)!== props.id && props.id) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      await API.getAppointmentNotes(
        teamId,
        profileId,
        props.id
      ).subscribe(
        (res) => {
          setversion(res.data.version);
          if (res.data.appointmentNote) {
            setconsultationNote(decodeURI(res.data.appointmentNote));
            setlastconsultationNote(decodeURI(res.data.appointmentNote));
            
          } else setconsultationNote("");
        },
        // eslint-disable-next-line no-console
        (error) => console.log(error)
      );
    }
  }, [props.id]);

  const AUTOSAVE_INTERVAL = 1000;
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (lastconsultationNote !== consultationNote) {
        //consultation notes save
        await API.setAppointmentNotes(
          teamId,
          profileId,
          props.id,
          decodeURI(consultationNote),
          version
        ).subscribe(
          (res) => {
            if (res.data.result.reassigned || res.data.result.rescheduled 
              || res.data.result.cancelled || res.data.result.closed) {
              swal({
                closeOnClickOutside: false,
                title: res.data.result.title,
                text: res.data.result.subTitle,
                className: "telekardia",
                buttons: {
                  cancel: {
                    visible: true,
                    text:"",
                    className: "swal_cancel swal_black swal_close",
                    closeModal: true,
                  },
                  confirm: {
                    text: "OKAY",
                    visible: true,
                    className: "swal_ok swal_green",
                    closeModal: true,
                  },
                },
              }).then((res) => {
               
                  props.loader();
               
              });
            }
            //setalert(null);
            setloaded(true);
            // setalert(
            //   <Alert
            //     severity="success"
            //     title="Notes Saved Succesfully"
            //     component="notes"
            //   ></Alert>
            // );
          },
          (error) => {
            setalert(null);
            setloaded(false);
            setalert(
              <Alert
                severity="error"
                title="Unable to process this request due to internal server error"
                component="notes"
              ></Alert>
            );
          }
        );
        setlastconsultationNote(consultationNote);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultationNote]);

  const handleInputChange = (event) => {
    setconsultationNote(event.target.value);
  };
  const [alert, setalert] = useState();

  return (
    <div>
      <h5 className="heading">{t("My Notes")}</h5>
      <div className="row no-gutters w-100">
        {props.appointments && props.appointments.length > 0 ? (
          <div className="col-12">
            <div className="form-group">
              <textarea
                name="apnotes"
                className="form-control border-0 appt_notes"
                rows="10"
                value={consultationNote}
                placeholder="Write your personal notes here. They will not be shared with the patient."
                onChange={handleInputChange}
              />
            </div>
            {alert}
            <Box p={1} />
            <div className="d-flex align-items-end justify-content-end">
              {/* <button
                                    type="button"
                                    className="btn btn_teal py-2 px-4" onClick={handleClick} disabled={loaded ? false : true}>
                                    {t("SAVE")}
                                </button> */}
            </div>
          </div>
        ) : (
          <div>
            <p className="mb-0">
              {t("Notes cannot be added when there are no consultations")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentNotes;
