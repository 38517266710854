import React from 'react'
//import styles from './EkgRecording.scss'
import * as Lib from '../Constants/RecordingConstants'
import { paint1LeadWaveform, paint6LeadWaveform, paintGrid, renderCalpulse } from './../utils'
import MultilaneIndicator from '../utils/MultilaneIndicator/MultilaneIndicator'

import { SCROLLBAR_HEIGHT } from '../Constants/constants'

class EkgRecording extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sliceSize: 0,
      rows: 0,
      stripWidthPx: 0,
      rowSeconds: props.rowSeconds,
      recordingHeight: 0,
      rowHeight: Lib.MV_BANDWIDTH * Lib.PX_PER_MV * 2,
    }

    this.ekgCanvas = React.createRef()
    this.renderChart = this.renderChart.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ((prevState === null ||
      nextProps.width !== prevState.width ||
      nextProps.rowSeconds !== prevState.rowSeconds ||
      nextProps.calpulse !== prevState.calpulse) &&
      nextProps.recording.enhanced) {
      const frequency = nextProps.recording.enhanced.frequency || Lib.SAMPLES_PER_SECOND
      const newSliceSize = nextProps.rowSeconds * frequency
      // calpulse is half a second
      const calpulseSamps = 0.5 * frequency
      const sampsCount = nextProps.calpulse ?
        nextProps.recording.enhanced.samples.leadI.length + calpulseSamps :
        nextProps.recording.enhanced.samples.leadI.length
      let newRows = Math.ceil(sampsCount / newSliceSize)
      let newRecordingHeight = newRows * prevState.rowHeight
      if (nextProps.is6l) {
        newRows = 6
        newRecordingHeight = (prevState.rowHeight * 3) + ((Lib.MV_BANDWIDTH * Lib.PX_PER_MV) / 2)
      }
      return {
        sliceSize: newSliceSize,
        rows: newRows,
        stripWidthPx: nextProps.rowSeconds * Lib.PX_PER_SECOND,
        rowSeconds: nextProps.rowSeconds,
        recordingHeight: newRecordingHeight,
      }
    }
    return null
  }

  componentDidMount() {
    this.renderChart()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.recording.id !== prevProps.recording.id ||
      this.props.inverted !== prevProps.inverted ||
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height ||
      this.props.rowSeconds !== prevProps.rowSeconds) {
      this.renderChart()
    }
  }

  renderChart() {
    /* eslint-disable prefer-template */
    // Only showing enhanced recordings
    if (this.props.recording.enhanced) {
      const canvas = this.ekgCanvas.current
      const {
        samples,
        frequency = Lib.SAMPLES_PER_SECOND,
      } = this.props.recording.enhanced
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      const pixelRatio = window.devicePixelRatio || 1
      let oldWidth = this.props.width
      let fullHeight = this.state.recordingHeight > this.props.height ? this.state.recordingHeight : this.props.height

      if (this.props.is6l) { // LAUREN should be recording type = 6 leads
        oldWidth = Math.ceil((samples.leadI.length + 150) / frequency) * Lib.PX_PER_SECOND
        fullHeight -= SCROLLBAR_HEIGHT
      }

      const oldHeight = fullHeight
      canvas.width = oldWidth * pixelRatio
      canvas.height = oldHeight * pixelRatio
      canvas.style.width = oldWidth + 'px'
      canvas.style.height = oldHeight + 'px'
      context.scale(pixelRatio, pixelRatio)

      // for drawing full wavelength on one line
      // adding half a second on the end to make up for calpulse
      const totalSeconds = Math.ceil((this.props.recording.enhanced.samples.leadI.length + 150) / frequency)
      const stripWidthPx6 = (samples.leadI.length / frequency) * Lib.PX_PER_SECOND


      if (!this.props.is6l) {
        paintGrid(
          context,
          this.state.rowSeconds,
          fullHeight,
          this.state.stripWidthPx,
          1
        )
        paint1LeadWaveform(
          samples.leadI,
          this.state.rowHeight,
          context,
          1,
          this.props.inverted,
          this.state.sliceSize,
          this.state.stripWidthPx,
          this.state.rows
        )
      } else {
        paintGrid(
          context,
          totalSeconds,
          fullHeight,
          oldWidth,
          1
        )
        paint6LeadWaveform(
          samples,
          this.state.rowHeight,
          context,
          1,
          this.props.inverted,
          samples.leadI.length,
          stripWidthPx6
        )
      }
    }
  }

  render() {
    const {
      height,
      width,
      recording,
      calpulse,
      rowSeconds,
      is6l,
    } = this.props

    if (!recording) {
      return null
    }

    const {
      recordingHeight,
      sliceSize,
      stripWidthPx,
      rows,
      rowHeight,
    } = this.state
    const fullHeight = recordingHeight > height ? recordingHeight : height

    const sixCalpulseRender = () => {
      const content = []
      for (let i = 0; i < 6; i += 1) {
        content.push((
          <g transform={`translate(${0}, ${(rowHeight / 2) * i})`} key={i}>
            <path d={renderCalpulse(rowHeight, sliceSize, stripWidthPx)} />
          </g>
        ))
      }
      return content
    }

    return (
      <div
        // className={styles.ekgRecording}

        style={{
          // overflow: "auto",
          position: "relative",
          background: "#FFFFFF",
          width: `${width}px`,
          height: `${height}px`,
          // overflowX: is6l ? 'auto' : 'hidden',
        }}
      >
        <canvas
          // className={styles.canvas}
          style={{
            "position": "absolute"
          }}
          id="recordingCanvas"
          ref={this.ekgCanvas}
          height={fullHeight}
          width={width}
        />
        {recording.enhanced && !is6l &&
          <svg width={width} height={fullHeight} style={{
            "background": "transparent",
            "position": "absolute",
            "pointerEvents": "none",

          }}>
            {calpulse &&
              <path d={renderCalpulse(rowHeight, sliceSize, stripWidthPx)} />
            }
            <MultilaneIndicator
              rows={rows}
              rowSeconds={rowSeconds}
              stripWidth={stripWidthPx}
              height={rowHeight}
            />
          </svg>
        }
        {recording.enhanced && is6l &&
          <svg width={width} height={fullHeight - SCROLLBAR_HEIGHT} style={{
            "background": "transparent",
            "position": "absolute",
            "pointerEvents": "none",

          }}>
            {calpulse &&
              <g>
                {sixCalpulseRender()}
              </g>
            }
            <MultilaneIndicator
              rows={rows}
              rowSeconds={rowSeconds}
              stripWidth={stripWidthPx}
              height={rowHeight}
              is6l={is6l}
            />
          </svg>
        }
      </div>
    )
  }
}

export default EkgRecording
