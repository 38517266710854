/* eslint-disable */

import moment from "moment";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Dialog,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Chip,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import _get from "lodash.get";
import swal from "sweetalert";
import apiservice from "../../api/services/apiservice.service";
import { streamToPromise, getTimezoneName } from "../../Utilities/Utilities";
import { getAge } from "../../Utilities/Utilities";
import KeyboardArrowLeftOutlinedIcon from "@material-ui/icons/KeyboardArrowLeftOutlined";
import Alert from "../Shared/Alert/Alerts";
import PreviewReportModal from "../AppointmentDetails/PreviewReportModal";
import axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Details_tab from "../Dashboard/details_tab";
import Overread_ReportDropdown from "../AppointmentDetails/overread_ReportDropdown";
import Followup_Dropdown from "../AppointmentDetails/followup_Dropdown";
import ConsulationSummary_tab from "../AppointmentDetails/consulationSummary_tab";
import OverReadReportNote from "../Appointments/Calendar/OverReadReportNote";
import * as API from "../../Services/API/actions";
import { GoAPI } from "../../Axios/axios";
import { getToken } from "../../Auth/AuthUtilities";
import EcgLoader from "../Shared/EcgLoader";
import useResizeDetector from "use-resize-observer/polyfilled";
import debounce from "lodash.debounce";
import MuiTypography from "../Shared/Typography/MuiTypography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  gridPaddingLightgreen: {
    background: "rgba(224, 224, 224, 0.3)",
    minHeight: "172px",
    borderRadius: "4px",
    padding: theme.spacing(2),
  },
  gridPadding: {
    padding: theme.spacing(2),
  },
  tablehead: {
    // fontSize: 20,
    fontWeight: 700,
    whiteSpace: "nowrap",
    // fontWeight: "bolder",
    backgroundColor: "#FFFFFF",
  },
  tablecell: {
    width: 200,
    whiteSpace: "nowrap",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  smallButton: {
    position: "absolute",
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: "black",
    padding: "10px",
    margin: "0px 0px 0px 10px",
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: "8px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.smallButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

const ConsultationsDetailModal = (props) => {
  const { t } = useTranslation();
  let subscription = "";
  const classes = useStyles();
  const timezoneValue = getTimezoneName();
  // let appointmentId = props.appointmentId;
  const { appointmentId, clinicianId, teamId, memberId } = props;
  const [adminConsultationDetails, setAdminConsultationDetails] = useState({});
  const [ekgLoader, setekgLoader] = useState(false);

  const mediawidth = useWidth();
  const viewportRef2 = useRef(null);
  const [isEditable, setisEditable] = useState(false);
  const [tabvalue, settabvalue] = useState(0);
  const [toggleRecordingInvert, setoggleRecordingInvert] = useState(false);
  const [statistics, setstatistics] = useState([]);
  const [recording, setRecording] = useState([]);
  const [appNotes, setappNotes] = useState("");
  const [consultationNote, setconsultationNote] = useState("");
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setalert] = useState(false);
  const [id, setid] = useState(0);
  const [isError, setisError] = useState(false);
  const [notesheight, setnotesheight] = useState(1000);
  const [overReadReport, setoverReadReport] = useState("");
  const [heartConditions, setheartConditions] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [followup, setfollowup] = useState("No Follow-up");
  const [consultationSummary, setconsultationSummary] = useState("");
  const [targetPlan, settargetPlan] = useState("");
  const [lastconsultationNote, setlastconsultationNote] = useState("");
  const [lastoverheadreportnote, setlastoverheadreportnote] = useState("");
  const [lasttreatmentPlannote, setlasttreatmentPlannote] = useState("");
  const [lastconsultationSummaryNotes, setlastconsultationSummaryNotes] =
    useState("");
  const [previewReportModalOpen, setpreviewReportModalOpen] = useState(false);
  const [ekgRecordId, setekgRecordId] = useState("");
  const [IsDisabled, setIsDisabled] = useState(false);

  const loadAction = useCallback(async (apptid) => {
    setekgLoader(true);
    let offsetInMinutes = props.offset;
    const adminConsultationsRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/admin/${clinicianId}/appointments/${apptid}?offsetInMinutes=${offsetInMinutes}&timeZone=${timezoneValue}`,
      })
    );

    const adminConsultations = await adminConsultationsRequest;
    setAdminConsultationDetails(adminConsultations.data.result);
    setekgLoader(false);
  }, []);

  useEffect(() => {
    if (props.show) {
      loadAction(appointmentId);
    }
  }, [props.show]);
  useEffect(() => {
    if (isEditable) {
      loadPatientDetailAction();
      getConsulationNotes();
    }
  }, [isEditable]);
  useEffect(() => {
    if (ekgRecordId.length > 0 && isEditable) {
      loadEkgAction(ekgRecordId);
    }
  }, [ekgRecordId]);

  useEffect(() => {
    let subscription = API.heartConditions().subscribe(
      (res) => {
        if (res.data.result != null) {
          let conditions = [
            {
              id: 0,
              heartCondition: "Select EKG Interpretation",
              heartConditionAdditionalText: "",
            },
            ...res.data.result,
          ];
          setheartConditions(conditions);
        }
        let heartConditions = res.data.result.sort(function (a, b) {
          return a.id - b.id;
        });
      },
      (error) => {
        console.log;
      }
    );
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  const getConsulationNotes = () => {
    setconsultationNote("");
    let subscription = API.getAppointmentNotes(
      teamId,
      memberId,
      appointmentId
    ).subscribe(
      (res) => {
        if (res.data.appointmentNote) {
          setconsultationNote(decodeURI(res.data.appointmentNote));
          setlastconsultationNote(decodeURI(res.data.appointmentNote));
        } else setconsultationNote("");
      },
      // eslint-disable-next-line no-console
      (error) => console.log(error)
    );
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  };

  const loadPatientDetailAction = useCallback(async () => {
    setekgLoader(true);
    let offsetInMinutes = new Date().getTimezoneOffset();
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1.1/teams/${teamId}/clinicians/${memberId}/appointments/${appointmentId}/patient-detail?timeZoneOffset=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
      })
    );
    const appointmentdetailsdata = await appointmentRequest;
    setPatientDetails(appointmentdetailsdata.data);
    setekgRecordId(appointmentdetailsdata.data.ekgResults[0].ekgRecordId);
    loadEkgAction(appointmentdetailsdata.data.ekgResults[0].ekgRecordId);
  }, [appointmentId]);

  function handleResize({ height }) {
    if (notesheight !== height) {
      setnotesheight(height);
    }
  }

  const delay = 30;

  useResizeDetector({
    ref: viewportRef2,
    onResize: debounce(handleResize, delay, { maxWait: 1000 }),
  });

  const AUTOSAVE_INTERVAL = 3000;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastconsultationNote != consultationNote) {
        //consultation notes save
        API.setAppointmentNotes(
          teamId,
          clinicianId,
          appointmentId,
          decodeURI(consultationNote)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastconsultationNote(consultationNote);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer);
  }, [consultationNote]);

  useEffect(() => {
    const timer2 = setTimeout(() => {
      if (lastoverheadreportnote != overReadReport) {
        API.setOverReadReport(
          teamId,
          clinicianId,
          appointmentId,
          decodeURI(overReadReport)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastoverheadreportnote(overReadReport);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer2);
  }, [overReadReport]);

  useEffect(() => {
    const timer3 = setTimeout(() => {
      if (lasttreatmentPlannote != targetPlan) {
        API.settreatmentPlan(
          teamId,
          clinicianId,
          appointmentId,
          decodeURI(targetPlan)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlasttreatmentPlannote(targetPlan);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer3);
  }, [targetPlan]);

  useEffect(() => {
    const timer4 = setTimeout(() => {
      if (lastconsultationSummaryNotes != consultationSummary) {
        //consultation notes save
        API.setconsultationSummaryNotes(
          teamId,
          clinicianId,
          appointmentId,
          decodeURI(consultationSummary)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastconsultationSummaryNotes(consultationSummary);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer4);
  }, [consultationSummary]);

  const loadEkgAction = useCallback((recordingid) => {
    setekgLoader(true);
    let samps = GoAPI.get(`/i/v1/recordings/${recordingid}/samples`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    let recs = GoAPI.get(`/i/v1/recordings/${recordingid}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    axios
      .all([recs, samps])
      .then(
        axios.spread((...responses) => {
          // setloader(false);
          setekgLoader(false);
          setRecording({ ...responses[0].data, ...responses[1].data });
          setAlertOn(false);
        })
      )
      .catch((error) => {
        setAlertOn(true);
        setekgLoader(false);
        //setloader(false);
        setRecording({});
      });
  }, []);

  let cellClass = "";
  if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
    "Completed"
  ) {
    cellClass += " status_green_completed";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
    "Pending"
  ) {
    cellClass += " status_purple";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
    "Scheduled"
  ) {
    cellClass += " status_green_scheduled";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Tech issues - patient" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Tech issues - doctor" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Doctor disconnected" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Patient Missed" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Doctor Missed"
  ) {
    cellClass += " status_orange_closed";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Canceled" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Missed" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Declined" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Patient Canceled" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Doctor Canceled" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "System Canceled" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Admin Canceled"
  ) {
    cellClass += " status_red_canceled";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Report being generated" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
      "Report Generation"
  ) {
    cellClass += " status_gray_reportgenerated";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
    "In progress"
  ) {
    cellClass += " status_gray_inprogress";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") ===
    "Doctor review started"
  ) {
    cellClass += " status_gray_doctorreviewstarted";
  } else {
    cellClass += " status_purple";
  }

  const editReport = () => {
    setisEditable(true);
  };
  const changetabvalue = (event, newValue) => {
    settabvalue(newValue);
  };

  const settoggleRecordingInvert = (prps) => {
    setoggleRecordingInvert(prps);
  };

  const updateConsultationNote = (note) => {
    setconsultationNote(note.target.value);
  };

  const handleidChange = (event) => {
    setid(event.target.value);
    if (event.target.value !== 0) {
      API.setheartConditionId(
        teamId,
        memberId,
        appointmentId,
        event.target.value
      ).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error)
      );
      let selectedCondition = heartConditions.filter(
        (x) => x.id === event.target.value
      );
      setoverReadReport(
        selectedCondition.length != 0 &&
          selectedCondition[0].heartConditionAdditionalText
          ? selectedCondition[0].heartConditionAdditionalText
          : ""
      );
    } else {
      setoverReadReport("");
    }
  };

  const updateoverReadReport = (note) => {
    setoverReadReport(note.target.value);
  };

  const handleperiodChange = (event) => {
    API.setdurationText(
      teamId,
      memberId,
      appointmentId,
      event.target.value
    ).subscribe(
      (res) => {
        // do nothing
      },
      (error) => console.log(error)
    );
    setfollowup(event.target.value);
  };

  const updateconsultationSummary = (note) => {
    // auto save logic here
    setconsultationSummary(note.target.value);
  };

  const updatetargetplan = (note) => {
    // auto save logic here for update treatment plan
    settargetPlan(note.target.value);
  };

  const mobileNumberFormatter = (number) => {
    if (number) {
      if (number.substring(0, 1) === "1" || number.substring(0, 2) === "+1") {
        number = number.slice(number.length - 10);
        var countryCode = "+1";
        var newArray = number.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        return (
          countryCode +
          " (" +
          newArray[2] +
          ") " +
          newArray[3] +
          " " +
          newArray[4]
        );
      } else return number;
    }
  };

  const handleClose = () => {
    let body = {
      appointmentStatus: _get(adminConsultationDetails, "appointmentStatusId"),
      consultationSummaryNotes: encodeURI(consultationSummary),
      followup: followup,
      heartConditionId: id,
      overReadReportNotes: encodeURI(overReadReport),
      patientId: _get(patientDetails, "patient.patientId"),
      treatmentPlan: encodeURI(targetPlan),
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };

    if (consultationSummary.length === 0) {
      setalert(
        <Alert
          date={new Date()}
          message={
            "Please enter the Consultation Summary and Patient Note to proceed to the next step."
          }
        />
      );
    } else {
      setalert("");
      setIsDisabled(true);
      let subscription = API.updateReport(
        teamId,
        memberId,
        appointmentId,
        body
      ).subscribe(
        (res) => {
          if (res.data.result != null) {
            setisEditable(false);
            props.handleClose();
            setIsDisabled(false);
            swal({
              closeOnClickOutside: false,
              icon: "success",
              title: "Consultation Updated",
              text: "Consultation has been updated succesfully!",
              className: "telekardia",
              buttons: {
                confirm: {
                  text: "OKAY",
                  visible: true,
                  className: "swal_ok swal_green",
                  closeModal: true,
                },
              },
            }).then((value) => {
              props.onRefresh();
            });
          }
        },
        (error) => {
          setIsDisabled(false);
          console.log;
        }
      );
    }
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  };

  const loadReport = () => {
    if (overReadReport.length !== 0) {
      setpreviewReportModalOpen(true);
      setisError(false);
      setalert("");
    } else {
      setalert(
        <Alert
          date={new Date()}
          message={
            "Please select the EKG interpretation to proceed to the next step."
          }
        />
      );
      setisError(true);
    }
  };

  const handlePreviewReportModalClose = () => {
    setpreviewReportModalOpen(false);
  };

  let actions = (
    <>
      <button
        type="button"
        className="btn px-4 btn-outline-primary text-uppercase h40 f28_icon"
        onClick={() => onClose()}
      >
        <KeyboardArrowLeftOutlinedIcon /> Back
      </button>
      <button
        type="button"
        className="btn px-4 btn-outline-primary text-uppercase h40 ml-3"
        onClick={loadReport}
      >
        Preview REPORT
      </button>
      <button
        type="button"
        className="btn px-4 btn_teal text-uppercase h40 ml-3"
        onClick={handleClose}
        disabled={IsDisabled}
      >
        SEND REPORT
      </button>
    </>
  );
  const onClose = () => {
    if (isEditable) {
      setalert("");
      setisEditable(false);
      settabvalue(0);
      setekgRecordId("");
    } else {
      props.handleClose();
    }
  };
  return (
    <>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={isEditable ? "xl" : "md"}
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={props.show}
        >
          <DialogTitle
            id="customized-dialog-title-value"
            onClose={isEditable ? null : onClose}
          >
            {!isEditable && (
              <MuiTypography
                fontSize="24px"
                fontFamily="Work Sans"
                lineHeight="28.15px"
                fontWeight={500}
                color="#000000"
              >
                {t("Consultation details")}
              </MuiTypography>
            )}
          </DialogTitle>
          <DialogContent>
            {ekgLoader === true ? (
              <EcgLoader />
            ) : isEditable ? (
              <div className="telekardia">
                <div className="m-4">{alert}</div>
                <div className="container-fluid">
                  {previewReportModalOpen && (
                    <PreviewReportModal
                      open={previewReportModalOpen}
                      memberId={memberId}
                      patientId={patientDetails.patient.patientId}
                      appointmentId={appointmentId}
                      handlePreviewReportModalClose={
                        handlePreviewReportModalClose
                      }
                    />
                  )}
                  <div className="row m-0" style={{ display: "-ms-flexbox" }}>
                    <div className="col-12 col-xl-7">
                      <div ref={viewportRef2}>
                        <Details_tab
                          value={tabvalue}
                          patientDetails={patientDetails}
                          appointmentId={props.appointmentId}
                          handleChange={changetabvalue}
                          setoggleRecordingInvert={settoggleRecordingInvert}
                          stats={statistics}
                          toggleRecordingInvert={toggleRecordingInvert}
                          alertOn={alertOn}
                          appNotes={appNotes}
                          recording={recording}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        mediawidth === "xl" || mediawidth === "lg"
                          ? "col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3"
                          : "col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3 order-first"
                      }
                    >
                      <div className="row no-gutters">
                        <div className="col-12 bg_white p-4">
                          <h5 className="head">{t("My Notes")}</h5>
                          <textarea
                            onChange={updateConsultationNote}
                            className={
                              mediawidth === "xl" || mediawidth === "lg"
                                ? "form-control bg_grey border-0"
                                : "form-control bg_grey height_230 border-0"
                            }
                            style={{
                              height:
                                (mediawidth === "xl" || mediawidth === "lg") &&
                                notesheight - 457 - 381 - 77 - 30,
                            }}
                            placeholder="Write your personal notes here. They will not be shared with the patient."
                            value={consultationNote}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-12 height_381 bg_white p-4  mt-3">
                          <div className="row mb-4">
                            <div className="col-5 d-flex align-items-center">
                              <h5 className="head">{t("EKG Review")}</h5>
                            </div>
                            <div className="col-7">
                              <Overread_ReportDropdown
                                appointmentId={appointmentId}
                                id={id}
                                error={isError}
                                setError={setisError}
                                handleidChange={handleidChange}
                                heartConditions={heartConditions}
                              />
                            </div>
                            <div className="col-12">
                              <OverReadReportNote
                                appointmentId={appointmentId}
                                id={id}
                                updateoverReadReport={updateoverReadReport}
                                overReadReport={overReadReport}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row no-gutters">
                        <div className="col-12 height_457 bg_white p-4 my-3">
                          <div className="row mb-4">
                            <div className="col-7 d-flex align-items-center">
                              <h5 className="head">{t("Patient Note")}</h5>
                            </div>
                            <div className="col-5">
                              <Followup_Dropdown
                                period={followup}
                                handleChange={handleperiodChange}
                                appointmentId={appointmentId}
                              />
                            </div>
                            <div className="col-12">
                              <ConsulationSummary_tab
                                consultationSummary={consultationSummary}
                                updateconsultationSummary={
                                  updateconsultationSummary
                                }
                                treatmentPlan={targetPlan}
                                updatetreatmentPlan={updatetargetplan}
                                appointmentId={appointmentId}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        mediawidth === "xl" || mediawidth === "lg"
                          ? "col-12"
                          : "col-12 order-2"
                      }
                    >
                      <div className="telekardia">
                        <div className="d-flex justify-content-end py-3 px-0">
                          {actions}
                        </div>
                        {mediawidth !== "xl" && mediawidth !== "lg" && (
                          <Box mb={30} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <Grid container className={classes.gridPaddingLightgreen}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    spacing={10}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                      >
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#000000"
                          >
                            {t("Date and time")}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={400}
                            color="#000000"
                          >
                            {_get(adminConsultationDetails, "startdatetime") &&
                              moment(
                                _get(adminConsultationDetails, "startdatetime")
                              ).format("MM/DD/YYYY")}{" "}
                            {_get(adminConsultationDetails, "startdatetime") &&
                              moment(
                                _get(adminConsultationDetails, "startdatetime")
                              ).format("hh:mm A")}{" "}
                            to{" "}
                            {_get(adminConsultationDetails, "enddatetime") &&
                              moment(
                                _get(adminConsultationDetails, "enddatetime")
                              ).format("hh:mm A")}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#000000"
                          >
                            {t("Type")}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={400}
                            color="#000000"
                          >
                            {_get(
                              adminConsultationDetails,
                              "appointmentType"
                            ) || "-"}
                          </MuiTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                      >
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#000000"
                          >
                            {t("Status")}
                          </MuiTypography>
                        </Grid>
                        <Grid item style={{ display: "flex", gap: "1rem" }}>
                          <div className="telekardia">
                            <Chip
                              size="small"
                              className={cellClass}
                              label={
                                _get(
                                  adminConsultationDetails,
                                  "appointmentStatusDisplayName"
                                ) || "-"
                              }
                            />
                          </div>
                          {/* 
                            appointnemtStatusId = 4 represents session completed. 
                            appointnemtStatusId = 10 represents session closed. 
                            appointnemtStatusId = 5 represents session missed by provider/member. 
                            for these session status "Edit Report" button should be enabled.
                          */}
                          <div className="telekardia">
                            {(_get(
                              adminConsultationDetails,
                              "appointmentStatusId"
                            ) === 5 ||
                              _get(
                                adminConsultationDetails,
                                "appointmentStatusId"
                              ) === 10 ||
                              _get(
                                adminConsultationDetails,
                                "appointmentStatusId"
                              ) === 4) && (
                              <button
                                type="button"
                                className="btn px-4 text-camelcase h40 ml-3 swal_green"
                                onClick={editReport}
                              >
                                {t("Edit Report")}
                              </button>
                            )}
                          </div>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#000000"
                          >
                            {t("Method")}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={400}
                            color="#000000"
                          >
                            {_get(
                              adminConsultationDetails,
                              "appointmentChannelType"
                            ) || "-"}
                          </MuiTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item>
                      <Grid container direction="column" justify="space-between">
                        <Grid item>
                        </Grid>
                      <Grid item>
                      <div className="telekardia">
                <button type="button" className="btn px-4 text-camelcase h40 ml-3 swal_green">
                {t("Edit Report")}
                      </button>
                      </div>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>

                  <Grid container direction="row" justify="flex-start">
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                      >
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#000000"
                          >
                            {t("Reason for consultation")}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={400}
                            color="#000000"
                          >
                            {_get(adminConsultationDetails, "reasonForVisit") ||
                              "-"}
                          </MuiTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={2} />
                <Grid container className={classes.gridPadding}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    spacing={10}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                      >
                        <Grid item>
                          <MuiTypography
                            fontSize="10px"
                            fontFamily="Work Sans"
                            lineHeight="16px"
                            letterSpacing="2.5px"
                            fontWeight={500}
                            textTransform="uppercase"
                            color="#067F6F"
                          >
                            {t("Cardiologist details")}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#000000"
                          >
                            Dr{" "}
                            {_get(
                              adminConsultationDetails,
                              "physician.firstName"
                            ) || "-"}{" "}
                            {_get(
                              adminConsultationDetails,
                              "physician.lastName"
                            ) || "-"}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={400}
                            color="#000000"
                          >
                            {_get(
                              adminConsultationDetails,
                              "physician.email"
                            ) || "-"}
                            ,
                            {mobileNumberFormatter(
                              _get(adminConsultationDetails, "physician.phone")
                            ) || "-"}
                          </MuiTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                      >
                        <Grid item>
                          <MuiTypography
                            fontSize="10px"
                            fontFamily="Work Sans"
                            lineHeight="16px"
                            letterSpacing="2.5px"
                            fontWeight={500}
                            textTransform="uppercase"
                            color="#067F6F"
                          >
                            {t("PATIENT DETAILS")}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#000000"
                          >
                            {_get(
                              adminConsultationDetails,
                              "patient.firstName"
                            ) || "-"}{" "}
                            {_get(
                              adminConsultationDetails,
                              "patient.lastName"
                            ) || "-"}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="14px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={400}
                            color="#000000"
                          >
                            {_get(
                              adminConsultationDetails,
                              "patient.location"
                            ) || "-"}
                            ,
                            {_get(adminConsultationDetails, "patient.email") ||
                              "-"}
                            ,
                            {mobileNumberFormatter(
                              _get(adminConsultationDetails, "patient.phone")
                            ) || "-"}
                          </MuiTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {adminConsultationDetails &&
                  adminConsultationDetails.auditTags &&
                  adminConsultationDetails.auditTags.length > 0 && (
                    <>
                      <Box mt={2} />
                      <Grid
                        container
                        className={classes.gridPadding}
                        direction="row"
                      >
                        <Grid item>
                          <MuiTypography
                            fontSize="10px"
                            fontFamily="Work Sans"
                            lineHeight="16px"
                            letterSpacing="2.5px"
                            fontWeight={500}
                            textTransform="uppercase"
                            color="#067F6F"
                          >
                            {t("TAGS")}
                          </MuiTypography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{ display: "flex", gap: "2rem" }}
                        justify="flex-start"
                      >
                        {adminConsultationDetails.auditTags.map((tag) => (
                          <Grid item direction="column">
                            <div className="telekardia">
                              <Chip
                                size="small"
                                style={{
                                  border: "2px dashed rgb(167, 169, 171)",
                                  marginLeft: "15px",
                                  padding: "10px",
                                }}
                                label={tag}
                              />
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}

                <Box mt={2} />
                <Grid container className={classes.gridPadding}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    xs={7}
                  >
                    <Grid item>
                      <MuiTypography
                        fontSize="10px"
                        fontFamily="Work Sans"
                        lineHeight="16px"
                        letterSpacing="2.5px"
                        fontWeight={500}
                        textTransform="uppercase"
                        color="#067F6F"
                      >
                        {t("Audit")}
                      </MuiTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tablehead}
                        style={{ width: 173 }}
                      >
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          {t("Date and Time")}
                        </MuiTypography>
                      </TableCell>
                      <TableCell align="left" className={classes.tablehead}>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          {t("Action")}
                        </MuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adminConsultationDetails &&
                      adminConsultationDetails.auditDetails &&
                      adminConsultationDetails.auditDetails.length > 0 &&
                      adminConsultationDetails.auditDetails
                        .sort(
                          (a, b) =>
                            moment(b.auditDateTime) - moment(a.auditDateTime)
                        )
                        .map((row) => (
                          <StyledTableRow key={row.auditDateTime}>
                            <TableCell align="left" style={{ width: 173 }}>
                              <MuiTypography
                                fontSize="14px"
                                fontFamily="Work Sans"
                                lineHeight="24px"
                                letterSpacing="-0.2px"
                                fontWeight={400}
                                color="#000000"
                              >
                                {_get(row, "auditDateTime") &&
                                  moment(_get(row, "auditDateTime")).format(
                                    "MM/DD/YYYY"
                                  )}
                                ,{" "}
                                {_get(row, "auditDateTime") &&
                                  moment(_get(row, "auditDateTime")).format(
                                    "LT"
                                  )}{" "}
                              </MuiTypography>
                            </TableCell>
                            <TableCell align="left">
                              <MuiTypography
                                fontSize="14px"
                                fontFamily="Work Sans"
                                lineHeight="24px"
                                letterSpacing="-0.2px"
                                fontWeight={400}
                                color="#000000"
                              >
                                {row.auditReason}
                              </MuiTypography>

                              {row.auditReason
                                .toLowerCase()
                                .includes("closed") && (
                                <>
                                  {/* <br /> */}
                                  <MuiTypography
                                    fontSize="14px"
                                    fontFamily="Work Sans"
                                    lineHeight="24px"
                                    letterSpacing="-0.2px"
                                    fontWeight={400}
                                    color="#000000"
                                  >
                                    {t("Reason")} : {row.auditStatus}
                                  </MuiTypography>
                                </>
                              )}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                  </TableBody>
                </Table>
              </>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ConsultationsDetailModal;
