/* eslint-disable */
import React, {useEffect, useState, useCallback} from "react";
import {
    Grid,
    Container,
    Box,
    IconButton,
    InputBase,
    Paper,
    SvgIcon,
    Input,
    InputAdornment,
    Popover,
    Divider,
    Button,
    FormControl,
    InputLabel,
    Select,
    Chip,
    MenuItem,
    OutlinedInput,
    FormControlLabel,
    Checkbox,
    TextField,
    NativeSelect,
} from "@material-ui/core";
import BottomLineTitle from "../Shared/Typography/BottomLineTitle";
import {makeStyles} from "@material-ui/core/styles";
import {Chart} from "react-google-charts";
import Datepicker from "./material_datepicker";
import SearchIcon from "@material-ui/icons/Search";
import Calendar from './Calendar'
import AvailabilityForm from "./AvailabilityForm";
import Popup from './AvailabilityPopup';
import AppointmentPopup from './AppointmentPopup';
import ReAssignAppointment from "./ReAssignAppointment";
import ProviderPopUp from './ProviderPopUp';
import ReScheduleAppointment from "./ReScheduleAppointment";
import * as API from '../../Services/API/actions';
import {useSelector} from "react-redux";
import MuiTypography from "../Shared/Typography/MuiTypography";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import CancelConsultaionConfirmationPopup from "./CancelConsultaionConfirmationPopup";
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import AvailabilityAlert from "../Shared/Alert/AvailabilityAlert";
import Alert from "../Shared/Alert/Alerts";
import CachedIcon from "@material-ui/icons/Cached";
import { useTheme, withStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RecursiveConfirmationPopup from "./RecursiveConfirmationPopup";
import EcgLoader from '../Shared/EcgLoader';
import { useTranslation } from "react-i18next";
import { streamToPromise, getTimezoneName } from "../../Utilities/Utilities";
import FilterListIcon from "@material-ui/icons/FilterList";
import apiservice from "../../api/services/apiservice.service";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";

const muitheme = createMuiTheme({
  palette: {
      primary: {
          main: "#2D9F86"
      }
  },
  typography: {
      fontFamily: 'Work Sans',
    },
});

const buttonstyle = {
  marginBottom: "10px",
  color: "#2D9F86",
};

const CssTextField = withStyles({
  root: {
    "& input::placeholder": {
       fontFamily: "Work Sans !important",
      opacity:1,
      color: "#142A39 !important",
    },
    '& label.Mui-focused': {
      color: '#2D9F86',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#2D9F86',
    },
    '& .MuiOutlinedInput-root': {      
      '& .MuiAutocomplete-input': {
        fontFamily: "Work Sans !important",
        fontWeight: 400,
        color: "#142A39 !important",
      },
      
      '& fieldset': {
        borderColor: '#142A39',
      },
      '&:hover fieldset': {
        borderColor: '#2D9F86',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2D9F86',
      }
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  root: {
    // width: "100%",
    // height: "100vh",
    flex: 1,
    // overflowX: "auto",
    background: "#FFFFFF",
    marginTop: 0,
  },
  searchbarroot: {
    padding: "2px 4px",
    display:
      "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    height: "36px",
    width: "312px !important",
  },
  topbar: {
    background: "#FFFFFF",
    height: "66px",
    padding: "4px 4px",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
  searchbarroottablet: {
    padding: "2px 4px",
    display:
      "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    height: "36px",
    width: "204px !important",
  },
  topbarroot: {
    paddingLeft: "15px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "100%",
    background: "#FFFFFF",
  },

  topbarroottablet: {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    minWidth: 1300,
    background: "#FFFFFF",
  },
  pageTitle: {
    height: "42px",
    fontFamily: "Work Sans !important",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "34px",
    lineHeight: "40px",
    letterSpacing: "0.25px",
    color: "#142A39",
  },
  smallButton: {
    marginTop: "9px",
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    color: "black",
    // padding: "10px",
    // margin: "0px 0px 0px 10px",
    "&$buttonDisabled": {
      backgroundColor: "rgba(20, 42, 57, 0.15)",
      color: "rgba(20, 42, 57, 0.15)",
    },
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 41,
    opacity: 0.9,
    borderRadius: "8px",
  },
  icon: {
    alignItems: "center",
    display: "flex",
  },
  buttonDisabled: {},
  largeinput: {
    color: "#142A39 !important",
    minWidth: "512px",
    fontFamily: "Work Sans !important",
    "& input::placeholder": {
      fontFamily: "Work Sans !important",
      fontWeight: 400,
      color: "#142A39 !important",
      opacity: 1,
      textAlign: "left",
    },
    "&:focus": {
      borderColor: "#2D9F86",
    },
  },
  largeinputtablet: {
    color: "#142A39 !important",
    minWidth: "212px",
    fontFamily: "Work Sans !important",
    "& input::placeholder": {
      fontFamily: "Work Sans !important",
      fontWeight: 400,
      color: "#142A39 !important",
      opacity: 1,
      textAlign: "left",
    },
    "&:focus": {
      borderColor: "#2D9F86",
    },
  },
  scrollablegrid: {
    minHeight: 700,
    maxHeight: 800,
    // minWidth: 1800,
    overflowY: "auto",
    overflowX: "hidden",
  },
  scrollablegridtablet: {
    minHeight: 700,
    maxHeight: 800,
    minWidth: 1300,
    overflowY: "auto",
    overflowX: "auto",
  },
  popovergrid: {
    padding: "5px",
    minHeight: 280,
    background: "white",
    width: 590,
  },
  chip: {
    margin: 1,
  },
  chipfont:{  
    fontFamily: "Work Sans !important",
    color: "#000000 !important",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    fontWeight: 400
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControlchip: {
    background: "#FFFFFF",
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "5px",
    // minHeight: 280,
    minWidth: 280,
    maxWidth: 300
  },
  formControlchipsmall: {
    background: "#FFFFFF",
    // margin: theme.spacing(1),
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "5px",
    minWidth: 120,
    minHeight: 280,
    maxWidth: 300,
  },
  formControllabelstyle: {
    marginLeft: "5px",
    marginRight: "5px",
    fontFamily: "Work Sans !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(20, 42, 57, 0.72)",
  },
  popoverclass: {
    "& .popover_class::before": {
      content: "",
      position: "absolute",
      top: -20,
      right: 5,
      borderBottom: 10,
      //   border-right: 10px solid transparent;
      //   border-left: 10px solid transparent;
      //   border-top: 10px solid transparent;
      zIndex: 10,
    },
    "&::after": {
      content: "",
      position: "absolute",
      top: "-5px",
      right: "4px",
      borderStyle: "solid",
      borderWidth: "0 8px 8px 8px",
      borderColor: "transparent transparent green transparent",
      zIndex: 9998,
    },
  },
  menuroot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuselected: {
    backgroundColor: "#FFFFFF !important"
  },
  autocompleteinput: {
      color: "#000000",
      fontFamily: "Roboto Mono",
      backgroundColor: "#FFFFFF",
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "blue"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "blue"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "blue"
      },
      "&.Mui-focused": {
        backgroundColor: "#FFFFFF",
        color: "#000000",
      }
  }
}));


const StyledMenuItem = withStyles((theme) => ({
  root: {
    // '&$hover': {
    //   backgroundColor: '#FFFFFF',
    //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //       color: '#2D9F86',
    //     },
    // },
    '&:focus': {
      backgroundColor: '#FFFFFF',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#2D9F86',
      },
    },
    "&$selected": {
      backgroundColor: '#FFFFFF',
      color: '#2D9F86',
    },
    "&$selected:hover": {
      backgroundColor: "#FFFFFF",
      color:'#2D9F86',
    },
    '& .MuiButtonBase-root': {
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5)
		},
		'& .MuiListItemIcon-root': {
			minWidth: '40px'
		},
		'& .MuiButtonBase-root:hover': {
			backgroundColor: '#FFFFFF'
		},
		'& .MuiListItem-root.Mui-selected': {
			backgroundColor: '#FFFFFF',
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: '#2D9F86'
			}
		},
    "&.Mui-focusVisible": {
      color: "#FFFFFF",
      backgroundColor: '#FFFFFF',
    },
  },
  selected: {}
}))(MenuItem);


function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: "#000000",
    "&$checked": {
      color: "#2D9F86",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    // "& $notchedOutline": {
    //   borderColor: "red"
    // },
    minWidth:264,
    color: "#000000",
    backgroundColor: "#ffffff",
    "&:hover $notchedOutline": {
      color: "#2D9F86",
      borderColor: "#2D9F86",
    },
    "&$focused $notchedOutline": {
      color: "#000000",
      borderColor: "#2D9F86",
    },
  },
  input: {
    background: "#ffffff",
    "&:hover": {
      color: "#2D9F86",
    },
    "&$focused": {
      color: "#2D9F86",
    },
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
  focused: {},
  notchedOutline: {},
}));

function AdminCalendar(props) {
    const { t } = useTranslation();
    const mediawidth = useWidth();
    const categoriesIdArr = ['C00001', 'C00002', 'C00003', 'C00004', 'C00005'];
    const clinicianId = useSelector((state) => state.user.profile.id);
    const teamId = useSelector((state) => state.user.team.id);
    const [availabilityData, setAvailabilityData] = useState([]);
    const [arraylengthoftimeline, setarraylengthoftimeline] = useState(0);
    const [arrayofcolorsdata, setarrayofcolorsdata] = useState([]);
    const [availabilityunfilteredData, setavailabilityunfilteredData] = useState([]);
    const [timelinedate, settimelinedate] = useState(new Date());
    const [isLoading,setloading] = useState(false);    
    const [refreshaction, setrefreshaction] = useState(Date.now());
    let getAvailabilityDataSubscription;
    const [selectedtimelineDate,setselectedtimelineDate] = useState(new Date());
    const [anchorEl, setAnchorEl] = React.useState(null);

    const outlinedInputClasses = useOutlinedInputStyles();

    
    const inputLabel1 = React.useRef(null);
    const [newvals, setnewvals] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "10", "11"]);
    const top100Countries = [
      { title: 'Country-All', code: 10 },
      { title: 'A country', code: 1994 },
      { title: 'B country', code: 1972 },
      { title: 'C country', code: 1974 },
      { title: 'D country', code: 2008 },
      { title: 'E country', code: 1957 },
      { title: "F country", code: 1993 }
    ];

    const top100Regions = [
      { title: 'Region-All', code: 20 },
      { title: 'A region', code: 0 },
      { title: 'B region', code: 1 },
      { title: 'C region', code: 2 },
      { title: 'D region', code: 3 },
      { title: 'E region', code: 4 },
      { title: "F region", code: 5 }
    ];

    const [applyadvancefilter, setapplyadvancefilter] = React.useState(false);    

    const [combinedarray, setcombinedarray] = useState([]);

    useEffect(() => {
      const start = moment(timelinedate);
      let countryfilteredarray = combinedarray && combinedarray.length > 0 ? combinedarray.filter(x => "regions" in x) : [];
      let combinecountryvaluetemp = countryfilteredarray && countryfilteredarray.length > 0 ? countryfilteredarray.map(x => x.name).join() : "";
      const combinecountryvalue = !combinecountryvaluetemp.includes("All")
        ? combinecountryvaluetemp
        : "";

    
      let regionfilteredarray = combinedarray && combinedarray.length > 0 ? combinedarray.filter(x => !("regions" in x)) : [];
      let combineregionvaluetemp =
        regionfilteredarray && regionfilteredarray.length > 0
          ? regionfilteredarray.map((x) => x.name).join()
          : "";

      const combineregionvalue = !combineregionvaluetemp.includes("All")
        ? combineregionvaluetemp
        : "";

      // console.log(Timezone.tz(timezoneString).utcOffset());
      const remainder = 10 - (start.minute() % 10);
       setselectedtimelineDate(moment(start).add(remainder, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSS"));
        const queryParams = {
            date: moment(timelinedate).format("YYYY-MM-DD"),
            offsetInMinutes: new Date().getTimezoneOffset(),
            timeZone:getTimezoneName(),
            splitBasedOnDays:true,
            countryIds: combinecountryvalue,
            regionIds: combineregionvalue
        }
        setloading(true);
        getAvailabilityDataSubscription = API.getAvailabilityData(teamId, clinicianId, queryParams).subscribe(res => {
            const dataArr = [];
            // setrefreshaction(Date.now());
            setdoctorData(res.data.result.filter(c => c.availabilities && c.availabilities != null &&  c.availabilities.length > 0));
            const prelElmData = [
                {type: "string", id: "Category"},
                {type: "string", id: "ID"},
                {type: "string", role: "tooltip"},
                {type: "date", id: "Start"},
                {type: "date", id: "End"},
            ];
            dataArr.push(prelElmData);
            var barlength = 0;
            if (res.data.result.length> 0) {
                var arrayofcolors = new Set();  
               
            for (const respObj of res.data.result.filter(c => c.availabilities && c.availabilities != null &&  c.availabilities.length > 0)) {
                if (respObj.availabilities) {
                    barlength = barlength + 1;
                for (const availabilitiesArr of respObj.availabilities) {
                    const availabilityObjArr = [];
                    availabilityObjArr.push(`${respObj.physicianModel.memberId}`);
                    // availabilityObjArr.push(`Dr. ${respObj.physicianModel.firstName} ${respObj.physicianModel.lastName}memberid${respObj.physicianModel.memberId}`);
                    availabilityObjArr.push(categoriesIdArr[0]);
                    arrayofcolors.add(categoriesIdArr[0]);
                    availabilityObjArr.push(availabilitiesArr.id);
                    availabilityObjArr.push(availabilitiesArr.isSplit?moment(availabilitiesArr.splitStartDateTime)._d:moment(availabilitiesArr.startDateTime)._d);
                    availabilityObjArr.push(availabilitiesArr.isSplit?moment(availabilitiesArr.splitEndDateTime)._d:moment(availabilitiesArr.endDateTime)._d);
                    dataArr.push(availabilityObjArr);
                }
               }
                if (respObj.appointments && respObj.appointments != null) {
                    barlength = barlength + 1;
                    for (const appointmentsArr of respObj.appointments.filter(c => moment().isSameOrAfter(c.scheduledStartTime))) {
                        const appointmentsObjArr = [];
                        // appointmentsObjArr.push(`Dr. ${respObj.physicianModel.firstName} ${respObj.physicianModel.lastName}memberid${respObj.physicianModel.memberId}`);
                        appointmentsObjArr.push(`${respObj.physicianModel.memberId}`);
                        let categoryId1 = (appointmentsArr.appointmentStatusId === 3 || appointmentsArr.appointmentStatusId === 2) ? categoriesIdArr[4]: categoriesIdArr[1];
                        // let categoryId1 = categoriesIdArr[1];
                        appointmentsObjArr.push(categoryId1);
                        arrayofcolors.add(categoryId1);
                        appointmentsObjArr.push(appointmentsArr.appointmentId);
                        appointmentsObjArr.push(moment(appointmentsArr.scheduledStartTime)._d);
                        appointmentsObjArr.push(moment(appointmentsArr.scheduledEndTime).subtract(1, "minutes")._d);
                        dataArr.push(appointmentsObjArr);
                    }
                    for (const appointmentsscheduledArr of respObj.appointments.filter(c => moment().isBefore(c.scheduledStartTime))) {
                        const appointmentsObjArrscheduled = [];
                        // appointmentsObjArrscheduled.push(`Dr. ${respObj.physicianModel.firstName} ${respObj.physicianModel.lastName}memberid${respObj.physicianModel.memberId}`);
                        appointmentsObjArrscheduled.push(`${respObj.physicianModel.memberId}`);
                        let categoryId2 = (appointmentsscheduledArr.appointmentStatusId === 1) ? categoriesIdArr[2]: (appointmentsscheduledArr.appointmentStatusId === 3 || appointmentsscheduledArr.appointmentStatusId === 2) ? categoriesIdArr[4] : categoriesIdArr[3];
                        arrayofcolors.add(categoryId2);
                        appointmentsObjArrscheduled.push(categoryId2);
                        appointmentsObjArrscheduled.push(appointmentsscheduledArr.appointmentId);
                        appointmentsObjArrscheduled.push(moment(appointmentsscheduledArr.scheduledStartTime)._d);
                        appointmentsObjArrscheduled.push(moment(appointmentsscheduledArr.scheduledEndTime).subtract(1, "minutes")._d);
                        dataArr.push(appointmentsObjArrscheduled);
                    }
                    
                
                }
            }
            setarrayofcolorsdata([...arrayofcolors]);
            }
            setloading(false);
            setAvailabilityData(dataArr);
            setarraylengthoftimeline(barlength);
            setavailabilityunfilteredData(dataArr);
        }, error => {
            setloading(false);
            console.log(error);
        });
        return () => {
            if (getAvailabilityDataSubscription && getAvailabilityDataSubscription !== "")
                getAvailabilityDataSubscription.unsubscribe();
        }
    }, [timelinedate, refreshaction, applyadvancefilter]);

    

    const classes = useStyles();
    const [popup, setpopup] = React.useState(false);
    const [appointmentpopup, setappointmentpopup] = React.useState(false);
    const [providerdetailModal, setproviderdetailModal] = React.useState(false);
    const [deleteconfirmationpopup, setdeleteconfirmationpopup] = React.useState(false);
    const [providername, setprovidername] = React.useState("");
    const [searchInput, setSearchInput] = React.useState("");
    const [selectedData,setselectedData] = React.useState([])
    const [providerInfo,setproviderInfo] = React.useState([]);
    const [view, setview] = React.useState(true);
    const [doctorData,setdoctorData] = React.useState([]);
    const [addAvailabilityModal, setaddAvailabilityModal] = React.useState(false);
    const [deleteAvailabilityModal, setdeleteAvailabilityModal] = React.useState(false);
    const [editAvailabilityModal, seteditAvailabilityModal] = React.useState(false);
    const [reassignAppointmentModal, setreassignAppointmentModal] = React.useState(false);
    const [rescheduleAppointmentModal, setrescheduleAppointmentModal] = React.useState(false);
    const [selectedDoctorName,setselectedDoctorName] = React.useState('');
    const [cancelConsultaionConfirmationPopup, setcancelConsultaionConfirmationPopup] = React.useState(false);
    const [selectedClinicianteamId,setselectedClinicianteamId] = useState("");
    const [selectedClinicianmemberId,setselectedClinicianmemberId] = useState("")
    const [addAvailabilitysuccess, setAddAvailabilitysuccess] = useState(false);
    const [deleteAvailabilitysuccess,setdeleteAvailabilitysuccess] = useState(false);
    const [resheduleConsultationsuccess,setresheduleConsultationsuccess] = useState(false);
    const [editAvailabilitysuccess,seteditAvailabilitysuccess] = useState(false);
    const [reassignAppointmentSuccess,setReassignAppointmentSuccess] = useState(false);
    const [cancelConsultationsuccess,setcancelConsultationsuccess] = useState(false);
    const [avaialabilityalertprops, setavaialabilityalertprops] = useState({});
    const [recursiveConfirmation,setrecursiveConfirmation] = useState(false);
    const [recursiveSeries,setrecursiveSeries] = useState(0);
    const [recursiveConfirmationType,setrecursiveConfirmationType] = useState(false);
    const [ongoing,setOngoing] = useState(false);
  

    const [regionarrayoptions, setregionarrayoptions] = useState([]);
    const [countryarrayoptions, setcountryarrayoptions] = useState([]);

    const [regionarray, setregionarray] = useState([]);
    const [countryarray, setcountryarray] = useState([]);   


    const loadLookupAction = useCallback(async () => {
      const countrylookuprequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v1/teams/${teamId}/country`,
        })
      );
      const countrylookup = await countrylookuprequest;
      let allcountrydata = {
        name: "All",
        countryId: "all-country-id",
        regions: [
          {
            name: "All",
            abbreviation: "All",
            regionId: "all-region-id",
            countryId: "all-country-id",
          },
        ],
      };
  
      let addedcountrylookup = [allcountrydata, ...countrylookup.data.result];

      let countryarrayoptionsdata =  addedcountrylookup.map((item) => {
        return {
          ...item,
          title: item.name,
          code: item.countryId,
        };
      });
      setcountryarrayoptions(
        countryarrayoptionsdata
      );
      // countryarrayoptionsdata[0]
      setcountryarray([countryarrayoptionsdata[0]]);
      
      let regionarrayoptionsdata = addedcountrylookup[0].regions.map((item) => {
        return {
          ...item,
          title: item.name,
          code: item.regionId,
        };
      });

      setregionarrayoptions(
        regionarrayoptionsdata
      );
      // regionarrayoptionsdata[0]
      setregionarray([regionarrayoptionsdata[0]]);
      // [countryarrayoptionsdata[0], regionarrayoptionsdata[0]]
      setcombinedarray([countryarrayoptionsdata[0], regionarrayoptionsdata[0]]);           
    }, []);


    useEffect(() => {
      loadLookupAction();
    }, []);
    

    const setBorderRadius = (container) => {
        Array.prototype.forEach.call(container, function (rect) {
            if (parseFloat(rect.getAttribute("x")) > 0) {
                rect.setAttribute("stroke-width", 0);
                rect.setAttribute("rx", 4);
                rect.setAttribute("ry", 4);
            }
        });
    };

    useEffect(() => {
      return () => {
        setproviderdetailModal(false);
        setaddAvailabilityModal(false);
        setdeleteAvailabilityModal(false);
        setpopup(false);
        seteditAvailabilityModal(false);
        setreassignAppointmentModal(false);
        setrescheduleAppointmentModal(false);
        setcancelConsultaionConfirmationPopup(false);
        setdeleteconfirmationpopup(false);
        setappointmentpopup(false);
        setrecursiveConfirmation(false);
      };
    }, []);
  

    function arrayEquals(a, b) {
        return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
      };

      const arrayofcolors = (statusarray) => {
        const sortedcolors = statusarray.map((item) => {
          if (item === "C00001") {
            return "#9dd3de";
          } else if (item === "C00002" || item === "C00004") {
            return"#b8c4bb";
          } else if (item === "C00003") {
            return  "#885FA5";
          } else if (item === "C00005") {
            return  "#6BC077";
          }
          
        });
        return sortedcolors;
      };   

    const handleStartDateChange = (date) => {
      settimelinedate(date);
    };

    const addDay = (date) => {
      var result = new Date(date);
      result.setDate(result.getDate() + 1);
      return result;
    }

    const subDay = (date) => {
      var result = new Date(date);
      result.setDate(result.getDate() - 1);
      return result;
    }

    const handleStartDateRightButtonClick = () => {   
      settimelinedate(addDay(timelinedate)); 
    };

    const handleStartDateLeftButtonClick = () => {   
      settimelinedate(subDay(timelinedate)); 
    };

    const updateSearchInputValue = (evt) => {
      setSearchInput(evt.target.value);
    };

    const onSearchClick = () => {
      // let filteredschedule = availabilityunfilteredData.filter(
      //   (x, index) =>
      //     index === 0 ? true: x[0]
      //       .toLowerCase()
      //       .includes(searchInput.toLowerCase()) 
      // )
      let filteredschedule = availabilityunfilteredData.filter((x, index) =>
        index === 0 || searchInput.length === 0
          ? true
          : doctorData.filter(
              (c) =>
                (c.physicianModel.firstName
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                  c.physicianModel.lastName
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                  `${c.physicianModel.firstName} ${c.physicianModel.lastName}`
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())) &&
                c.physicianModel.memberId === x[0]
            ).length > 0
      );
 
            
      setAvailabilityData(filteredschedule);
    };
  

    const setsvg = (container) => {
        Array.prototype.forEach.call(container, function (text) {
            text.outerHTML =
                '<svg height="80" width="300">' +
                '<g fill="none">' +
                '<path stroke="red" d="M5 20 l215 0" />' +
                "</g>" +
                "</svg>";
        });
    };



    const displayDetails = (sender, memberidvalue) => {
      setproviderdetailModal(true);
      if(doctorData && doctorData.length>0){
        let doctor = doctorData.filter((c,memberid)=>{
         return c.physicianModel.memberId.includes(memberidvalue)
        });
        if(doctor.length>0){
          setproviderInfo(doctor);
          setprovidername(`Dr. ${doctor[0].physicianModel.firstName} ${doctor[0].physicianModel.lastName}`);
        }
      }
  };  


    const setupChartEvents = [
        {
            eventName: "ready",
            callback: ({Chart, google, chartWrapper}) => {
                var formatDate = new google.visualization.DateFormat({
                    pattern: "MM/dd/yyyy hh:mm",
                });
                const dataTable = chartWrapper.getDataTable();
                var dataTableGroup = google.visualization.data.group(dataTable, [0]);
                if(dataTableGroup.getNumberOfRows() > 0)  {
                var dateRangeStart = dataTable.getColumnRange(3);
                var dateRangeEnd = dataTable.getColumnRange(4);
                var baseline;
                var baselineBounds;
                var chartElements;
                var markerLine;
                var markerSpan;
                var svg;
                var timeline;
                var timelineUnit;
                var timelineWidth;
                var timespan;
                var markerLabel;
                var rowHeight = 43;
                var options = {
                    // height: (dataTableGroup.getNumberOfRows() * rowHeight) + rowHeight
                    height: arraylengthoftimeline * rowHeight 
                    // height: "90%"                
                };

                baseline = null;
                timeline = null;
                svg = null;
                markerLabel = null;
                var container = document.getElementById("reactgooglegrapharjun");
                chartElements = container.getElementsByTagName("svg");
                if (chartElements.length > 0) {
                    svg = chartElements[0];
                }
                chartElements = container.getElementsByTagName("rect");
                if (chartElements.length > 0) {
                    timeline = chartElements[0];
                }
                chartElements = container.getElementsByTagName("path");
                if (chartElements.length > 0) {
                    baseline = chartElements[0];
                }
                chartElements = container.getElementsByTagName("text");
                if (chartElements.length > 0) {
                    markerLabel = chartElements[0].cloneNode(true);
                }


                // calculate placement
                timelineWidth = parseFloat(timeline.getAttribute("width"));
                baselineBounds = baseline.getBBox();
                timespan = dateRangeEnd.max.getTime() - dateRangeStart.min.getTime();
                timelineUnit = (timelineWidth - baselineBounds.x) / timespan;
                markerSpan = new Date().getTime() - dateRangeStart.min.getTime();
                // add label
                markerLabel.setAttribute("fill", "#067F6F");
                markerLabel.setAttribute("y", 290);
                markerLabel.setAttribute(
                    "x",
                    baselineBounds.x + timelineUnit * markerSpan - 4
                );
                markerLabel.textContent = formatDate.formatValue(
                    new Date(2020, 11, 22, 13, 0, 0)
                    // dateRangeEnd.max.getTime()
                );
                // svg.appendChild(markerLabel);

                markerLine = timeline.cloneNode(true);
                markerLine.setAttribute("y", 0);
                markerLine.setAttribute(
                    "x",
                    baselineBounds.x + timelineUnit * markerSpan
                );
                markerLine.setAttribute('height', options.height);
                markerLine.setAttribute("width", 1);
                markerLine.setAttribute("stroke", "none");
                markerLine.setAttribute("stroke-width", "0");
                markerLine.setAttribute("fill", "#067F6F");
                svg.appendChild(markerLine);
                var rtc = document.getElementsByTagName("rect");
                setBorderRadius(rtc);
                var gridtimes = document.getElementsByTagName("g")[1] &&
                (document.getElementsByTagName("g")[1]).getElementsByTagName("text")[0].getAttribute("text-anchor") === "middle"
                ? document.getElementsByTagName("g")[1] :document.getElementsByTagName("g")[2];
                gridtimes.parentNode.style.top = "50px";
                gridtimes.parentNode.style.overflow = "visible";
                gridtimes.style.overflow = "visible";
                var height =
                    Number(gridtimes.getElementsByTagName("text")[0].getAttribute("y")) -
                    -20;
                gridtimes.setAttribute("transform", "translate(0,-" + height + ")");
            }
                container = document.getElementById("reactgooglegrapharjun");
                let rowLabels = container.getElementsByTagName('text');
                Array.prototype.forEach.call(rowLabels, function (label, index) {
                   
                    label.setAttribute("font-family", "Work Sans");
                    label.setAttribute("font-size", "10px");
                    label.setAttribute("font-weight", 500);
                    
                    if (label.getAttribute('text-anchor') === 'end' && label.parentNode.getAttribute('transform') === null) {
                         let doctordatavalue =doctorData.filter((c)=>{
                          return c.physicianModel.memberId.includes(label.childNodes[0].nodeValue.replace("…", ""))
                        })[0];
                        label.setAttribute("font-family", "Work Sans");
                        label.setAttribute("font-size", "16px");
                        label.setAttribute("text-anchor", "start");
                        label.setAttribute("x", "16.08px");
                        label.setAttribute("font-weight", 600);
                        label.setAttribute("cursor", "pointer");
                        label.setAttribute("memberid", label.childNodes[0].textContent.replace("…", ""));                        
                        label.addEventListener('click', (sender) => displayDetails(sender,label.getAttribute("memberid")), false);
                        label.childNodes[0].textContent = (`Dr. ${doctordatavalue.physicianModel.firstName} ${doctordatavalue.physicianModel.lastName}`).length > 26 ?
                        `Dr. ${doctordatavalue.physicianModel.firstName.slice(0, 8)}... ${doctordatavalue.physicianModel.lastName.slice(0, 8)}...` :
                        `Dr. ${doctordatavalue.physicianModel.firstName} ${doctordatavalue.physicianModel.lastName}`
                      }
                });
                const chart = chartWrapper.getChart();
                google.visualization.events.addListener(chart, "onmouseover", (e) => {
                    rtc = document.getElementsByTagName("rect");
                    setBorderRadius(rtc);
                });
                google.visualization.events.addListener(chart, "onmouseout", (e) => {
                    rtc = document.getElementsByTagName("rect");
                    setBorderRadius(rtc);
                });
          },
        },
        {
            eventName: "select",
            callback({chartWrapper}) {
                let chart = chartWrapper.getChart();
                let selection = chart.getSelection();
                if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const dataTable = chartWrapper.getDataTable();
                    let dctrdata = doctorData.filter((c)=>{
                      return c.physicianModel.memberId.includes( dataTable.getValue(selection[0].row, 0))
                    })[0];
                    setselectedDoctorName(`Dr. ${dctrdata.physicianModel.firstName} ${dctrdata.physicianModel.lastName}`);
                     
                    for(let key in doctorData){
                        if(dataTable.getValue(selection[0].row, 1) === "C00001"){
                        doctorData[key].availabilities.filter(doctor=>{
                          let start = doctor.isSplit?moment(doctor.splitStartDateTime)._d:moment(doctor.startDateTime)._d;
                          let end = doctor.isSplit?moment(doctor.splitEndDateTime)._d:moment(doctor.endDateTime)._d;
                            if(doctor.id === dataTable.getValue(selection[0].row, 2) && moment(start).isSame(moment(dataTable.getValue(selection[0].row,3)))
                             && moment(end).isSame(moment(dataTable.getValue(selection[0].row,4)))){
                                setselectedData(doctor);
                            }
                                
                        })
                    }
                        else if(doctorData[key].appointments){
                            
                            doctorData[key].appointments.filter(appointment=>{
                                if(appointment.appointmentId === dataTable.getValue(selection[0].row, 2)){
                                    setselectedData(appointment);
                                    setselectedClinicianmemberId(doctorData[key].memberId);
                                    setselectedClinicianteamId(doctorData[key].teamId);
                                }
                                    
                            })
                        }
                    }
                    if (dataTable.getValue(selection[0].row, 1) === "C00001") {
                        setpopup(true);
                    } else if (dataTable.getValue(selection[0].row, 1) ===  "C00003") {
                        setappointmentpopup(true);
                    }
                }
            },
        },
    ];

    function createCustomHTMLContent(flagURL) {
        return (
            '<div style="padding:5px 5px 5px 5px;">' +
            '<img src="' +
            flagURL +
            '" style="width:75px;height:50px"><br/>' +
            +"</div>"
        );
    }


    const providermodalCloseHandler = () => {
      setproviderdetailModal(false);
  }
  const providermodalShowCalendarHandler = () => {      
    setproviderdetailModal(false);
    setview(!view);
}

    const deleteconfirmationpopupCloseHandler = () => {
        setdeleteconfirmationpopup(false);
    }

    const deleteconsultaionconfirmationpopupCloseHandler = () => {
        setcancelConsultaionConfirmationPopup(false);
    }

    const deleteconfirmationpopupOpenHandler = () => {
      const pattern = "YYYY-MM-DDTHH:mm:ss.SSS";
      let current_time = moment().format(pattern);
        let event_time = moment(selectedData.startDateTime).format(pattern)
        setOngoing(current_time>event_time);
      setrecursiveConfirmationType(false);
        if(selectedData.isRecurring){
          setrecursiveConfirmation(true);
          setpopup(false);
        }
        else{
          setpopup(false);
        setdeleteconfirmationpopup(true);
        }
    }

  
    const addAvailabilityHandler = () => {
        setaddAvailabilityModal(true);
    }
    const editAvailabilityHandler = () => {
      setrecursiveConfirmationType(true);
      if(selectedData.isRecurring){
        setrecursiveConfirmation(true);
        setpopup(false);
      }
      else{
        setpopup(false);
        seteditAvailabilityModal(true);
      }
    }
    const modalCloseHandler = () => {
        setaddAvailabilityModal(false);
        setdeleteAvailabilityModal(false);
        setpopup(false);
        seteditAvailabilityModal(false);
        setreassignAppointmentModal(false);
        setrescheduleAppointmentModal(false);
        setcancelConsultaionConfirmationPopup(false);
        setdeleteconfirmationpopup(false);
        
    }
    const onSubmitcancelConsultation =()=>{
      setReassignAppointmentSuccess(false);
      setcancelConsultationsuccess(false);
      modalCloseHandler();
      settimelinedate(new Date(timelinedate));
      setAddAvailabilitysuccess(false);
      seteditAvailabilitysuccess(false);
      setdeleteAvailabilitysuccess(false);
      setresheduleConsultationsuccess(false);
      setReassignAppointmentSuccess(false);
      setcancelConsultationsuccess(true);
    }
    const onSubmitReassignHandler =(avaialabilityalertpropsdata)=>{
      setReassignAppointmentSuccess(false);
      setcancelConsultationsuccess(false);
      modalCloseHandler();
      setavaialabilityalertprops(avaialabilityalertpropsdata);
      settimelinedate(new Date(timelinedate));
      setAddAvailabilitysuccess(false);
      seteditAvailabilitysuccess(false);
      setdeleteAvailabilitysuccess(false);
      setresheduleConsultationsuccess(false);
      setReassignAppointmentSuccess(true);
    }
    const submitHandler =()=>{
        modalCloseHandler();
        settimelinedate(new Date(timelinedate));
        
    }
    const onSubmitDeleteAvailabilityhandler =(avaialabilityalertpropsdata)=>{
      setdeleteAvailabilitysuccess(false);
      setcancelConsultationsuccess(false);
      setavaialabilityalertprops(avaialabilityalertpropsdata);
      modalCloseHandler();
      settimelinedate(new Date(timelinedate));
      setAddAvailabilitysuccess(false);
      seteditAvailabilitysuccess(false);
      setReassignAppointmentSuccess(false);
      setresheduleConsultationsuccess(false);
      setdeleteAvailabilitysuccess(true);
      
  }
    const onSubmitAddAvailabilityhandler =(avaialabilityalertpropsdata)=>{
      setAddAvailabilitysuccess(false);
      setcancelConsultationsuccess(false);
      setavaialabilityalertprops(avaialabilityalertpropsdata);
      modalCloseHandler();
      settimelinedate(new Date(timelinedate));
      seteditAvailabilitysuccess(false);
      setdeleteAvailabilitysuccess(false);
      setReassignAppointmentSuccess(false);
      setresheduleConsultationsuccess(false);
      setAddAvailabilitysuccess(true);
      
  }
  const onSubmitEditAvailabilityhandler =()=>{
    seteditAvailabilitysuccess(false);
    setcancelConsultationsuccess(false);
    setavaialabilityalertprops(selectedDoctorName);
    modalCloseHandler();
    settimelinedate(new Date(timelinedate));
    setAddAvailabilitysuccess(false);
      setdeleteAvailabilitysuccess(false);
      setReassignAppointmentSuccess(false);
      setresheduleConsultationsuccess(false);
      seteditAvailabilitysuccess(true);

    
}
const onSubmitReschedulehandler =()=>{
  setresheduleConsultationsuccess(false);
  setcancelConsultationsuccess(false);
  modalCloseHandler();
  settimelinedate(new Date(timelinedate));
  setAddAvailabilitysuccess(false);
      seteditAvailabilitysuccess(false);
      setdeleteAvailabilitysuccess(false);
      setReassignAppointmentSuccess(false);
      setresheduleConsultationsuccess(true);
}

    const appointmentmodalCloseHandler = () => {
        setaddAvailabilityModal(false);
        setdeleteAvailabilityModal(false);
        setappointmentpopup(false);
    }
    const deleteAvailabilityHandler = () => {
        setdeleteAvailabilityModal(true);
    }

    const rescheduleHandler = () => {
        setappointmentpopup(false);
        setrescheduleAppointmentModal(true);
    }

    const cancelappointmentHandler = () => {
        setappointmentpopup(false);
        setcancelConsultaionConfirmationPopup(true);
    }

    const reassignHandler = () => {
        setappointmentpopup(false);
        setreassignAppointmentModal(true);
    }

    const onRefreshClick = () => {
      if(view)
        setrefreshaction(Date.now());
      else{
        settimelinedate(new Date())
      }
    };

    const alertCloseHandler =()=>{
      setresheduleConsultationsuccess(false);
      setAddAvailabilitysuccess(false);
      seteditAvailabilitysuccess(false);
      setdeleteAvailabilitysuccess(false);
      setReassignAppointmentSuccess(false);
    }
    const recursiveConfirmationHandler=()=>{
      setrecursiveConfirmation(false);
    }
    const recursiveSeriesConfirmHanlder=(value)=>{
      setrecursiveSeries(value);
      setrecursiveConfirmation(false);
      setpopup(false);
      if(recursiveConfirmationType){
        seteditAvailabilityModal(true);
      }
      else{
        setdeleteconfirmationpopup(true);
      }
    }

  const popoveropen = Boolean(anchorEl);
  const popoverid = popoveropen ? "simple-popover" : undefined;
    
  const handlepopoveropenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  
  const handlepopoverapplyclick = () => {
    setAnchorEl(null);
    setapplyadvancefilter(!applyadvancefilter);
  };


  const handlepopoverresetclick = () => {
    setAnchorEl(null);
    setcountryarray([]);
    setregionarray([]);
    setcombinedarray([]);
    setTimeout(() => {
      setapplyadvancefilter(!applyadvancefilter);
    }, 100);
  };

  const handlepopovercloseclick = () => {
    setAnchorEl(null);
  };

  const changeregionarray =(newregionarray, details) => {
    var newregionarraydata = [];
    if (
      details.option.name === "All" &&
      regionarray.some((el) => el.name === "All")
    ) {
      newregionarraydata = [];
    }
    else if (
      details.option.name === "All" &&
      !regionarray.some((el) => el.name === "All")
    ) {
      newregionarraydata = [...regionarrayoptions];
    }
    else if (
      regionarray.some((el) => el.name === details.option.name)
    ) {
      let filteralnonallregionarray = newregionarray.filter(
        (x) => x.name !== "All"
      );
      newregionarraydata =  [...filteralnonallregionarray];
    }

    else {
      newregionarraydata =  [...newregionarray]; 
    }

    setregionarray([
      ...newregionarraydata
    ]);
    setcombinedarray([...new Set([...countryarray,...newregionarraydata])])
  }

  const changecountryarray =(newcountryarray, details) => {
    var newcountryarraydata = [];
    if (
      details.option.name === "All" &&
      countryarray.some((el) => el.name === "All")
    ) {
     newcountryarraydata = [];
    }
    else if (
      details.option.name === "All" &&
      !countryarray.some((el) => el.name === "All")
    ) {
      newcountryarraydata = [...countryarrayoptions];
    }
    else if (
      countryarray.some((el) => el.name === details.option.name)
    ) {
      let filteralnonallcountryarray = newcountryarray.filter(
        (x) => x.name !== "All"
      );
      newcountryarraydata =  [...filteralnonallcountryarray];
    }

    else {
      newcountryarraydata =  [...newcountryarray]; 
    }

    setcountryarray([
      ...newcountryarraydata
    ]);
    let regionarrayoptionsdatatemp = newcountryarraydata.map((item) => {
      let combinedregionArray = [];
      combinedregionArray.push(...item.regions);
      return combinedregionArray;
    });
    let regionarrayoptionsdataflatten =  Array.prototype.concat.apply([], regionarrayoptionsdatatemp);
    let regionarrayoptionsdatatemp2 = regionarrayoptionsdataflatten.map((item) =>{
    return {
      ...item,
      title: item.name,
      code: item.regionId,
    }});

    setregionarrayoptions(
      regionarrayoptionsdatatemp2
    );

    let regionfilteredarraysellected = regionarrayoptionsdatatemp2.filter(x=>{
      if(regionarray.filter(y=> y.name === x.name).length > 0)
        return x;
    });
    setregionarray(regionfilteredarraysellected);
    setcombinedarray([...new Set([...newcountryarray,...regionfilteredarraysellected])])
  }


  
  const removetag = (chipToDelete) => {
    let newregionarray = [...regionarray];
    newregionarray = newregionarray.filter((item) => item.name !== chipToDelete.name);
    // newregionarray = newregionarray.filter((item) => item.code !== chipToDelete.code);

    let newcountryarray = [...countryarray];
    newcountryarray = newcountryarray.filter((item) => item.name !== chipToDelete.name);
    setregionarray([...newregionarray]);
    setcountryarray([...newcountryarray]);
    setcombinedarray([...new Set([...newcountryarray,...newregionarray])]);
    setTimeout(() => {
      setapplyadvancefilter(!applyadvancefilter);
    }, 100);
  };

    return (
      <Container maxWidth="xl">
        <>
          <Box mt={2} />
          <Grid alignItems="center" container justify="space-between">
            <Grid item>
              <Grid item container alignItems="center" direction="row">
                {!view && (
                  <Grid item>
                    <div className="telekardia">
                      <button
                        className="btn btn-back"
                        onClick={providermodalShowCalendarHandler}
                      >
                        <ArrowBackSharpIcon className="prev-icon" />
                      </button>
                    </div>
                  </Grid>
                )}
                <Grid item>
                  <MuiTypography
                    fontSize="34px"
                    fontFamily="Work Sans"
                    lineHeight="40px"
                    letterSpacing="0.25px"
                    fontWeight={500}
                    maxWidth="500px"
                    noWrap={true}
                    title={providername}
                    color="#000000"
                  >
                    {view ? t("Scheduler") : providername}
                  </MuiTypography>
                  <BottomLineTitle />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item container alignItems="center" direction="row">
                {(mediawidth === "xl" || mediawidth === "lg") && (
                  <Grid item>
                    <MuiTypography
                      fontSize="12px"
                      fontFamily="Work Sans"
                      lineHeight="20px"
                      letterSpacing="-0.2px"
                      textTransform="uppercase"
                      color="#536063"
                    >
                      {t("LAST REFRESHED")}
                    </MuiTypography>
                  </Grid>
                )}
                {(mediawidth === "xl" || mediawidth === "lg") && (
                  <Grid item>
                    <MuiTypography
                      fontSize="12px"
                      fontFamily="Work Sans"
                      lineHeight="20px"
                      letterSpacing="-0.2px"
                      textTransform="uppercase"
                      color="#536063"
                      fontWeight={700}
                    >
                      &nbsp;{moment(refreshaction).format("hh:mm A")}
                    </MuiTypography>
                  </Grid>
                )}
                {(mediawidth === "xl" || mediawidth === "lg") && (
                  <Grid item>
                    <IconButton onClick={() => onRefreshClick()}>
                      <CachedIcon style={{ color: "#142A39" }} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Box pt={2} />
          <Grid
            alignItems="center"
            container
            justify="space-between"
            className={classes.topbar}
          >
            <Grid item>
              <ThemeProvider theme={muitheme}>
                <Input
                  className={
                    mediawidth === "xl" || mediawidth === "lg"
                      ? classes.largeinput
                      : classes.largeinputtablet
                  }
                  id="standard-adornment-password"
                  placeholder={t("Search cardiologist")}
                  value={searchInput}
                  onChange={(e) => updateSearchInputValue(e)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      onSearchClick(); // here was the mistake
                    }
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onSearchClick}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Grid item container alignItems="center" direction="row">
                <Grid item>
                  <div className="telekardia">
                    <button
                      type="button"
                      className="btn m-1 px-3 btn-outline-primary text-uppercase h40"
                      onClick={deleteAvailabilityHandler}
                    >
                      {t("DELETE AVAILABILITY")}
                    </button>
                  </div>
                </Grid>
                <Grid item>
                  <div className="telekardia">
                    <button
                      type="button"
                      className="btn m-1 btn_teal py-2 px-4"
                      onClick={addAvailabilityHandler}
                    >
                      <AddIcon style={{ top: -2, left: -5, color: "FFFFFF" }} />
                      {t("ADD AVAILABILITY")}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {reassignAppointmentSuccess && (
            <Box pt={2}>
              <AvailabilityAlert
                component="AdminCalendar"
                title={` The consultation has been successfully assigned to ${avaialabilityalertprops.doctorFirstName} ${avaialabilityalertprops.doctorLastName}`}
                onclose={alertCloseHandler}
              />
            </Box>
          )}
          {addAvailabilitysuccess && (
            <Box pt={2}>
              <AvailabilityAlert
                component="AdminCalendar"
                onclose={alertCloseHandler}
                title={
                  avaialabilityalertprops.isRecurring
                    ? `Recursive Availability from ${avaialabilityalertprops.startDate}
                   to ${avaialabilityalertprops.endDate} from ${avaialabilityalertprops.startTime} to ${avaialabilityalertprops.endTime} on  ${avaialabilityalertprops.days} added successfully for
                 ${avaialabilityalertprops.doctorFirstName} ${avaialabilityalertprops.doctorLastName}`
                    : `Availability on ${avaialabilityalertprops.startDate}, 
                ${avaialabilityalertprops.startTime} to ${avaialabilityalertprops.endTime} added successfully for
                 ${avaialabilityalertprops.doctorFirstName} ${avaialabilityalertprops.doctorLastName}`
                }
              />
            </Box>
          )}
          {editAvailabilitysuccess && (
            <Box pt={2}>
              <AvailabilityAlert
                component="AdminCalendar"
                onclose={alertCloseHandler}
                title={`Availability for ${selectedDoctorName.substring(
                  3
                )} changed successfully`}
              />
            </Box>
          )}
          {resheduleConsultationsuccess && (
            <Box pt={2}>
              <AvailabilityAlert
                component="AdminCalendar"
                component="AdminCalendar"
                onclose={alertCloseHandler}
                title={`The consultation has been rescheduled successfully`}
              />
            </Box>
          )}
          {deleteAvailabilitysuccess && (
            <Box pt={2}>
              <AvailabilityAlert
                component="AdminCalendar"
                onclose={alertCloseHandler}
                title={
                  avaialabilityalertprops.isRecurring
                    ? `${
                        view
                          ? selectedDoctorName.substring(3)
                          : providername.substring(3)
                      }'s availability on selected days 
                has been deleted successfully`
                    : `${
                        view
                          ? selectedDoctorName.substring(3)
                          : providername.substring(3)
                      }'s availability has been deleted successfully`
                }
              />
            </Box>
          )}
          {cancelConsultationsuccess && (
            <Box pt={2}>
              <AvailabilityAlert
                component="AdminCalendar"
                onclose={alertCloseHandler}
                title={`The consultation has been canceled successfully`}
              />
            </Box>
          )}
          {view ? (
            <div
              className={
                mediawidth === "xl" || mediawidth === "lg"
                  ? classes.scrollablegrid
                  : classes.scrollablegridtablet
              }
            >
              <Box mt={2} pt={5} />
              <Grid
                alignItems="center"
                spacing={mediawidth === "xl" || mediawidth === "lg" ? 0 : null}
                container
                justify="space-between"
                className={
                  mediawidth === "xl" || mediawidth === "lg"
                    ? classes.topbarroot
                    : classes.topbarroottablet
                }
                style={{ display: "-ms-flexbox" }}
              >
                <Grid item>
                  <Grid
                    alignItems="center"
                    spacing={2}
                    container
                    justify="flex-start"
                  >
                    <Grid item>
                      <MuiTypography
                        fontFamily="Work Sans"
                        fontSize="16px"
                        fonStyle="normal"
                        fontWeight={600}
                        lineHeight="20px"
                        color="#142A39"
                      >
                        Filter
                      </MuiTypography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={handlepopoveropenClick}
                        aria-label="Search"
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      {combinedarray &&
                        combinedarray.length > 0 &&
                        combinedarray.map((value) => (
                          <>
                            <Chip
                              variant="outlined"
                              className={classes.chipfont}
                              key={value.code}
                              label={
                                "regions" in value
                                  ? `Country - ${value.title}`
                                  : `Region - ${value.title}`
                              }
                              onDelete={() => removetag(value)}
                            />
                            &nbsp;
                          </>
                        ))}
                    </Grid>
                  </Grid>
                  <Popover
                    id={popoverid}
                    open={popoveropen}
                    anchorEl={anchorEl}
                    className={classes.popoverclass}
                    modifiers={{
                      flip: {
                        enabled: true,
                      },
                      preventOverflow: {
                        enabled: false,
                        boundariesElement: "scrollParent",
                      },
                      arrow: {
                        enabled: true,
                        // element: arrowRef,
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Grid
                      item
                      container
                      justify="flex-end"
                      alignItems="flex-end"
                      direction="row"
                      spacing={2}
                    >
                      <Grid item>
                        <IconButton
                          aria-label="close"
                          onClick={handlepopovercloseclick}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      className={classes.popovergrid}
                    >
                      <Grid item>
                        <InputLabel
                          ref={inputLabel1}
                          className={classes.formControllabelstyle}
                        >
                          {t("Country")}
                        </InputLabel>
                        <FormControl
                          variant="outlined"
                          className={
                            mediawidth === "xl" || mediawidth === "lg"
                              ? classes.formControlchip
                              : classes.formControlchipsmall
                          }
                        >
                          <Select
                            multiple
                            className="not-rounded"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newvals}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            }}
                            renderValue={(selected) =>
                              countryarray.length > 0 ? (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  {`${countryarray
                                    .map((x) => x.title)
                                    .join()
                                    .slice(0, 30)}...`}
                                </MuiTypography>
                              ) : (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  All
                                </MuiTypography>
                              )
                            }
                            input={
                              <OutlinedInput
                                labelWidth={0}
                                name="status"
                                id="outlined-status-simple"
                                classes={outlinedInputClasses}
                              />
                            }
                          >
                            <StyledMenuItem
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={countryarrayoptions}
                                disableCloseOnSelect
                                disableClearable
                                classes={{
                                  inputRoot: classes.autocompleteinput,
                                }}
                                getOptionLabel={(option) => option.title}
                                getOptionSelected={(option, value) =>
                                  value.code === option.code
                                }
                                onKeyDown={(e) => e.stopPropagation()}
                                renderTags={(tagValue, getTagProps) => {
                                  return <></>;
                                }}
                                style={{ width: "240px" }}
                                value={countryarray}
                                onChange={(event, newValue, reason, details) => {
                                  changecountryarray(newValue, details);
                                }}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <GreenCheckbox
                                      color="primary"
                                      checked={selected}
                                      style={{ marginRight: 8 }}
                                    />
                                    <MuiTypography
                                      fontFamily="Work Sans"
                                      fontSize="16px"
                                      fonStyle="normal"
                                      fonWeight="400"
                                      lineHeight="20px"
                                      color="#142A39"
                                    >
                                      {option.title}
                                    </MuiTypography>
                                  </React.Fragment>
                                )}
                                // style={{ padding: 10 }}
                                renderInput={(params) => (
                                  <CssTextField
                                    {...params}
                                    label={props.label}
                                    placeholder="Select country"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </StyledMenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <InputLabel
                          ref={inputLabel1}
                          className={classes.formControllabelstyle}
                        >
                          {t("Region")}
                        </InputLabel>

                        <FormControl
                          variant="outlined"
                          className={
                            mediawidth === "xl" || mediawidth === "lg"
                              ? classes.formControlchip
                              : classes.formControlchipsmall
                          }
                        >
                          <Select
                            multiple
                            className="not-rounded"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newvals}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            }}
                            renderValue={(selected) =>
                              regionarray.length > 0 ? (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  {`${regionarray
                                    .map((x) => x.title)
                                    .join()
                                    .slice(0, 30)}...`}
                                </MuiTypography>
                              ) : (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  All
                                </MuiTypography>
                              )
                            }
                            input={
                              <OutlinedInput
                                labelWidth={0}
                                name="status"
                                id="outlined-status-simple-region"
                                classes={outlinedInputClasses}
                              />
                            }
                          >
                            <StyledMenuItem
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={regionarrayoptions}
                                disableCloseOnSelect
                                disableClearable
                                classes={{
                                  inputRoot: classes.autocompleteinput,
                                }}
                                getOptionLabel={(option) => option.title}
                                getOptionSelected={(option, value) =>
                                  value.code === option.code
                                }
                                onKeyDown={(e) => e.stopPropagation()}
                                renderTags={(tagValue, getTagProps) => {
                                  return <></>;
                                }}
                                style={{ width: "240px" }}
                                value={regionarray}
                                onChange={(event, newValue, reason, details) => {
                                  changeregionarray(newValue, details);
                                }}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <GreenCheckbox
                                      color="primary"
                                      checked={selected}
                                      style={{ marginRight: 8 }}
                                    />
                                    <MuiTypography
                                      fontFamily="Work Sans"
                                      fontSize="16px"
                                      fonStyle="normal"
                                      fonWeight="400"
                                      lineHeight="20px"
                                      color="#142A39"
                                    >
                                      {option.title}
                                    </MuiTypography>
                                  </React.Fragment>
                                )}
                                // style={{ padding: 10 }}
                                renderInput={(params) => (
                                  <CssTextField
                                    {...params}
                                    label={props.label}
                                    placeholder="Select region"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </StyledMenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        container
                        justify="flex-end"
                        alignItems="flex-end"
                        direction="row"
                        spacing={2}
                      >
                        <Grid item>
                          <Button
                            style={buttonstyle}
                            onClick={handlepopoverresetclick}
                          >
                            <MuiTypography
                              fontSize="14px"
                              lineHeight="16.42px"
                              fontFamily="Work Sans"
                              letterSpacing="0.75px"
                              fontWeight={700}
                              color="#2D9F86"
                            >
                              {t("CLEAR ALL")}
                            </MuiTypography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <div className="telekardia">
                            <button
                              type="button"
                              className="btn m-1 btn_teal py-2 px-4"
                              onClick={handlepopoverapplyclick}
                            >
                              {t("APPLY")}
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>

                {(mediawidth === "sm" || mediawidth === "md") && (
                  <Grid item>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                    >
                      <Grid item>
                        <SvgIcon viewBox="0 0 32 32">
                          <path
                            d="M10.667 0h10.667c5.891 0 10.667 4.776 10.667 10.667v10.667c0 5.891-4.776 10.667-10.667 10.667h-10.667c-5.891 0-10.667-4.776-10.667-10.667v-10.667c0-5.891 4.776-10.667 10.667-10.667z"
                            fill="#9dd3de"
                          />
                        </SvgIcon>
                      </Grid>
                      <Grid item>&nbsp;{t("Availability")}</Grid>
                      <Grid item>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <SvgIcon viewBox="0 0 32 32">
                          <path
                            d="M10.667 0h10.667c5.891 0 10.667 4.776 10.667 10.667v10.667c0 5.891-4.776 10.667-10.667 10.667h-10.667c-5.891 0-10.667-4.776-10.667-10.667v-10.667c0-5.891 4.776-10.667 10.667-10.667z"
                            fill="#885FA5"
                          />
                        </SvgIcon>
                      </Grid>
                      <Grid item>&nbsp;{t("Scheduled")}</Grid>
                      <Grid item>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <SvgIcon viewBox="0 0 32 32">
                          <path
                            d="M10.667 0h10.667c5.891 0 10.667 4.776 10.667 10.667v10.667c0 5.891-4.776 10.667-10.667 10.667h-10.667c-5.891 0-10.667-4.776-10.667-10.667v-10.667c0-5.891 4.776-10.667 10.667-10.667z"
                            fill="#E2E7E3"
                          />
                        </SvgIcon>
                      </Grid>
                      <Grid item>&nbsp;{t("Completed /closed")}</Grid>
                      <Grid item>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <SvgIcon viewBox="0 0 32 32">
                          <path
                            d="M10.667 0h10.667c5.891 0 10.667 4.776 10.667 10.667v10.667c0 5.891-4.776 10.667-10.667 10.667h-10.667c-5.891 0-10.667-4.776-10.667-10.667v-10.667c0-5.891 4.776-10.667 10.667-10.667z"
                            fill="#6BC077"
                          />
                        </SvgIcon>
                      </Grid>
                      <Grid item>&nbsp;{t("In progress")}</Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  {mediawidth === "xl" || mediawidth === "lg" ? (
                    <Grid
                      alignItems="center"
                      spacing={2}
                      container
                      justify="flex-start"
                    >
                      <Grid item>
                        <IconButton
                          classes={{
                            root: classes.smallButton,
                            disabled: classes.buttonDisabled,
                          }}
                          aria-label="Delete"
                          //disabled={timelinedate <= new Date()}
                          onClick={handleStartDateLeftButtonClick}
                        >
                          <SvgIcon viewBox="0 0 8 12" style={{ fontSize: 20 }}>
                            <path
                              d="M7.4 1.4L6 0L0 6L6 12L7.4 10.6L2.8 6L7.4 1.4Z"
                              fill="#142A39"
                            />
                          </SvgIcon>
                        </IconButton>
                      </Grid>

                      <Grid item>
                        <Datepicker
                          variant="inline"
                          label={false}
                          padding="10px"
                          date={timelinedate}
                          changeHandle={handleStartDateChange}
                        />
                      </Grid>

                      <Grid item>
                        <IconButton
                          className={classes.smallButton}
                          aria-label="Delete"
                          onClick={handleStartDateRightButtonClick}
                        >
                          <SvgIcon viewBox="0 0 8 12" style={{ fontSize: 20 }}>
                            <path
                              d="M1.4 0L0 1.4L4.6 6L0 10.6L1.4 12L7.4 6L1.4 0Z"
                              fill="#142A39"
                            />
                          </SvgIcon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item>
                      {/* <MuiTypography
                        fontSize="12px"
                        fontFamily="Work Sans"
                        letterSpacing="0.4px"
                        fontWeight={500}
                        color="rgba(20, 42, 57, 0.72)"
                      >
                        Showing for
                      </MuiTypography> */}
                      <Datepicker
                        variant="inline"
                        padding="10px"
                        width={
                          (mediawidth === "sm" || mediawidth === "md") &&
                          "170px"
                        }
                        date={timelinedate}
                        changeHandle={handleStartDateChange}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Box pt={5} style={{ background: "#FFFFFF" }} />
              {isLoading ? (
                <EcgLoader />
              ) : (
                <div className="telekardia">
                  {availabilityData.length > 1 ? (
                    <Grid id="reactgooglegrapharjun">
                      <Chart
                        // width= {(mediawidth === "xl" || mediawidth === "lg") ? 1645 : 1200}
                        height="100%"
                        // height={`${arraylengthoftimeline * 50 + 50}px`}
                        chartType="Timeline"
                        loader={<EcgLoader />}
                        chartEvents={setupChartEvents}
                        data={availabilityData}
                        options={{
                          tooltip: { trigger: "none" },
                          allowHtml: true,
                          colors: moment().isBefore(timelinedate)
                            ? ["#9dd3de", "#885FA5", "#885FA5", "#885FA5"]
                            : arrayofcolors(arrayofcolorsdata),
                          height: arraylengthoftimeline * 50 + 50,
                          timeline: {
                            barHeight: 32,
                            showBarLabels: false,
                            groupByRowLabel: true,
                            rowLabelStyle: {
                              fontSize: 20,
                              color: "#333333",
                            },
                            barLabelStyle: {
                              fontSize: 14,
                            },
                          },
                          hAxis: {
                            format: "hh:mm a",
                          },
                          labelStyle: {
                            fontName: "Work Sans",
                            fontSize: 34,
                            color: "#000000",
                          },
                        }}
                        rootProps={{ "data-testid": "4" }}
                      />
                      {(mediawidth === "xl" || mediawidth === "lg") && (
                        <Grid
                          item
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Grid item>
                            <SvgIcon viewBox="0 0 32 32">
                              <path
                                d="M10.667 0h10.667c5.891 0 10.667 4.776 10.667 10.667v10.667c0 5.891-4.776 10.667-10.667 10.667h-10.667c-5.891 0-10.667-4.776-10.667-10.667v-10.667c0-5.891 4.776-10.667 10.667-10.667z"
                                fill="#9dd3de"
                              />
                            </SvgIcon>
                          </Grid>
                          <Grid item>{t("Availability")}</Grid>
                          <Grid item>
                            <SvgIcon viewBox="0 0 32 32">
                              <path
                                d="M10.667 0h10.667c5.891 0 10.667 4.776 10.667 10.667v10.667c0 5.891-4.776 10.667-10.667 10.667h-10.667c-5.891 0-10.667-4.776-10.667-10.667v-10.667c0-5.891 4.776-10.667 10.667-10.667z"
                                fill="#885FA5"
                              />
                            </SvgIcon>
                          </Grid>
                          <Grid item>{t("Scheduled")}</Grid>
                          <Grid item>
                            <SvgIcon viewBox="0 0 32 32">
                              <path
                                d="M10.667 0h10.667c5.891 0 10.667 4.776 10.667 10.667v10.667c0 5.891-4.776 10.667-10.667 10.667h-10.667c-5.891 0-10.667-4.776-10.667-10.667v-10.667c0-5.891 4.776-10.667 10.667-10.667z"
                                fill="#E2E7E3"
                              />
                            </SvgIcon>
                          </Grid>
                          <Grid item>{t("Completed /closed")}</Grid>
                          <Grid item>
                            <SvgIcon viewBox="0 0 32 32">
                              <path
                                d="M10.667 0h10.667c5.891 0 10.667 4.776 10.667 10.667v10.667c0 5.891-4.776 10.667-10.667 10.667h-10.667c-5.891 0-10.667-4.776-10.667-10.667v-10.667c0-5.891 4.776-10.667 10.667-10.667z"
                                fill="#6BC077"
                              />
                            </SvgIcon>
                          </Grid>
                          <Grid item>{t("In progress")}</Grid>
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    !isLoading &&
                    availabilityData.length === 1 && (
                      <Alert date={new Date()} title="No data to display" />
                    )
                  )}
                </div>
              )}
            </div>
          ) : providerInfo ? (
            <Calendar
              profileId={providerInfo[0].memberId}
              teamId={providerInfo[0].teamId}
              date={timelinedate}
              selectedDoctorName={providername}
              onSubmitEditAvailabilityhandler={onSubmitEditAvailabilityhandler}
              onSubmitDeleteAvailabilityhandler={
                onSubmitDeleteAvailabilityhandler
              }
            />
          ) : null}

          { modalCloseHandler &&
            <AvailabilityForm
              show={addAvailabilityModal}
              view={view}
              providerName={providername}
              handleClose={modalCloseHandler}
              startDate={selectedtimelineDate}
              endDate={selectedtimelineDate}
              startTime={selectedtimelineDate}
              endTime={selectedtimelineDate}
              onSubmitAddAvailability={onSubmitAddAvailabilityhandler}
              // onSubmit={submitHandler}
              recurrsivepattern={"NotRecursive"}
              component="toolbar"
              type="add"
              title={"Add availability"}
            />
          }
          { deleteAvailabilityModal &&
            <AvailabilityForm
              doctorData={doctorData}
              view={view}
              providerName={providername}
              currenttimeline={timelinedate}
              show={deleteAvailabilityModal}
              handleClose={modalCloseHandler}
              startDate={selectedtimelineDate}
              endDate={selectedtimelineDate}
              startTime={selectedtimelineDate}
              endTime={selectedtimelineDate}
              onSubmit={onSubmitDeleteAvailabilityhandler}
              recurrsivepattern={"NotRecursive"}
              component="toolbar"
              type="delete"
              title={"Delete availability"}
            />
          }
          {editAvailabilityModal && (
            <AvailabilityForm
              seriesConfirm={recursiveSeries}
              view={view}
              selectedData={selectedDoctorName}
              selectedDoctor={selectedData}
              show={editAvailabilityModal}
              handleClose={modalCloseHandler}
              startDate={selectedData.startDateTime}
              endDate={selectedData.endDateTime}
              startTime={selectedData.startDateTime}
              endTime={selectedData.endDateTime}
              onSubmit={onSubmitEditAvailabilityhandler}
              isRecursive={selectedData.isRecurring}
              component="popover"
              type="edit"
              title={"Edit availability"}
            />
          )}
          {recursiveConfirmation && (
            <RecursiveConfirmationPopup
              show={recursiveConfirmation}
              onClose={recursiveConfirmationHandler}
              type={recursiveConfirmationType}
              recursiveSeriesConfirmHanlder={recursiveSeriesConfirmHanlder}
            />
          )}
          {popup && (
            <Popup
              selectedDoctorName={selectedDoctorName}
              open={popup}
              handleClose={modalCloseHandler}
              onEdit={editAvailabilityHandler}
              data={selectedData}
              onDelete={deleteconfirmationpopupOpenHandler}
            />
          )}
          {
            <AppointmentPopup
              open={appointmentpopup}
              handleClose={appointmentmodalCloseHandler}
              data={selectedData}
              selectedDoctorName={selectedDoctorName}
              onReschedule={rescheduleHandler}
              onCancel={cancelappointmentHandler}
              onReassign={reassignHandler}
            />
          }
          {
            <ProviderPopUp
              open={providerdetailModal}
              providername={providername}
              providerInfo={providerInfo}
              handleClose={providermodalCloseHandler}
              data={doctorData}
              showCalendarView={providermodalShowCalendarHandler}
            />
          }
          {reassignAppointmentModal === true && (
            <ReAssignAppointment
              date={timelinedate}
              show={reassignAppointmentModal}
              date={timelinedate}
              handleClose={modalCloseHandler}
              adminTeamId={teamId}
              memberId={selectedClinicianmemberId}
              data={selectedData}
              onSubmit={onSubmitReassignHandler}
              onCancel={submitHandler}
            />
          )}
          {rescheduleAppointmentModal === true && (
            <ReScheduleAppointment
              show={rescheduleAppointmentModal}
              handleClose={modalCloseHandler}
              teamId={selectedClinicianteamId}
              memberId={selectedClinicianmemberId}
              data={selectedData}
              adminId={clinicianId}
              onSubmit={onSubmitReschedulehandler}
            />
          )}

          {deleteconfirmationpopup && (
            <DeleteConfirmationPopup
              open={deleteconfirmationpopup}
              handleClose={deleteconfirmationpopupCloseHandler}
              data={selectedData}
              series={recursiveSeries}
              selectedDoctorName={selectedDoctorName}
              ongoing={ongoing}
              onCancel={onSubmitDeleteAvailabilityhandler}
            />
          )}

          {cancelConsultaionConfirmationPopup === true && (
            <CancelConsultaionConfirmationPopup
              open={cancelConsultaionConfirmationPopup}
              handleClose={deleteconsultaionconfirmationpopupCloseHandler}
              data={selectedData}
              onCancel={onSubmitcancelConsultation}
              memberId={selectedClinicianmemberId}
            />
          )}
        </>
      </Container>
    );
}

export default AdminCalendar;
