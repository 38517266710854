/* eslint-disable */
import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  IconButton,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {useSelector} from 'react-redux';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
       <Typography variant="h6">&nbsp;</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const AppointmentDetails = (props) => {

  const loading=useSelector((state) => state.appointments.loading);


  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.show}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        {props.component === "popover" && (
          <DialogTitle
            id="customized-dialog-title"
            onClose={props.handleClose}
          />
        )}

        <DialogContent dividers>
          <div
            className={loading ? "filtering" : null}
            {...props}
          >
            <div className={loading ? "spinner-border" : null}></div>
            {props.children}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AppointmentDetails;
