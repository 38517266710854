/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { Grid, Container, Box, IconButton } from "@material-ui/core";
import ListPageAdminConsultations from "../Shared/List/ListPageAdminConsultations";
import ConsultationsDetailModal from "./ConsultationsDetailModal";
import apiservice from "../../api/services/apiservice.service";
import { streamToPromise, getTimezoneName } from "../../Utilities/Utilities";
import get from "lodash.get";
import { useSelector } from "react-redux";
import moment from "moment";
import Timezone from 'moment-timezone'
import BottomLineTitle from "../Shared/Typography/BottomLineTitle";
import MuiTypography from "../Shared/Typography/MuiTypography";
import CachedIcon from "@material-ui/icons/Cached";
import { useTranslation } from "react-i18next";
import ReportModal from "../AllAppointments/ReportModal";

function AdminConsultations(props) {
  const { t } = useTranslation();
  const { history } = props;
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const timezoneString = useSelector(state=>state.user.team.timezone)
  const timezoneValue = getTimezoneName();
  const offset = -(Timezone.tz(timezoneString).utcOffset());
  const [, setpageSize] = useState("");
  const [
    isConsultationDetailModalOpen,
    setisConsultationDetailModalOpen,
  ] = useState(false);

  const [appointidofmodal, setappointidofmodal] = useState("");
  const [activeClinicianId,setactiveClinicianId] = useState("");
  const [patientinfo, setpatientinfo] = useState({});
  const [sortPropsName, setSortPropsName] = useState("Date");
  const [sortordertype, setSortordertype] = useState("asc");
  const [memberId, setmemberId] = useState("");
  const [isReportModalOpen, setisReportModalOpen] = useState(false);
  

  const consultationDetailModalOpenCloseHandler = () => {
    setisConsultationDetailModalOpen(false);
  };

  const [refreshaction, setrefreshaction] = useState(Date.now());
  const [refreshlistaction, setrefreshlistaction] = useState(false);

  const onRefreshClick = () => {
    setrefreshaction(Date.now());
    setrefreshlistaction(true);
  };

  useEffect(() => {
    return () => {
      setisConsultationDetailModalOpen(false);
      setisReportModalOpen(false);
    };
  }, []);

  const reportmodalCloseHandler = () => {
    setisReportModalOpen(false);
  };

  const loadAction = useCallback(async (pageDetail) => {
    setrefreshlistaction(false);
    // Assign promises to variables so they run in parallel
    const skip = pageDetail.pageNumber
      ? (pageDetail.pageNumber - 1).toString()
      : "0";
    const nonStringSkip = pageDetail.pageNumber ? pageDetail.pageNumber : 1;
    const searchInput = pageDetail.searchInput
      ? pageDetail.searchInput.toString()
      : "";

    // const sortpropsname = pageDetail.sortprops && pageDetail.sortprops.sortparameters ? pageDetail.sortprops.sortparameters.headername : undefined;

    // const ordertype = pageDetail.sortprops && pageDetail.sortprops.sortparameters ? pageDetail.sortprops.sortparameters.order : undefined;

    const take = pageDetail.take ? pageDetail.take.toString() : "10";
    setpageSize(take);

    const sortpropsname =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.headername
        : undefined;
    const ordertype =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.order
        : undefined;
        const combinecountryvalue = (pageDetail.countries && !get(pageDetail, "countries").includes("All"))
          ? pageDetail.countries
          : "";
        const combineregionvalue = (pageDetail.regions  && !get(pageDetail, "regions").includes("All")) ? pageDetail.regions : "";

    if (pageDetail.filter && Object.keys(pageDetail.filter).length > 0) {
      setSortPropsName(sortpropsname);      
      setSortordertype(ordertype === "asc" ? "asc" : "desc");
      let sortpropsnamenew =
      sortpropsname === t("Patient")
        ? "patient"
        : sortpropsname === t("Cardiologist")
        ? "cardiologist"
        : sortpropsname === t("Type")
        ? "type"
        : sortpropsname === t("Date")
        ? "date"
        : sortpropsname === t("Time")
        ? "time"
        : sortpropsname;
        let sortordertypenew =
        ordertype === "desc"
          ? "descending"
          : ordertype === "asc"
          ? "ascending"
          : undefined;
      const localfilterstatus = (get(pageDetail.filter, "status") && !get(pageDetail.filter, "status").includes("100")) ? get(pageDetail.filter, "status") : "";
      let offsetInMinutes = offset;
      const startDateformatted = get(pageDetail.filter, "startDate") || "";
      const endDateformatted = get(pageDetail.filter, "endDate") || "";
      // const memberIddata = get(pageDetail.filter, "memberId") && get(pageDetail.filter, "memberId") !== "All"? get(pageDetail.filter, "memberId") :  "";
      const memberIddata = get(pageDetail.filter, "memberIdarray") && !get(pageDetail.filter, "memberIdarray").includes("all") ? get(pageDetail.filter, "memberIdarray") :  "";
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl:
            get(pageDetail.filter, "memberIdarray") &&
            // get(pageDetail.filter, "memberIdarray") !== "All"
            !get(pageDetail.filter, "memberIdarray").includes("all")
              ? `api/v2/teams/${teamId}/admin/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${localfilterstatus}&searchString=${searchInput}&startDateTime=${startDateformatted}&endDateTime=${endDateformatted}&filterMemberIds=${memberIddata}&sortOrder=${sortordertypenew}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`
              : `api/v2/teams/${teamId}/admin/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${localfilterstatus}&searchString=${searchInput}&startDateTime=${startDateformatted}&endDateTime=${endDateformatted}&sortOrder=${sortordertypenew}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
        })
      );

      const appointments = await appointmentRequest;
      setmemberId(appointments.data.result.memberId);
      setrefreshaction(Date.now());
      const data = appointments.data.result.appointments
        .map((item) => {
          return {
            ...item,
            Patient: `${
              item.patient.firstName === null ? "-" : item.patient.firstName
            } ${item.patient.lastName === null ? "-" : item.patient.lastName}`,
            date: `${moment(item.startdatetime).format("MM/DD/YYYY")} `,
            Cardiologist:  `Dr. ${
              item.physician.firstName === null ? "-" : item.physician.firstName
            } ${item.physician.lastName === null ? "-" : item.physician.lastName}`,
            Type: item.appointmentType,
            Status: item.appointmentStatusDisplayName,
            Method:item.appointmentChannelType,
            disabledAction:
              item.isOverReadReportCompleted ||
              item.appointmentStatus === "Completed",
            Time: `${moment(item.startdatetime).format(
              "hh:mm A"
            )}-${moment(item.enddatetime).format("hh:mm A")}`,
          };
        });
        return {
          data,
          meta: {
            count: appointments.data.result.totalElements,
            currentPage: nonStringSkip,
          },
        };
     
    } else {
      let offsetInMinutes = offset;
      let status = "";
      setSortPropsName(sortpropsname);
      setSortordertype(ordertype === "asc" ? "asc" : "desc");

      let sortpropsnamenew =
      sortpropsname === t("Patient")
        ? "patient"
        : sortpropsname === t("Cardiologist") 
        ? "cardiologist"
        : sortpropsname === t("Type") 
        ? "type"
        : sortpropsname === t("Date") 
        ? "date"
        : sortpropsname === t("Time") 
        ? "time"
        : sortpropsname;
        let sortordertypenew =
          ordertype === "desc"
            ? "descending"
            : ordertype === "asc"
            ? "ascending"
            : undefined;
      // const appointmentRequest = streamToPromise(
      //   apiservice.getapiservice({
      //     baseUrl: `api/v2/clinicians/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${status}`,
      //   })
      // );
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v2/teams/${teamId}/admin/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${status}&searchString=${searchInput}&sortOrder=${sortordertypenew}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
        })
      );

      const appointments = await appointmentRequest;
      setmemberId(appointments.data.result.memberId);
      setrefreshaction(Date.now());
      const data = appointments.data.result.appointments
        .map((item, index) => {
          return {
            ...item,
            Patient: `${
              item.patient.firstName === null ? "-" : item.patient.firstName
            } ${item.patient.lastName === null ? "-" : item.patient.lastName}`,
            date: `${moment(item.startdatetime).format("MM/DD/YYYY")}`,
            Cardiologist:   `Dr. ${
              item.physician.firstName === null ? "-" : item.physician.firstName
            } ${item.physician.lastName === null ? "-" : item.physician.lastName}`,
            Type: item.appointmentType,
            Status: item.appointmentStatusDisplayName,
            Method:item.appointmentChannelType,
            disabledAction:
              item.isOverReadReportCompleted === true ||
              item.appointmentStatus === "Completed",
            Time: `${moment(item.startdatetime).format(
              "hh:mm A"
            )}-${moment(item.enddatetime).format("hh:mm A")}`,
          };
        });
        return {
          data,
          meta: {
            count: appointments.data.result.totalElements,
            currentPage: nonStringSkip,
          },
        };     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewAdminConsultationDetailsAction = useCallback(
    ({ entity, history: hstry }) => {
      setappointidofmodal(entity.appointmentId);
      setpatientinfo(entity.patient);
      setactiveClinicianId(entity.physician.memberId);
      setisConsultationDetailModalOpen(true);
    },
    []
  );
  const adminEditAction = useCallback(({ entity, history: hstry }) => {
    if (
      entity.appointmentStatusDisplayName === "Completed" ||
      entity.isOverReadReportCompleted
    ) {
      setappointidofmodal(entity.appointmentId);
      setpatientinfo(entity.patient);
      setactiveClinicianId(entity.physician.memberId);
      // setappointmentinfofull(entity);
      setisReportModalOpen(true);
    } else {

      setappointidofmodal(entity.appointmentId);
      setpatientinfo(entity.patient);
      //setappointmentinfofull(entity);
      //setisAppointmentDetailModalOpen(true);
    }
  }, []);

  return (
    <div className="row no-gutters all_appointments">
      <div className="col-12">
        <Container maxWidth="xl">
          <Box mt={2} />
          <Grid alignItems="center" container justify="space-between">
            <Grid item>
              <MuiTypography
                fontSize="34px"
                fontFamily="Work Sans"
                lineHeight="40px"
                letterSpacing="0.25px"
                fontWeight={500}
                color="#000000"
              >
                {t("Consultations")} 
              </MuiTypography>

              <BottomLineTitle />
            </Grid>
            <Grid item>
              <Grid alignItems="center" container justify="space-between">
                <Grid item>
                  <MuiTypography
                    fontSize="12px"
                    fontFamily="Work Sans"
                    lineHeight="20px"
                    letterSpacing="-0.2px"
                    textTransform="uppercase"
                    color="#536063"
                  >
                    {t("LAST REFRESHED")}{" "}
                    {moment(refreshaction).format("hh:mm A")}
                  </MuiTypography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => onRefreshClick()}>
                    <CachedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={2} />

          {appointidofmodal !== "" && (
            <ConsultationsDetailModal
              key="report-modal"
              appointmentId={appointidofmodal}
              patient={patientinfo}
              memberId={activeClinicianId}
              clinicianId={clinicianId}
              teamId={teamId}
              onRefresh = {onRefreshClick}
              show={isConsultationDetailModalOpen}
              offset={offset}
              handleClose={() => consultationDetailModalOpenCloseHandler()}
            />
          )}
          {isReportModalOpen && appointidofmodal !== "" && (
            <ReportModal
              key="report-modal"
              appointmentId={appointidofmodal}
              patient={patientinfo}
              memberId={activeClinicianId}
              show={isReportModalOpen}
              handleClose={reportmodalCloseHandler}
              // onSubmit={(e) => this.submitHandler(e)} title={"Update Availability"}
            ></ReportModal>
          )}

          <ListPageAdminConsultations
            feature="Appointments"
            entityProps={[
              "date",
              "Time",
              "Cardiologist",
              "Patient",
              "Type",
              "Method",
              "Status",
              "disabledAction",
            ]}
            refreshaction={refreshlistaction}
            loadAction={loadAction}
            loading="false"
            history={history}
            sortPropsName={sortPropsName}
            sortordertype={sortordertype}
            headers={[
              t("Date"),
              t("Time"),
              t("Cardiologist"),
              t("Patient"),
              t("Type"),
              t("Method"),
              t("Status"),
            ]}
            searchEnabled={true}
            viewAdminConsultationDetailsAction={
              viewAdminConsultationDetailsAction
            }
            adminEditAction={adminEditAction}
            toggleField={"isActive"}
            filters={[
              {
                name: "test",
                options: [
                  { value: "", label: "Select a filter" },
                  { value: "123", label: "Example filter" },
                ],
              },
            ]}
          />
        </Container>
      </div>
    </div>
  );
}

export default AdminConsultations;
