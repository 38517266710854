/* eslint-disable  */
import React from "react";
import { getAge } from "../../Utilities/Utilities";
import _get from "lodash.get";
import moment from "moment";
import { useTranslation } from "react-i18next";

const PatientDetails = (props) => {
  const { t } = useTranslation();
  const updatedpatientDetails = props.patientDetails;
  // const Age =
  //   getAge(new Date(_get(props.patientDetails, "patient.dob"))) || "-";

  return (
    <>
      <div className="row no-gutters mb-3">
        <div className="col-12 height460 bg_white p-3">
          <div className="row mb-3">
            <div className="col-12">
              <h5 className="head">
                {_get(updatedpatientDetails, "patient.firstName") || "-"}
                  {" "}
                  {_get(updatedpatientDetails, "patient.lastName") || "-"}
              </h5>
            </div>
          </div>
          <div className="row" style={{ display: "-ms-flexbox" }}>
            <div className="col-5">
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t("Age")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                  {getAge(_get(updatedpatientDetails, "patient.dob"))?getAge(_get(updatedpatientDetails, "patient.dob")) +" years" : "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t("Gender")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, "patient.gender") || "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t("Location")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, "patient.location") || "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t("Weight")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                  {_get(updatedpatientDetails, "patient.weight")?_get(updatedpatientDetails, "patient.weight")<= 0 ?"0": (_get(updatedpatientDetails, "patient.weight") + " lb") : "-"}
                    
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t("Height")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {updatedpatientDetails &&
                    _get(updatedpatientDetails, "patient.height")
                      ? Math.floor(
                          _get(updatedpatientDetails, "patient.height") / 30.48
                        ) +
                        "'" +
                        " " +
                        (Math.floor(
                          _get(updatedpatientDetails, "patient.height") / 2.54
                        ) -
                          12 *
                            Math.floor(
                              _get(updatedpatientDetails, "patient.height") /
                                30.48
                            )) +
                        '"'
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t("BMI")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                  {updatedpatientDetails && _get(updatedpatientDetails, "patient.bmi") || "-"}
                  </span>
                </div>
              </div>
            
            </div>
            <div className="col-7">
            <div className="row m-0">
                <label className="col-form-label subhead p-0">{t("Email")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(updatedpatientDetails, "patient.email") || "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">
                  {t("Consultation")}{":"}
                </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                  { (_get(
                        updatedpatientDetails,
                        "patientAppointment.scheduledStartTime"
                      ) && _get(
                        updatedpatientDetails,
                        "patientAppointment.scheduledEndTime"
                      )) ?(moment(
                      _get(
                        updatedpatientDetails,
                        "patientAppointment.scheduledStartTime"
                      ) || "-"
                    ).format("hh:mm A") +"-"+ moment(
                      _get(
                        updatedpatientDetails,
                        "patientAppointment.scheduledEndTime"
                      ) || "-"
                    ).format("hh:mm A") +" "+"(20 Minutes)") : "-"
                    } 
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">
                  Type of Consultation{":"}
                </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(
                      updatedpatientDetails,
                      "patientAppointment.appointmentType"
                    ) || "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">
                {t("Method of Consultation")}{":"}
                </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(
                      updatedpatientDetails,
                      "patientAppointment.appointmentChannelType"
                    ) || "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0">{t("Contact")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    <strong>
                      {_get(updatedpatientDetails, "patient.phone") || "-"}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 mt-3">
            <div className="col-12 p-0">
              <label className="col-form-label p-0">
                <strong>{t("Reason for Consultation")}: </strong>
                {_get(
                  updatedpatientDetails,
                  "patientAppointment.visitReason"
                ) || "-"}
              </label>
            </div>
          </div>
          <div className="row m-0 mt-3">
            <div className="col-12 p-0">
              <label className="col-form-label p-0">
                <strong>{t("Symptoms")}: </strong>
              </label>
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th scope="col">{t("Symptom")}</th>
                    <th scope="col">{t("Severity")} </th>
                    <th scope="col">{t("Duration")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(updatedpatientDetails).length > 0 &&
                  updatedpatientDetails.symptoms &&
                  updatedpatientDetails.symptoms.length > 0 ? (
                    updatedpatientDetails.symptoms.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.severity}</td>
                        <td>{item.duration}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDetails;
