/*eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { useAsync, IfPending, IfFulfilled, IfRejected } from "react-async";
import Container from "../Container";
import { Flex } from "../Flex";
import FlexItem from "../FlexItem";
import EcgLoader from "../EcgLoader";
import Spacing from "../Spacing";
import MaterialPagination from "../MaterialPagination";
import { move } from "./../../../Utilities/Utilities";
import get from "lodash.get";
import { Note } from "./styled";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import {
  Paper,
  Button,
  InputBase,
  Popover,
  IconButton,
  InputLabel,
  OutlinedInput,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Divider,
  FormControlLabel,
  Checkbox,
  Chip,
  Input,
  InputAdornment,
  Box,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ListTable from "./ListTable";
import { streamToPromise } from "./../../../Utilities/Utilities";
import apiservice from "./../../../api/services/apiservice.service";
import Datepicker from "../../Appointments/Calendar/material_datepicker_metrix";
import MuiTypography from "../Typography/MuiTypography";
import moment from "moment";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import TitleAlert from "../Alert/TitleAlert";
import Snackbar from '@material-ui/core/Snackbar';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";

type Props = {
  feature: String,
  /** The list of entity props to display in the list  */
  entityProps: Array<string>,
  /**
   * Function to load list of entities for the page
   *
   * @param {Object} filter An object with each property corresponding to a filter name, and the value the select one
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  loadAction: Promise<Array>,
  /** In container, wrap with withRouter to get history and privileges */
  history?: {
    push: Function, // eslint-disable-line
  },
  /** The table headers, component will looks up in translation files references*/
  headers?: Array<string>,
  /**
   * Function to add an entity
   *
   * @param {Object} history Router history object
   */
  addAction?: Function,
  /**
   * Function to edit an entity
   *
   * @param {Object} entity Entity to edit
   * @param {Object} history Router history object
   */
  viewAdminConsultationDetailsAction?: Function,
  /**
   * Function to drilldown on an entity
   *
   * @param {Object} entity Entity to edit
   * @param {Object} history Router history object
   */
  drillDownAction?: Function,
  /**
   * Function to delete an entity and refresh page
   *
   * @param {Object} entity Entity to remove
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  deleteAction?: Promise<Array>,
  /**
   * Function to save inline edited entity and refresh page
   *
   * @param {Object} entity Modified entity to save
   * @param {string} searchInput String to search, empty to return akl results
   * @param {number} pageNumber When paging, this is the page number
   */
  inlineEditAction?: Promise<Array>,
  /**
   * Function to delete an entity and refresh page
   *
   * @param {Array<Object>} sortedEntities entities with new sort order (sort sent is based on array order, provided function should compare to sort field and update if required)
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  sortAction?: Promise<Array>,
  /** Prop name containing the toggle field, must be a string */
  toggleField?: Boolean,
  /** Prop name containing the favorite field, must be a string */
  favoriteField?: Boolean,
  /** Page title */
  title?: string,
  sortordertype: String,
  sortPropsName: String,
  /** Boolean to set whether we support search */
  searchEnabled?: Boolean,
  /** Filters using select, each filter object should have a "name" property (string) and an "options" property (Array). Each option object should have a "value" and a "label" property */
  filters?: Array<Object>,
  /** Custom renderer for field, property should be the same as the name of the field, and value
   * should be a function. The special "_edit" prop is for deciding whether to display edit button or not  */
  render?: { [string]: Function },
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      "label + &": {
        marginTop: theme.spacing(3),
        fontSize: 16,
        color: "#000 !important",
        width: "100%",
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      borderBottom: "1px solid #d8d8d8",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // "&::placeholder": {
      //  color: "#000 !important"
      // },
      // Use the system font instead of the default Roboto font.
      fontFamily: ["inherit"].join(","),
    },
  })
)(InputBase);

const GreenCheckbox = withStyles({
  root: {
    color: "#000000",
    "&$checked": {
      color: "#2D9F86",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    // "& $notchedOutline": {
    //   borderColor: "red"
    // },
    color: "#000000",
    backgroundColor: "#ffffff",
    "&:hover $notchedOutline": {
      color: "#2D9F86",
      borderColor: "#2D9F86",
    },
    "&$focused $notchedOutline": {
      color: "#000000",
      borderColor: "#2D9F86",
    },
  },
  input: {
    background: "#ffffff",
    "&:hover": {
      color: "#2D9F86",
    },
    "&$focused": {
      color: "#2D9F86",
    },
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
  focused: {},
  notchedOutline: {},
}));

const divStyle = {
  color: "rgba(20, 42, 57, 0.72)",
};

const buttonstyle = {
  marginBottom: "10px",
  color: "#F25F5F",
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: 0,
  },
  listroot: {
    // maxHeight: "calc(50vh)",
    width: "100%",
    overflow: "auto",
  },

  searchbarroot: {
    marginTop: "14px",
    padding: "2px 4px",
    display:
      "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
    alignItems: "center",
    // background: "rgba(118,118,128,0.12)",
    borderRadius: "10px",
    boxShadow: "none",
    height: "36px",
    width: "300px !important",
  },
  searchbarrootsmall: {
    marginTop: "14px",
    padding: "2px 4px",
    display:
      "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
    alignItems: "center",
    borderRadius: "10px",
    boxShadow: "none",
    height: "36px",
    width: "170px !important",
  },
  paperoot: {
    // display: 'flex',
    // flexWrap: 'wrap',
    "& > *": {
      // margin: theme.spacing(1),
      width: theme.spacing(100),
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  container: {
    display:
      "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formControllabelstyle: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontFamily: "Work Sans !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(20, 42, 57, 0.72)",
  },
  formControllabelstyle2: {
    marginLeft: "5px",
    marginRight: "5px",
    fontFamily: "Work Sans !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(20, 42, 57, 0.72)",
  },
  formControl: {
    background: "#FFFFFF",
    // margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 280,
  },
  formControlsmall: {
    background: "#FFFFFF",
    minWidth: 120,
    maxWidth: 300,
    // margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControlchip: {
    background: "#FFFFFF",
    // margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 280,
    maxWidth: 300,
  },
  formControlchipsmall: {
    background: "#FFFFFF",
    // margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  formControlchip2: {
    background: "#FFFFFF",
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "5px",
    // minHeight: 280,
    minWidth: 280,
    maxWidth: 300
  },
  formControlchipsmall2: {
    background: "#FFFFFF",
    // margin: theme.spacing(1),
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "5px",
    minWidth: 280,
    minHeight: 280,
    maxWidth: 300,
  },
  popovergrid: {
    padding: theme.spacing(3),
    background: "white",
  },
  popovergridmain2: {
    maxWidth: 1000
  },
  popovergrid2: {
    padding: "5px",
    minHeight: 280,
    background: "white",
    width: 650,
  },
  smallButton: {
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    color: "black",
    padding: "10px",
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: "8px",
    marginTop: "14px"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 1,
  },
  paddingTopmainmenu: {
    paddingTop: theme.spacing(1),
    paddingLeft:"15px",
    background: "white",
  },
  topbar: {
    background: "#FFFFFF",
    height: "66px",
    padding: "4px 4px",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
  largeinput: {
    color: "#142A39 !important",
    minWidth: "512px",
    fontFamily: "Work Sans !important",
    "& input::placeholder": {
      fontFamily: "Work Sans !important",
      fontWeight: 400,
      color: "#142A39 !important",
      opacity: 1,
      textAlign: "left",
    },
    "&:focus": {
      borderColor: "#2D9F86",
    },
  },
  largeinputtablet: {
    color: "#142A39 !important",
    minWidth: "212px",
    fontFamily: "Work Sans !important",
    "& input::placeholder": {
      fontFamily: "Work Sans !important",
      fontSize: "9px",
      fontWeight: 400,
      color: "#142A39 !important",
      opacity: 1,
      textAlign: "left",
    },
    "&:focus": {
      borderColor: "#2D9F86",
    },
  },
  smallinput: {
    color: "#142A39 !important",
    fontFamily: 'Work Sans !important',
    "& input::placeholder": {
      fontSize: "9px",
      fontFamily: 'Work Sans !important',
      lineHeight: '9px',
      letterSpacing: '-0.2px',
      fontWeight: 400,
      color: "#142A39 !important",
      opacity: 1,
      textAlign: 'left'
    }
    // padding: "10px 26px 10px 12px"
  },
  menuitemroot: {
    maxWidth: 280,
    wordWrap: "break-word"
  },
  autocompleteinput: {
    color: "#000000",
    fontFamily: 'Work Sans !important',
    backgroundColor: "#FFFFFF",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "blue"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "blue"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "blue"
    },
    "&.Mui-focused": {
      backgroundColor: "#FFFFFF",
      color: "#000000",
    }
  },
  popoverRoot: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 1000
  },
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%"
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0"
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%"
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0"
      }
    }
  },
  arrow: {
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: theme.palette.background.paper,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      boxShadow: theme.shadows[1],
      backgroundColor: "currentColor",
      transform: "rotate(45deg)"
    }
  },
  smallpaddediconButton: {
    padding: "5px",
  },
  chipfont:{  
    fontFamily: "Work Sans !important",
    color: "#000000 !important",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    fontWeight: 400
  },
}));


const muitheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2D9F86"
    }
  },
  typography: {
    fontFamily: 'Work Sans',
  },
});


const CssTextField = withStyles({
  root: {
    "& input::placeholder": {
      fontFamily: "Work Sans !important",
      opacity: 1,
      color: "#142A39 !important",
    },
    "& label.Mui-focused": {
      color: "#2D9F86",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2D9F86",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#142A39",
      },
      "&:hover fieldset": {
        borderColor: "#2D9F86",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2D9F86",
      },
    },
  },
})(TextField);


const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&$hover': {
      backgroundColor: '#FFFFFF',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#2D9F86',
      },
    },
    '&:focus': {
      backgroundColor: '#FFFFFF',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#2D9F86',
      },
    },
    "&$selected": {
      backgroundColor: '#FFFFFF',
      color: '#2D9F86',
    },
    "&$selected:hover": {
      backgroundColor: "#FFFFFF",
      color: '#2D9F86',
    },
    '& .MuiButtonBase-root': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px'
    },
    '& .MuiButtonBase-root:hover': {
      backgroundColor: '#FFFFFF'
    },
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: '#FFFFFF',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#2D9F86'
      }
    },
    "&.Mui-focusVisible": {
      color: "#FFFFFF",
      backgroundColor: '#FFFFFF',
    },
  },
  selected: {}
}))(MenuItem);

const userError = (error, lastAction) => {
  switch (lastAction) {
    case "DELETE":
      return "Error DeletingData";
    case "ENABLE":
      return "Error SavingData";
    case "FAVORITE":
      return "Error SavingData";
    case "SORT":
      return "Error Sorting Data";
    case "SEARCH":
      return "Error Loading Data";
    case "FILTER":
      return "Error Loading Data";
    default:
      return "Error Loading Data";
  }
};

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

const ListPageAdminConsultations = (props: Props) => {
  const { t } = useTranslation();
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const mediawidth = useWidth();
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const {
    history,
    headers,
    entityProps,
    feature,
    loadAction,
    addAction,
    deleteAction,
    viewAdminConsultationDetailsAction,
    drillDownAction,
    uploadappointmentsAction,
    adminEditAction,
    inlineEditAction,
    sortAction,
    toggleField,
    favoriteField,
    title,
    render,
    searchEnabled,
    watch,
    sortordertype,
    sortPropsName,
  } = props;

  const [searchInput, setSearchInput] = useState("");
  const [lastAction, setLastAction] = useState("LOAD");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setpageNumber] = useState(0);
  const [sortprops, setsortprops] = useState({});
  // const [clientids] = useState([
  //   { id: "123", name: "PT System" },
  //   { id: "1", name: "ALIVECORE" }
  // ]);
  const [filter, setFilter] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [statusvaluedata, setstatusvaluedata] = useState("100,1,2,3,4,5,6,7,8,10,11");

  const inputLabel1 = React.useRef(null);
  const inputLabel2 = React.useRef(null);
  const inputLabel3 = React.useRef(null);

  const [label1Width, setLabel1Width] = React.useState(0);
  const [label2Width, setLabel2Width] = React.useState(0);
  const [label3Width, setLabel3Width] = React.useState(0);
  // useEffect(() => {
  //   setLabel1Width(inputLabel1.current.offsetWidth);
  //   setLabel2Width(inputLabel2.current.offsetWidth);
  //   setLabel3Width(inputLabel3.current.offsetWidth);
  // }, []);

  useEffect(() => {
    if (props.refreshaction === true) {
      onSearchClick();
    }
  }, [props.refreshaction]);

  const [status, setStatus] = React.useState(["1,2,3,4,5,6,7,8,10,11"]);
  const [statusList, setstatusList] = React.useState([]);
  const [cardiologistvalue, setcardiologistvalue] = useState("All");
  const [cardiologistList, setcardiologistList] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendtDate] = useState(new Date());
  // const dt = new Date();
  // let initialstartdateformatted = moment(startOfDay(dt)).format(
  //   "YYYY-MM-DDT00:00:00.000"
  // );
  // let initialenddateformatted = moment(endOfDay(dt)).format(
  //   "YYYY-MM-DDT23:59:00.000"
  // );
  // const [startdateformatted, setstartDateformatted] = useState(
  //   initialstartdateformatted
  // );
  // const [enddateformatted, setenddateformatted] = useState(
  //   initialenddateformatted
  // );

  const [startdateformatted, setstartDateformatted] = useState(
    ""
  );
  const [enddateformatted, setenddateformatted] = useState(
    ""
  );

  const [dateselectionmenuitemtext, setdateselectionmenuitemtext] = useState(
    "Select date range"
  );

  // const [statusselectionmenuitemtext, setstatusselectionmenuitemtext] = useState(
  //   "All"
  // );


  const [isSelectorOpen, setisSelectorOpen] = useState(false);

  const [invaliddatealert, setinvaliddatealert] = useState(false);
  const [invaliddatealertmessage, setinvaliddatealertmessage] = useState("Invalid Date Time");
  const [snackbaropen, setsnackbaropen] = React.useState(false);

  const [regionselectionmenuitemtext, setregionselectionmenuitemtext] = useState(
    "All"
  );

  const [arrowRef, setArrowRef] = React.useState(null);


  const [combinedarray, setcombinedarray] = useState([]);

  const [regionarrayoptions, setregionarrayoptions] = useState([]);
  const [countryarrayoptions, setcountryarrayoptions] = useState([]);

  const [regionarray, setregionarray] = useState([]);
  const [countryarray, setcountryarray] = useState([]);


  const countryfilteredarray = combinedarray && combinedarray.length > 0 ? combinedarray.filter(x => "regions" in x) : [];
  const combinecountryvalue = countryfilteredarray && countryfilteredarray.length > 0 ? countryfilteredarray.map(x => x.name).join() : "";


  const regionfilteredarray = combinedarray && combinedarray.length > 0 ? combinedarray.filter(x => "abbreviation" in x) : [];
  const combineregionvalue = regionfilteredarray && regionfilteredarray.length > 0 ? regionfilteredarray.map(x => x.name).join() : "";


  const removetag = (chipToDelete) => {
    let newcombinedarray = combinedarray.filter((item) =>
      "regionId" in chipToDelete
        ? item.regionId !== chipToDelete.regionId
        : "countryId" in chipToDelete && !("regionId" in chipToDelete)
          ? item.countryId !== chipToDelete.countryId
          : "displayName" in chipToDelete
            ? item.displayName !== chipToDelete.displayName
            : item.email !== chipToDelete.email
    );
    let newcountryarray = newcombinedarray.filter(x => ("regions" in x));
    setcountryarray([...newcountryarray]);
    const combinecountrytagvalue = newcountryarray && newcountryarray.length > 0 ? newcountryarray.map(x => x.name).join() : "";

    let newregionarray = newcombinedarray.filter(x => ("regionId" in x));
    setregionarray([...newregionarray]);
    const combineregiontagvalue = newregionarray && newregionarray.length > 0 ? newregionarray.map(x => x.name).join() : "";

    let newstatusarray = newcombinedarray.filter(x => ("displayName" in x));
    setstatusarray(newstatusarray);
    let newkeyarray = newstatusarray.map(x => x.id);
    let newkeyarrayall = statusList.map(x => x.id);
    let findDeep = function (data, activity) {
      return data.some(function (e) {
        if (e.displayName == activity) return true;
      })
    }
    let strigifystatusnewvalue = findDeep(newstatusarray, 'All') ? newkeyarrayall.join() : newkeyarray.join();

    let newclinicianarray = newcombinedarray.filter(x => ("email" in x));
    setclinicianarray(newclinicianarray);
    let newcliniciankeyarray = newclinicianarray.map(x => x.memberId);

    let strigifycliniciannewvalue = newcliniciankeyarray.join();

    setcombinedarray(newcombinedarray);
    if (statusvaluedata === "") {
      setsnackbaropen(true);
    }
    else {
      setLastAction("FILTER");
      setFilter({
        ...filter,
        startDate: startdateformatted,
        endDate: enddateformatted,
        status: strigifystatusnewvalue,
        searchInput: searchInput,
        sortprops: sortprops,
        take: pageSize,
        countries: combinecountrytagvalue,
        regions: combineregiontagvalue,
        memberIdarray: strigifycliniciannewvalue
      });
      return run("FILTER", {
        filter: {
          ...filter,
          startDate: startdateformatted,
          endDate: enddateformatted,
          status: strigifystatusnewvalue,
          searchInput: searchInput,
          sortprops: sortprops,
          take: pageSize,
          countries: combinecountrytagvalue,
          regions: combineregiontagvalue,
          memberIdarray: strigifycliniciannewvalue
        },
        searchInput: searchInput,
        sortprops: sortprops,
        take: pageSize,
        countries: combinecountrytagvalue,
        regions: combineregiontagvalue,
        memberIdarray: strigifycliniciannewvalue
      });
    }
  };
  const [statusarray, setstatusarray] = useState([...statusList]);

  const [clinicianarray, setclinicianarray] = useState([...cardiologistList]);

  useEffect(() => {
    if (statusList.length > 0) {
      setstatusarray([statusList[0]]);
    }
  }, [statusList]);

  useEffect(() => {
    if (cardiologistList.length > 0) {
      setclinicianarray([cardiologistList[0]]);
      let clinsarray = [cardiologistList[0]];
      setcombinedarray([...new Set([...combinedarray, ...clinsarray, ...statusarray])])
    }
  }, [cardiologistList]);
  

  const loadLookupActionCountryRegion = useCallback(async () => {
    const countrylookuprequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/country`,
      })
    );
    const countrylookup = await countrylookuprequest;

    let allcountrydata = {
      name: "All",
      countryId: "all-country-id",
      regions: [
        {
          name: "All",
          abbreviation: "All",
          regionId: "all-region-id",
          countryId: "all-country-id",
        },
      ],
    };

    let addedcountrylookup = [allcountrydata, ...countrylookup.data.result];


    let countryarrayoptionsdata = addedcountrylookup.map((item) => {
      return {
        ...item,
        title: item.name,
        code: item.countryId,
      };
    });
    setcountryarrayoptions(
      countryarrayoptionsdata
    );
    // countryarrayoptionsdata[0]
    setcountryarray([countryarrayoptionsdata[0]]);

    let regionarrayoptionsdata = addedcountrylookup[0].regions.map((item) => {
      return {
        ...item,
        title: item.name,
        code: item.regionId,
      };
    });

    setregionarrayoptions(
      regionarrayoptionsdata
    );
    // regionarrayoptionsdata[0]
    setregionarray([regionarrayoptionsdata[0]]);
    let regcontryarray = [countryarrayoptionsdata[0], regionarrayoptionsdata[0]];
    setcombinedarray([...new Set([...combinedarray, ...regcontryarray])]);
  }, []);


  useEffect(() => {
    loadLookupActionCountryRegion();
  }, []);



 



  const changeregionarray = (newregionarray, details) => {
    const noncountryregionarray = combinedarray.filter(x => !("countryId" in x));
    if (
      details.option.name === "All" &&
      regionarray.some((el) => el.name === "All")
    ) {
      setregionarray([]);

      setcombinedarray([
        ...new Set([...countryarray, ...noncountryregionarray]),
      ]);
    } else if (
      details.option.name === "All" &&
      !regionarray.some((el) => el.name === "All")
    ) {
      setregionarray([...regionarrayoptions]);
      setcombinedarray([
        ...new Set([
          ...countryarray,
          ...regionarrayoptions,
          ...noncountryregionarray,
        ]),
      ]);
    } 
    else if (
      regionarray.some((el) => el.name === details.option.name)
    ) {
      let filteralnonallregionarray = newregionarray.filter(
        (x) => x.name !== "All"
      );
      setregionarray([...filteralnonallregionarray]);
      setcombinedarray([
        ...new Set([
          ...countryarray,
          ...filteralnonallregionarray,
          ...noncountryregionarray,
        ]),
      ]);
    }
    else {
      setregionarray([...newregionarray]);
      setcombinedarray([
        ...new Set([
          ...countryarray,
          ...newregionarray,
          ...noncountryregionarray,
        ]),
      ]);
    }
  }

  const changecountryarray = (newcountryarray, details) => {
    var newcountryarraydata = [];
    const noncountryregionarray = combinedarray.filter(x => !("countryId" in x));
    if (
      details.option.name === "All" &&
      countryarray.some((el) => el.name === "All")
    ) {
     newcountryarraydata = [];
    }
    else if (
      details.option.name === "All" &&
      !countryarray.some((el) => el.name === "All")
    ) {
      newcountryarraydata = [...countryarrayoptions];
    }
    else if (
      countryarray.some((el) => el.name === details.option.name)
    ) {
      let filteralnonallcountryarray = newcountryarray.filter(
        (x) => x.name !== "All"
      );
      newcountryarraydata =  [...filteralnonallcountryarray];
    }

    else {
      newcountryarraydata =  [...newcountryarray]; 
    }

    setcountryarray([
      ...newcountryarraydata
    ]);
    let regionarrayoptionsdatatemp = newcountryarraydata.map((item) => {
      let combinedregionArray = [];
      combinedregionArray.push(...item.regions);
      return combinedregionArray;
    });
    let regionarrayoptionsdataflatten = Array.prototype.concat.apply([], regionarrayoptionsdatatemp);
    let regionarrayoptionsdatatemp2 = regionarrayoptionsdataflatten.map((item) => {
      return {
        ...item,
        title: item.name,
        code: item.regionId,
      }
    });

    setregionarrayoptions(
      regionarrayoptionsdatatemp2
    );

    let regionfilteredarraysellected = regionarrayoptionsdatatemp2.filter(x => {
      if (regionarray.filter(y => y.name === x.name).length > 0)
        return x;
    });

    setregionarray(regionfilteredarraysellected);
    setcombinedarray([...new Set([...newcountryarray, ...regionfilteredarraysellected, ...noncountryregionarray])]);
  }

  const changestatusarray = (newstatusarray, details) => {
    const findDeep = function (data, activity) {
      return data.some(function (e) {
        if (e.displayName == activity) return true;
        // else if(e.items) return findDeep(e.items, activity)
      });
    };

    if (
      details.option.displayName === "All" &&
      statusarray.some((el) => el.displayName === "All")
    ) {
      setstatusarray([]);
      let newkeyarray = newstatusarray.map((x) => x.id);
      let newkeyarrayall = statusList.map((x) => x.id);
      let strigifynewvalue = findDeep(newstatusarray, "All")
        ? newkeyarrayall.join()
        : newkeyarray.join();
      _statusChangeActionmultiselect(strigifynewvalue);
      let nonstatusarray = combinedarray.filter((x) => !("displayName" in x));
      setcombinedarray([...new Set([...nonstatusarray])]);
    } else if (
      details.option.displayName === "All" &&
      !statusarray.some((el) => el.displayName === "All")
    ) {
      setstatusarray([...statusList]);

      let newkeyarray = newstatusarray.map((x) => x.id);
      let newkeyarrayall = statusList.map((x) => x.id);

      let strigifynewvalue = findDeep(newstatusarray, "All")
        ? newkeyarrayall.join()
        : newkeyarray.join();
      _statusChangeActionmultiselect(strigifynewvalue);
      let nonstatusarray = combinedarray.filter((x) => !("displayName" in x));
      setcombinedarray([...new Set([...statusList, ...nonstatusarray])]);
    } else if (
      statusarray.some((el) => el.displayName === details.option.displayName)
    ) {
      let filterallstatusarray = newstatusarray.filter(
        (x) => x.displayName !== "All"
      );
      setstatusarray([...filterallstatusarray]);

      let newkeyarray = filterallstatusarray.map((x) => x.id);
      let newkeyarrayall = statusList.map((x) => x.id);

      let strigifynewvalue = findDeep(filterallstatusarray, "All")
        ? newkeyarrayall.join()
        : newkeyarray.join();
      _statusChangeActionmultiselect(strigifynewvalue);
      let nonstatusarray = combinedarray.filter((x) => !("displayName" in x));
      setcombinedarray([
        ...new Set([...filterallstatusarray, ...nonstatusarray]),
      ]);
    } else {
      setstatusarray([...newstatusarray]);

      let newkeyarray = newstatusarray.map((x) => x.id);
      let newkeyarrayall = statusList.map((x) => x.id);

      let strigifynewvalue = findDeep(newstatusarray, "All")
        ? newkeyarrayall.join()
        : newkeyarray.join();
      _statusChangeActionmultiselect(strigifynewvalue);
      let nonstatusarray = combinedarray.filter((x) => !("displayName" in x));
      setcombinedarray([...new Set([...newstatusarray, ...nonstatusarray])]);
    }
  };



  const changeclinciansarray = (newclinicianarray) => {
    setclinicianarray([
      ...newclinicianarray
    ]);
    let newkeyarray = newclinicianarray.map(x => x.memberId);

    let strigifynewvalue = newkeyarray.join();
    _clinicanChangeActionmultiselect(strigifynewvalue);
    let nonclinicianarray = combinedarray.filter(x => !("email" in x));
    setcombinedarray([...new Set([...newclinicianarray, ...nonclinicianarray])])
  }




  // final submit here
  const onsubmitfilter = useCallback(async () => {
    setAnchorE2(null);
    if (statusvaluedata === "") {
      setsnackbaropen(true);
    }
    else {
      setLastAction("FILTER");
      setFilter({
        ...filter,
        startDate: startdateformatted,
        endDate: enddateformatted,
        status: statusvaluedata,
        sortprops: sortprops,
        countries: combinecountryvalue,
        regions: combineregionvalue
      });
      return run("FILTER", {
        filter: {
          ...filter,
          startDate: startdateformatted,
          endDate: enddateformatted,
          status: statusvaluedata,
        },
        searchInput: searchInput,
        sortprops: sortprops,
        take: pageSize,
        countries: combinecountryvalue,
        regions: combineregionvalue
      });
    }
    console.log(filter, run, searchInput, startdateformatted, enddateformatted, statusvaluedata, sortprops)
  }, [
    filter,
    run,
    searchInput,
    startdateformatted,
    enddateformatted,
    statusvaluedata,
    sortprops,
    combinedarray
  ]);
  const handlesnackbarClose = () => {
    setsnackbaropen(false)
  }


  const _daterangeChangeAction = useCallback(async () => {
    setLastAction("FILTER");
    setFilter({
      ...filter,
      startDate: startdateformatted,
      endDate: enddateformatted,
    });
    return run("FILTER", {
      filter: {
        ...filter,
        startDate: startdateformatted,
        endDate: enddateformatted,
      },
      searchInput: searchInput,
      take: pageSize,
    });
  }, [filter, run, searchInput, startdateformatted, enddateformatted]);

  const _daterangeresetAction = useCallback(
    async (startdatefrmtd, enddatefrmtd) => {
      setLastAction("FILTER");
      setFilter({
        ...filter,
        startDate: startdatefrmtd,
        endDate: enddatefrmtd,
        status: statusvaluedata,
        sortprops: sortprops,
        countries: combinecountryvalue,
        regions: combineregionvalue
      });
      return run("FILTER", {
        filter: { ...filter, startDate: startdatefrmtd, endDate: enddatefrmtd },
        searchInput: searchInput,
        take: pageSize,
        searchInput: searchInput,
        sortprops: sortprops,
        take: pageSize,
        countries: combinecountryvalue,
        regions: combineregionvalue
      });
    },
    [filter, run, searchInput]
  );

  const handleStartDateChange = (date) => {
    const pattern = "YYYY-MM-DDT00:00:00.000";
    setstartDate(date);
    let tempdt = moment(startOfDay(date)).format(pattern);
    setstartDateformatted(tempdt);
  };

  const handleEndDateChange = (date) => {
    const eodpattern = "YYYY-MM-DDT23:59:00.000";
    setendtDate(date);
    let tempdt2 = moment(endOfDay(date)).format(eodpattern);
    setenddateformatted(tempdt2);
  };


  const handlepopover2openClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handlestoppropogationChange2 = (event) => {
    event.stopPropagation();
    // set your value
  };


  const handlepopoveropenClick = (event) => {
    setisSelectorOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlestoppropogationChange = (event) => {
    event.stopPropagation();
    // set your value
  };

  const handlepopoverokayclick = () => {
    const pattern = "YYYY-MM-DDT00:00:00.000";
    const eodpattern = "YYYY-MM-DDT23:59:00.000";
    if (startDate === null || endDate === null) {
      setinvaliddatealertmessage("Invalid Date Time");
      setinvaliddatealert(true);
    } else if (startDate > endDate) {
      setinvaliddatealertmessage("Start date should not be greater than End date");
      setinvaliddatealert(true);
    }
    else if (startDate !== null && endDate !== null) {
      setinvaliddatealert(false);
      let tempdt1 = moment(startDate).format(pattern);
      let tempdt2 = moment(endDate).format(eodpattern);
      setstartDateformatted(tempdt1);
      setenddateformatted(tempdt2);
      setdateselectionmenuitemtext(
        `${moment(startDate).format("MM-DD-YYYY")} - ${moment(endDate).format(
          "MM-DD-YYYY"
        )}`
      );
      setisSelectorOpen(false);
      setAnchorEl(null);
      if (statusvaluedata === "") {
        setsnackbaropen(true);
      }
      else {
        setLastAction("FILTER");
        setFilter({
          ...filter,
          startDate: tempdt1,
          endDate: tempdt2,
          status: statusvaluedata,
          sortprops: sortprops,
          countries: combinecountryvalue,
          regions: combineregionvalue
        });
        return run("FILTER", {
          filter: {
            ...filter,
            startDate: tempdt1,
            endDate: tempdt2,
            status: statusvaluedata,
          },
          searchInput: searchInput,
          sortprops: sortprops,
          take: pageSize,
          countries: combinecountryvalue,
          regions: combineregionvalue
        });
      }
    }
  };

  const handlepopovercancelclick = () => {
    setstartDate(new Date());
    setendtDate(new Date());
    setisSelectorOpen(false);
    setAnchorEl(null);
  };

  const handlepopoverresetclick = () => {
    // const pattern = "YYYY-MM-DDT00:00:00.000";
    // const eodpattern = "YYYY-MM-DDT23:59:00.000";
    // let tempdt1 = moment(startOfDay(new Date())).format(pattern);
    // let tempdt2 = moment(endOfDay(new Date())).format(eodpattern);
    setstartDate(new Date());
    setendtDate(new Date());
    // setstartDateformatted(tempdt1);
    // setenddateformatted(tempdt2);
    setstartDateformatted("");
    setenddateformatted("");
    setdateselectionmenuitemtext("Select date range");
    // _daterangeresetAction(tempdt1, tempdt2);
    setisSelectorOpen(false);
    setAnchorEl(null);
    _daterangeresetAction();
  };


  const handlepopoverresetclick2 = () => {
    setAnchorE2(null);
    setcombinedarray([]);
    setcountryarray([]);
    setregionarray([]);
    setstatusarray([]);
    setclinicianarray([]);
    setLastAction("FILTER");
    // strigifystatusnewvalue
    // strigifycliniciannewvalue
    setFilter({
      ...filter,
      startDate: startdateformatted,
      endDate: enddateformatted,
      status: "",
      searchInput: searchInput,
      sortprops: sortprops,
      take: pageSize,
      countries: "",
      regions: "",
      memberIdarray: undefined
    });
    return run("FILTER", {
      filter: {
        ...filter,
        startDate: startdateformatted,
        endDate: enddateformatted,
        status: "",
        searchInput: searchInput,
        sortprops: sortprops,
        take: pageSize,
        countries: "",
        regions: "",
        memberIdarray: ""
      },
      searchInput: searchInput,
      sortprops: sortprops,
      take: pageSize,
      countries: "",
      regions: "",
      memberIdarray: ""
    });
  };

  const handlepopovercloseclick = () => {
    setAnchorE2(null);
  };

  const popoveropen = Boolean(anchorEl);
  const popoverid = popoveropen ? "simple-popover" : undefined;


  const popoveropen2 = Boolean(anchorE2);
  const popoverid2 = popoveropen2 ? "simple-popover" : undefined;
  // const isMounted = useRef(true);

  const loadLookupAction = useCallback(async () => {
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/appointment-status`,
      })
    );

    let allstatus = {
      displayName: "All",
      id: 100,
      isDisplay: true,
      status: "All",
    };
    const appointmentstatuslookup = await appointmentRequest;
    setstatusList([allstatus, ...statusList, ...appointmentstatuslookup.data.result.filter((x) => x.isDisplay === true)]);

    const cardiologistlistRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/admin/${clinicianId}/clinicians`,
      })
    );
    const cardiologistlistlookup = await cardiologistlistRequest;
    let allcardiologist = {
      email: "",
      firstName: "All",
      lastName: "",
      memberId: "all",
      teamId: "",
    }
    setcardiologistList([allcardiologist, ...cardiologistList, ...cardiologistlistlookup.data.result]);
  }, []);

  useEffect(() => {
    loadLookupAction();
  }, []);

  // const isMobileView =
  //   useMedia(
  //     // Media queries
  //     ["(min-width: 961px)"],
  //     // Column counts (relates to above media queries by array index)
  //     ["large"],
  //     // Default column count
  //     "small"
  //   ) === "small";

  const modifyAction = async (params) => {
    const [action, newArgs] = params;

    switch (action) {
      case "DELETE":
        return deleteAction(newArgs);
      case "ENABLE":
        return inlineEditAction(newArgs);
      case "FAVORITE":
        return inlineEditAction(newArgs);
      case "SORT":
        return sortAction(newArgs);
      case "SEARCH":
        return loadAction(newArgs);
      case "FILTER":
        return loadAction(newArgs);
      default:
        throw new Error("unhandled action");
    }
  };

  // Fetch data
  const listState = useAsync({
    promiseFn: loadAction,
    deferFn: modifyAction,
    watch,
  });
  const { run, setData, isLoading, error, setError } = listState;

  /** Change rows per page */
  const onSizeChange = useCallback(
    async (event) => {
      const rowsPerPage = +event.target.value;
      setPageSize(rowsPerPage);
      setLastAction("SEARCH");
      return run("SEARCH", {
        filter,
        countries: (get(filter, "countries") || ""),
        status: (get(filter, "status") || ""),
        regions: (get(filter, "regions") || ""),
        searchInput,
        sortprops: sortprops,
        pageNumber: 1,
        take: rowsPerPage,
      });
    },
    [filter, run, searchInput]
  );

  /** Search input functions */
  // const onSearchClick = useCallback(async () => {
  //   setLastAction("SEARCH");
  //   return run("SEARCH", { filter, searchInput });
  // }, [filter, run, searchInput]);

  const onSearchClick = useCallback(async () => {
    setLastAction("SEARCH");
    return run("SEARCH", {
      searchInput: searchInput,
      countries: (get(filter, "countries") || ""),
      status: (get(filter, "status") || ""),
      regions: (get(filter, "regions") || ""),
      sortprops: sortprops,
      filter: filter,
      take: pageSize,
      pageNumber: pageNumber,
    });
  }, [filter, run, searchInput]);

  const onClear = useCallback(async () => {
    setSearchInput("");
    // setPageSize(pageSize);
    setLastAction("SEARCH");
    return run("SEARCH", {
      searchInput: "",
      sortprops: sortprops,
      filter: filter,
      take: pageSize,
      pageNumber: pageNumber,
    });
  }, [filter, run]);

  const updateSearchInputValue = (evt) => {
    return setSearchInput(evt.target.value);
  };

  const onChangePage = useCallback(
    async (newPage: number) => {
      setpageNumber(newPage);
      setLastAction("SEARCH");
      return run("SEARCH", {
        filter,
        countries: (get(filter, "countries") || ""),
        status: (get(filter, "status") || ""),
        regions: (get(filter, "regions") || ""),
        sortprops: sortprops,
        searchInput,
        take: pageSize,
        pageNumber: newPage,
      });
    },
    [filter, run, searchInput, pageSize]
  );

  /** Action functions */
  // const _addAction = useCallback(async () => {
  //   setAddLoading(true);
  //   try {
  //     await addAction({
  //       history,
  //       reload: () => {
  //         if (isMounted.current) setAddLoading(false);
  //         return run("SEARCH");
  //       },
  //     });
  //   } catch (e) {
  //     setError(e);
  //   }
  // }, [addAction, history, run, setError]);

  const _statusChangeAction = useCallback(
    async (event) => {
      setStatus(event.target.value);
      setLastAction("FILTER");
      setFilterValues(event.target.value);
      let statusvalue = event.target.value;
      setFilter({ ...filter, status: statusvalue });
      return run("FILTER", {
        filter: { ...filter, status: statusvalue },
        searchInput: searchInput,
        take: pageSize,
      });
    },
    [filter, run, searchInput]
  );

  const _drillDownAction = useCallback(
    (entity) =>
      drillDownAction
        ? async () => {
          setLastAction("DRILLDOWN");
          return drillDownAction({ entity, history });
        }
        : null,
    [drillDownAction, history]
  );

  const _uploadappointmentsAction = useCallback(
    (entity) =>
      uploadappointmentsAction
        ? async () => {
          setLastAction("EDIT");
          return uploadappointmentsAction({ entity, history });
        }
        : null,
    [uploadappointmentsAction, history]
  );

  const _viewAdminConsultationDetailsAction = useCallback(
    (entity) =>
      viewAdminConsultationDetailsAction
        ? async () => {
          setLastAction("EDIT");
          return viewAdminConsultationDetailsAction({ entity, history });
        }
        : null,
    [viewAdminConsultationDetailsAction, history]
  );

  // const reloadAction = useCallback(async () => {
  //   setSearchInput("");
  //   setLastAction("SEARCH");
  //   return run("SEARCH", {
  //     searchInput: "",
  //     filter: filter
  //   });
  // }, [filter, run]);

  const _deleteAction = useCallback(
    (entity, entities) =>
      deleteAction
        ? async () => {
          if (
            window.confirm("Are you sure you want to delete this record?") // NOSONAR
          ) {
            setLastAction("DELETE");
            return deleteAction({ entity, history });
          }
          return;
        }
        : null,
    [deleteAction, filter, run, searchInput, setData]
  );

  const _toggleAction = useCallback(
    (entity, entities) =>
      inlineEditAction
        ? async () => {
          setLastAction("ENABLE");
          // setData({
          //   data: entities.data.map(x => {
          //     if (
          //       (x.id && x.id === entity.id) ||
          //       (x.key && x.key === entity.key)
          //     ) {
          //       x[toggleField] = !entity[toggleField];
          //       return x;
          //     }
          //     return x;
          //   }),
          //   meta: entities.meta
          // });
          return run("ENABLE", {
            filter,
            entity,
            searchInput,
            pageNumber: entities.meta && entities.meta.currentPage,
          });
        }
        : null,
    [filter, inlineEditAction, run, searchInput, setData, toggleField]
  );

  const _favoriteAction = useCallback(
    (entity, entities) =>
      inlineEditAction
        ? async () => {
          setLastAction("FAVORITE");
          setData({
            data: entities.data.map((x) => {
              if (x.id === entity.id) {
                x[favoriteField] = !entity[favoriteField];
                return x;
              }
              return x;
            }),
            meta: entities.meta,
          });
          return run("FAVORITE", {
            filter,
            entity,
            searchInput,
            pageNumber: entities.meta && entities.meta.currentPage,
          });
        }
        : null,
    [favoriteField, filter, inlineEditAction, run, searchInput, setData]
  );

  const _sortAction = useCallback(
    (entities) =>
      sortAction
        ? async ({ oldIndex, newIndex }) => {
          setLastAction("SORT");
          const newEntities = move(entities.data, oldIndex, newIndex);
          setData({ data: newEntities, meta: entities.meta });
          return run("SORT", {
            filter,
            entities: newEntities,
            searchInput,
            pageNumber: entities.meta && entities.meta.currentPage,
          });
        }
        : null,
    [filter, run, searchInput, setData, sortAction]
  );

  const _sortHeaderAction = useCallback(
    async (prps) => {
      setsortprops(prps);
      setLastAction("FILTER");
      return run("FILTER", {
        filter,
        countries: (get(filter, "countries") || ""),
        status: (get(filter, "status") || ""),
        regions: (get(filter, "regions") || ""),
        sortprops: prps,
        searchInput: "",
        take: pageSize,
        // pageNumber: pageNumber
      });
    },
    [filter, run, searchInput]
  );
  // const _sortHeaderAction = async (prps) => {
  //   setLastAction("SORT");
  //   console.log("sort props",prps);

  //   return run("SORT", {
  //     filter: prps,
  //     searchInput: "",
  //     take: pageSize,
  //   });
  // };

  const [newvals, setnewvals] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "10", "11"]);

  const _statusChangeActionmultiselect = useCallback(
    async (data) => {
      setLastAction("FILTER");
      setFilterValues(data);
      let statusvalue = data;
      setFilter({ ...filter, status: statusvalue });
      setstatusvaluedata(statusvalue);
    },
    [filter, run, searchInput]
  );


  const _clinicanChangeActionmultiselect = useCallback(
    async (data) => {
      setLastAction("FILTER");
      setFilterValues(data);
      let memberidarrayvalue = data;
      setFilter({ ...filter, memberIdarray: memberidarrayvalue });
    },
    [filter, run, searchInput]
  );


  const _cardiologistchangeaction = useCallback(
    async (event) => {
      setLastAction("FILTER");
      let memberidvalue = event.target.value;
      setFilter({ ...filter, memberId: memberidvalue });
      setcardiologistvalue(memberidvalue);
    },
    [filter, run, searchInput]
  );

  const handleOptionToggle = (event) => {
    let newValue = [...newvals];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    setnewvals(newValue);
    let strigifynewvalue = newValue.join();
    _statusChangeActionmultiselect(strigifynewvalue);
  };

  const handleOptionRemove = (chipToDelete) => {
    let newValue = [...newvals];
    newValue = newValue.filter((item) => item !== chipToDelete);
    setnewvals(newValue);
    let strigifynewvalue = newValue.join();
    _statusChangeActionmultiselect(strigifynewvalue);
  };

  const handleselectallstatusclick = () => {
    _statusChangeActionmultiselect("1,2,3,4,5,6,7,8,10,11");
    setnewvals(["1", "2", "3", "4", "5", "6", "7", "8", "10", "11"]);
  };

  const _adminEditAction = useCallback(
    (entity) =>
    adminEditAction
        ? async () => {
            setLastAction("EDIT");
            return adminEditAction({ entity, history });
          }
        : null,
    [adminEditAction, history]
  );

  return (
    <>
      <Container maxWidth="xl">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackbaropen}
          autoHideDuration={3000}
          onClose={handlesnackbarClose}
        >
          <TitleAlert title={"Please select atleast one status!"} />
        </Snackbar>

        <Grid
          alignItems="center"
          container
          justify="space-between"
          className={classes.topbar}
        >
          <Grid item>
            <ThemeProvider theme={muitheme}>
              <Input
                className={
                  mediawidth === "xl" || mediawidth === "lg"
                    ? classes.largeinput
                    : classes.largeinputtablet
                }
                id="standard-adornment-password"
                placeholder={t("Search cardiologist or patient name")}
                value={searchInput}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    onSearchClick(); // here was the mistake
                  }
                }}
                onChange={(e) => updateSearchInputValue(e)}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      classes={{
                        root:
                          mediawidth === "xl" || mediawidth === "lg"
                            ? ""
                            : classes.smallpaddediconButton,
                      }}
                      aria-label="toggle password visibility"
                      onClick={onSearchClick}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </ThemeProvider>
          </Grid>
          <Grid item>
            <FormControl
              variant="outlined"
              className={
                mediawidth === "xl" || mediawidth === "lg"
                  ? classes.formControl
                  : classes.formControlsmall
              }
            >
              <Select
                labelId="demo-simple-select-label-3"
                id="demo-simple-select-3"
                value={"0"}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                }}
                renderValue={(value) => (
                  <MuiTypography
                    fontFamily="Work Sans"
                    fontSize="16px"
                    fonStyle="normal"
                    fonWeight="400"
                    lineHeight="20px"
                    color="#142A39"
                  >
                    {dateselectionmenuitemtext}
                  </MuiTypography>
                )}
                input={
                  <OutlinedInput
                    labelWidth={0}
                    name="status"
                    id="outlined-status-simple"
                    classes={outlinedInputClasses}
                  />
                }
                open={isSelectorOpen}
                onChange={handlestoppropogationChange}
                onOpen={handlepopoveropenClick}
              >
                <MenuItem value={"1"}>
                  <Popover
                    id={popoverid}
                    open={popoveropen}
                    anchorEl={anchorEl}
                    // onClose={handlepopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Grid
                      container
                      justify="flex-end"
                      direction="column"
                      className={classes.popovergrid}
                    >
                      {invaliddatealert && (
                        <Grid item>
                          <TitleAlert title={invaliddatealertmessage} />
                        </Grid>
                      )}
                      <Grid item>
                        <MuiTypography
                          fontSize="12px"
                          fontFamily="Work Sans"
                          lineHeight="16px"
                          letterSpacing="0.4px"
                          color="rgba(20, 42, 57, 0.72)"
                        >
                          {t("Start Date")}
                        </MuiTypography>
                        <Datepicker
                          label="Start Date"
                          date={startDate}
                          changeHandle={handleStartDateChange}
                        />
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="12px"
                          fontFamily="Work Sans"
                          lineHeight="16px"
                          letterSpacing="0.4px"
                          color="rgba(20, 42, 57, 0.72)"
                        >
                          {t("End Date")}
                        </MuiTypography>
                        <Datepicker
                          label="End Date"
                          date={endDate}
                          changeHandle={handleEndDateChange}
                        />
                        <Divider />
                      </Grid>

                      <Grid
                        item
                        container
                        justify="space-between"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item>
                          <Button onClick={handlepopovercancelclick}>
                            <MuiTypography
                              fontFamily="Work Sans"
                              fontSize="14px"
                              fonStyle="normal"
                              fontWeight={700}
                              lineHeight="16.42px"
                              letterSpacing="0.75 px"
                              color="#142A39"
                            >
                              {t("CANCEL")}
                            </MuiTypography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button onClick={handlepopoverresetclick}>
                            <MuiTypography
                              fontFamily="Work Sans"
                              fontSize="14px"
                              fonStyle="normal"
                              fontWeight={700}
                              lineHeight="16.42px"
                              letterSpacing="0.75 px"
                              color="#2D9F86"
                            >
                              {t("RESET")}
                            </MuiTypography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <div className="telekardia">
                            <button
                              type="button"
                              className="btn m-1 btn_teal py-2 px-4"
                              onClick={handlepopoverokayclick}
                            >
                              {t("OK")}
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box pt={2} />
        <div className="all_apptContainer box_shade mt-5">
          {addAction || title}
          <>
            {searchEnabled && (
              <Grid
                alignItems="flex-start"
                spacing={0}
                className={classes.paddingTopmainmenu}
                container
                justify="space-between"
                style={{ display: "-ms-flexbox", background: "#fff" }}
              >
                <Grid item>
                  <Grid
                    alignItems="center"
                    spacing={2}
                    container
                    justify="flex-start"
                  >
                    <Grid item>
                      <MuiTypography
                        fontFamily="Work Sans"
                        fontSize="16px"
                        fonStyle="normal"
                        fontWeight={600}
                        lineHeight="20px"
                        color="#142A39"
                      >
                        Filter
                      </MuiTypography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={handlepopover2openClick}
                        aria-label="Search"
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      {combinedarray &&
                        combinedarray.length > 0 &&
                        combinedarray.map((value) => (
                          <>
                            <Chip
                              variant="outlined"
                              className={classes.chipfont}
                              key={value.id}
                              label={
                                "regions" in value
                                  ? `Country - ${value.title}`
                                  : "regionId" in value
                                  ? `Region - ${value.title}`
                                  : "displayName" in value
                                  ? `Status - ${value.displayName}`
                                  : `Cardiologist - ${value.firstName}`
                              }
                              onDelete={() => removetag(value)}
                            />
                            &nbsp;
                          </>
                        ))}
                    </Grid>
                  </Grid>
                  <Popover
                    id={popoverid2}
                    open={popoveropen2}
                    anchorEl={anchorE2}
                    className={classes.popper}
                    modifiers={{
                      preventOverflow: {
                        enabled: false,
                        boundariesElement: "window",
                      },
                      arrow: {
                        enabled: true,
                        element: arrowRef,
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {/* <Paper> */}
                    {/* <Paper  className={classes.popoverRoot}>
                   <span className={classes.arrow} ref={setArrowRef} />
                   </Paper> */}
                    {/* <Box mt={5} /> */}
                    <Grid
                      item
                      container
                      justify="flex-end"
                      alignItems="flex-end"
                      direction="row"
                      spacing={2}
                    >
                      <Grid item>
                        <IconButton
                          aria-label="close"
                          onClick={handlepopovercloseclick}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      className={classes.popovergrid2}
                    >
                      <Grid item>
                        <InputLabel
                          ref={inputLabel1}
                          className={classes.formControllabelstyle2}
                        >
                          {t("Status")}
                        </InputLabel>
                        <FormControl
                          variant="outlined"
                          className={
                            mediawidth === "xl" || mediawidth === "lg"
                              ? classes.formControlchip2
                              : classes.formControlchipsmall2
                          }
                        >
                          <Select
                            multiple
                            className="not-rounded"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newvals}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            }}
                            renderValue={(selected) =>
                              statusarray.length > 0 ? (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  {`${statusarray
                                    .map((x) => x.displayName)
                                    .join()
                                    .slice(0, 30)}...`}
                                </MuiTypography>
                              ) : (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  All
                                </MuiTypography>
                              )
                            }
                            input={
                              <OutlinedInput
                                labelWidth={0}
                                name="status"
                                id="outlined-status-simple"
                                classes={outlinedInputClasses}
                              />
                            }
                          >
                            <StyledMenuItem
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={statusList}
                                disableCloseOnSelect
                                disableClearable
                                classes={{
                                  inputRoot: classes.autocompleteinput,
                                }}
                                getOptionLabel={(option) => option.displayName}
                                getOptionSelected={(option, value) =>
                                  value.id === option.id
                                }
                                onKeyDown={(e) => e.stopPropagation()}
                                renderTags={(tagValue, getTagProps) => {
                                  return <></>;
                                }}
                                style={{ width: "240px" }}
                                value={statusarray}
                                onChange={(event, newValue, reason, details) => {
                                  changestatusarray(newValue, details);
                                }}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <GreenCheckbox
                                      color="primary"
                                      checked={selected}
                                      style={{ marginRight: 8 }}
                                    />
                                    <MuiTypography
                                      fontFamily="Work Sans"
                                      fontSize="16px"
                                      fonStyle="normal"
                                      fonWeight="400"
                                      lineHeight="20px"
                                      color="#142A39"
                                    >
                                      {option.displayName}
                                    </MuiTypography>
                                  </React.Fragment>
                                )}
                                // style={{ padding: 10 }}
                                renderInput={(params) => (
                                  <CssTextField
                                    {...params}
                                    label={props.label}
                                    placeholder="Select status"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </StyledMenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <InputLabel
                          ref={inputLabel1}
                          className={classes.formControllabelstyle2}
                        >
                          {t("Cardiologist")}
                        </InputLabel>
                        <FormControl
                          variant="outlined"
                          className={
                            mediawidth === "xl" || mediawidth === "lg"
                              ? classes.formControlchip2
                              : classes.formControlchipsmall2
                          }
                        >
                          <Select
                            multiple
                            className="not-rounded"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newvals}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            }}
                            renderValue={(selected) =>
                              clinicianarray.length > 0 ? (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  {`${clinicianarray
                                    .map((x) => x.firstName)
                                    .join()
                                    .slice(0, 30)}...`}
                                </MuiTypography>
                              ) : (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  All
                                </MuiTypography>
                              )
                            }
                            input={
                              <OutlinedInput
                                labelWidth={0}
                                name="status"
                                id="outlined-status-simple"
                                classes={outlinedInputClasses}
                              />
                            }
                          >
                            <StyledMenuItem
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={cardiologistList}
                                disableCloseOnSelect
                                disableClearable
                                classes={{
                                  inputRoot: classes.autocompleteinput,
                                }}
                                getOptionLabel={(option) =>
                                  `${option.firstName} ${option.lastName}`
                                }
                                getOptionSelected={(option, value) =>
                                  value.memberId === option.memberId
                                }
                                onKeyDown={(e) => e.stopPropagation()}
                                renderTags={(tagValue, getTagProps) => {
                                  return <></>;
                                }}
                                style={{ width: "240px" }}
                                value={clinicianarray}
                                onChange={(event, newValue, reason, details) => {
                                  changeclinciansarray(newValue, details);
                                }}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <GreenCheckbox
                                      color="primary"
                                      checked={selected}
                                      style={{ marginRight: 8 }}
                                    />
                                    <MuiTypography
                                      fontFamily="Work Sans"
                                      fontSize="16px"
                                      fonStyle="normal"
                                      fonWeight="400"
                                      lineHeight="20px"
                                      color="#142A39"
                                    >
                                      {option.firstName} {option.lastName}
                                    </MuiTypography>
                                  </React.Fragment>
                                )}
                                // style={{ padding: 10 }}
                                renderInput={(params) => (
                                  <CssTextField
                                    {...params}
                                    label={props.label}
                                    placeholder="Select cardiologist"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </StyledMenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <InputLabel
                          ref={inputLabel1}
                          className={classes.formControllabelstyle2}
                        >
                          {t("Country")}
                        </InputLabel>
                        <FormControl
                          variant="outlined"
                          className={
                            mediawidth === "xl" || mediawidth === "lg"
                              ? classes.formControlchip2
                              : classes.formControlchipsmall2
                          }
                        >
                          <Select
                            multiple
                            className="not-rounded"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newvals}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            }}
                            renderValue={(selected) =>
                              countryarray.length > 0 ? (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  {`${countryarray
                                    .map((x) => x.name)
                                    .join()
                                    .slice(0, 30)}...`}
                                </MuiTypography>
                              ) : (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  All
                                </MuiTypography>
                              )
                            }
                            input={
                              <OutlinedInput
                                labelWidth={0}
                                name="status"
                                id="outlined-status-simple"
                                classes={outlinedInputClasses}
                              />
                            }
                          >
                            <StyledMenuItem
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={countryarrayoptions}
                                disableCloseOnSelect
                                disableClearable
                                classes={{
                                  inputRoot: classes.autocompleteinput,
                                }}
                                getOptionLabel={(option) => option.title}
                                getOptionSelected={(option, value) =>
                                  value.code === option.code
                                }
                                onKeyDown={(e) => e.stopPropagation()}
                                renderTags={(tagValue, getTagProps) => {
                                  return <></>;
                                }}
                                style={{ width: "240px" }}
                                value={countryarray}
                                onChange={(event, newValue, reason, details) => {
                                  changecountryarray(newValue, details);
                                }}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <GreenCheckbox
                                      color="primary"
                                      checked={selected}
                                      style={{ marginRight: 8 }}
                                    />
                                    <MuiTypography
                                      fontFamily="Work Sans"
                                      fontSize="16px"
                                      fonStyle="normal"
                                      fonWeight="400"
                                      lineHeight="20px"
                                      color="#142A39"
                                    >
                                      {option.title}
                                    </MuiTypography>
                                  </React.Fragment>
                                )}
                                // style={{ padding: 10 }}
                                renderInput={(params) => (
                                  <CssTextField
                                    {...params}
                                    label={props.label}
                                    placeholder="Select country"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </StyledMenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <InputLabel
                          ref={inputLabel1}
                          className={classes.formControllabelstyle2}
                        >
                          {t("Region")}
                        </InputLabel>

                        <FormControl
                          variant="outlined"
                          className={
                            mediawidth === "xl" || mediawidth === "lg"
                              ? classes.formControlchip2
                              : classes.formControlchipsmall2
                          }
                        >
                          <Select
                            multiple
                            className="not-rounded"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newvals}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            }}
                            renderValue={(selected) =>
                              regionarray.length > 0 ? (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  {`${regionarray
                                    .map((x) => x.name)
                                    .join()
                                    .slice(0, 30)}...`}
                                </MuiTypography>
                              ) : (
                                <MuiTypography
                                  fontSize="14px"
                                  lineHeight="16.42px"
                                  fontFamily="Work Sans"
                                  letterSpacing="-0.02px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  All
                                </MuiTypography>
                              )
                            }
                            input={
                              <OutlinedInput
                                labelWidth={0}
                                name="status"
                                id="outlined-status-simple"
                                classes={outlinedInputClasses}
                              />
                            }
                          >
                            <StyledMenuItem
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={regionarrayoptions}
                                disableCloseOnSelect
                                disableClearable
                                classes={{
                                  inputRoot: classes.autocompleteinput,
                                }}
                                getOptionLabel={(option) => option.title}
                                getOptionSelected={(option, value) =>
                                  value.code === option.code
                                }
                                onKeyDown={(e) => e.stopPropagation()}
                                renderTags={(tagValue, getTagProps) => {
                                  return <></>;
                                }}
                                style={{ width: "240px" }}
                                value={regionarray}
                                onChange={(event, newValue, reason, details) => {
                                  changeregionarray(newValue, details);
                                }}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <GreenCheckbox
                                      color="primary"
                                      checked={selected}
                                      style={{ marginRight: 8 }}
                                    />
                                    <MuiTypography
                                      fontFamily="Work Sans"
                                      fontSize="16px"
                                      fonStyle="normal"
                                      fonWeight="400"
                                      lineHeight="20px"
                                      color="#142A39"
                                    >
                                      {option.title}
                                    </MuiTypography>
                                  </React.Fragment>
                                )}
                                // style={{ padding: 10 }}
                                renderInput={(params) => (
                                  <CssTextField
                                    {...params}
                                    label={props.label}
                                    placeholder="Select region"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </StyledMenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        container
                        justify="flex-end"
                        alignItems="flex-end"
                        direction="row"
                        spacing={2}
                      >
                        <Grid item>
                          <Button
                            style={buttonstyle}
                            onClick={handlepopoverresetclick2}
                          >
                            <MuiTypography
                              fontSize="14px"
                              lineHeight="16.42px"
                              fontFamily="Work Sans"
                              letterSpacing="0.75px"
                              fontWeight={700}
                              color="#2D9F86"
                            >
                              {t("CLEAR ALL")}
                            </MuiTypography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <div className="telekardia">
                            <button
                              type="button"
                              className="btn m-1 btn_teal py-2 px-4"
                              onClick={onsubmitfilter}
                            >
                              {t("APPLY")}
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </Paper> */}
                  </Popover>
                </Grid>
              </Grid>
              // </ThemeProvider>
              // </div>
            )}
          </>
          <Grid container spacing={0}>
            <Paper className={classes.listroot}>
              {isLoading && ["DELETE", "SORT", "ENABLE"].includes(lastAction) && (
                <Note>
                  <EcgLoader />
                </Note>
              )}

              <IfPending state={listState} initial={true}>
                <Container>
                  <Spacing padding="80px">
                    <EcgLoader />
                  </Spacing>
                </Container>
              </IfPending>

              {isLoading && ["SEARCH", "FILTER"].includes(lastAction) && (
                <Container>
                  <Spacing padding="80px">
                    <EcgLoader />
                  </Spacing>
                </Container>
              )}

              {/*Display when there was an error with data*/}
              <IfRejected state={listState}>
                {(
                  error // NOSONAR
                ) => (
                  <Flex justify="center">
                    <Flex
                      direction="column"
                      justify="center"
                      textAlign="center"
                    >
                      <FlexItem>
                        <Spacing padding="50px">
                          {userError(error, lastAction)}
                        </Spacing>
                      </FlexItem>
                    </Flex>
                  </Flex>
                )}
              </IfRejected>

              {/*Display when data was retrieved, persist for optimistic updates*/}
              <IfFulfilled state={listState} persist={true}>
                {(entities) =>
                  (entities.data.length === 0 && (
                    <Flex justify="center">
                      <Spacing padding="20px">{"No Data"}</Spacing>
                    </Flex>
                  )) ||
                  ((!isLoading || !["SEARCH", "FILTER"].includes(lastAction)) &&
                    !error && (
                      <>
                        <div className="telekardia">
                          <ListTable
                            feature="Admin Consultations"
                            {...props}
                            onSortEnd={_sortAction(entities)}
                            headings={headers}
                            sortordertype={sortordertype}
                            sortPropsName={sortPropsName}
                            sortHeaderAction={_sortHeaderAction}
                            items={entities.data.map((entity, index) => ({
                              entityValues: entityProps.map((prop) =>
                                render && render[prop]
                                  ? render[prop](get(entity, prop))
                                  : get(entity, prop)
                              ),
                              toggleChecked:
                                toggleField && inlineEditAction
                                  ? entity[toggleField] ||
                                    typeof entity[toggleField] === "undefined"
                                  : null,
                              favoriteChecked:
                                favoriteField && inlineEditAction
                                  ? !!entity[favoriteField]
                                  : null,

                              drillDownAction: _drillDownAction(entity),
                              uploadappointmentsAction:
                                _uploadappointmentsAction(entity),
                              viewAdminConsultationDetailsAction:
                                _viewAdminConsultationDetailsAction(entity),
                                adminEditAction: _adminEditAction(entity),
                              deleteAction: _deleteAction(entity, entities),
                              onToggleChange: _toggleAction(entity, entities),
                              onFavoriteChange: _favoriteAction(
                                entity,
                                entities
                              ),
                              isLoading,
                            }))}
                          />
                        </div>
                        <Flex justify="flex-end">
                          <MaterialPagination
                            pageSize={pageSize}
                            current={
                              entities &&
                              entities.meta &&
                              entities.meta.currentPage
                            }
                            total={
                              entities && entities.meta && entities.meta.count
                            }
                            onChange={onChangePage}
                            onSizeChange={onSizeChange}
                          />
                        </Flex>
                      </>
                    ))
                }
              </IfFulfilled>
            </Paper>
            {/* </Grid> */}
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default ListPageAdminConsultations;
