import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';

const initialState = {
    events:[],
    error:null,
    loading:false
}

const addAvailabilityStart = (state,action) =>{
    return updateObject(state,{loading:true,error:null});
}

const addAvailabilitySuccess = (state,action) =>{
    return updateObject( state, {
       
        events:state.events.concat(action.availabilityData.result),
        error:null,
        loading:false
        
    } );
}

const addAvailabilityFail = (state,action) =>{
    return updateObject(state, {
        error:action.error,
        loading:false
    });
}

const getAvailabilitiesStart = (state,action) =>{
    return updateObject(state,{
        error:null,
        loading:true,
        events:[]
    });
}

const getAvailabilitiesSuccess = (state,action) =>{
        return updateObject(state,{
        events:action.availabilityData,
        error:null,
        loading:false
    });
}

const getAvailabilitiesFail = (state,action) =>{
    return updateObject(state, {
        error:action.error,
        loading:false
    });
}

const updateAvailabilityStart = (state,action) =>{
    return updateObject(state,{
        error:null,
        loading:true
    });
}
const updateAvailabilitySuccess = (state,action) =>{
    return updateObject(state,{
        events:state.events.map((availability)=>availability.id === action.id ? {...availability}:availability),
        error:null,
        loading:false
    });
}
const updateAvailabilityFail = (state,action) =>{
    return updateObject(state, {
        error:action.error,
        loading:false
    });
}

const deleteAvailabilityStart = (state,action) =>{
    return updateObject(state,{
        error:null,
        loading:true
       
    });
}
const deleteAvailabilitySuccess = (state,action) =>{
    return updateObject(state,{
        events:[...state.events.filter(id=>id!==action.id)],
        loading:false
       
    });
}
const deleteAvailabilityFail = (state,action) =>{
    return updateObject(state, {
        error:action.error,
        loading:false
        
    });
}

const reducer = ( state =initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ADD_AVAILABILITY_START: return addAvailabilityStart( state, action );
        case actionTypes.ADD_AVAILABILITY_SUCCESS: return addAvailabilitySuccess( state, action )
        case actionTypes.ADD_AVAILABILITY_FAIL: return addAvailabilityFail( state, action );
        case actionTypes.GET_AVAILABILITY_START: return getAvailabilitiesStart( state, action );
        case actionTypes.GET_AVAILABILITY_SUCCESS: return getAvailabilitiesSuccess( state, action );
        case actionTypes.GET_AVAILABILITY_FAIL: return getAvailabilitiesFail( state, action );
        case actionTypes.DELETE_AVAILABILITY_START:return deleteAvailabilityStart(state,action);
        case actionTypes.DELETE_AVAILABILITY_SUCCESS:return deleteAvailabilitySuccess(state,action);
        case actionTypes.DELETE_AVAILABILITY_FAIL:return deleteAvailabilityFail(state,action);
        case actionTypes.UPDATE_AVAILABILITY_START:return updateAvailabilityStart(state,action);
        case actionTypes.UPDATE_AVAILABILITY_SUCCESS:return updateAvailabilitySuccess(state,action);
        case actionTypes.UPDATE_AVAILABILITY_FAIL:return updateAvailabilityFail(state,action);
        default: return state;
    }
};

export default reducer;