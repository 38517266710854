import React, { useEffect, useState, useCallback } from "react";
import CountdownTimer from "react-component-countdown-timer";

const Countdown_Timer = (props) => {
  const [color, setcolor] = useState("#e6c029");
  const [settings, setsettings] = useState({
    count: props.count,
    border: true,
    showTitle: true,
    noPoints: true,
  });

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    setsettings({
      count: props.count,
      border: true,
      showTitle: true,
      noPoints: true,
    });

    let timer = setTimeout(() => {
      setcolor("#CC3D3F");
    }, props.count * 1000 - 10000);
    forceUpdate();
    return () => clearTimeout(timer);
  }, [props.count]);

  return (
    <div className="d-flex flex-row">
      <CountdownTimer
        responsive
        {...settings}
        size={50}
        hideDay
        hideHours
        showTitle
        border
        backgroundColor={color}
      />
    </div>
  );
};

export default Countdown_Timer;
