import React from 'react'
import {
    Container,
    IconButton,
    InputLabel,
    OutlinedInput,
    Grid,
    Select,
    Input,
    InputAdornment,
    Box,
    TextField,
  } from "@material-ui/core";
import {
    createStyles,
    makeStyles,
    withStyles,
    Theme,
    ThemeProvider,
    createMuiTheme,
    useTheme
  } from "@material-ui/core/styles";
import EcgLoader from '../Shared/EcgLoader';
import MuiTypography from "../Shared/Typography/MuiTypography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BottomLineTitle from "../Shared/Typography/BottomLineTitle";
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from "react-i18next";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import * as API from '../../Services/API/actions';
import {useSelector} from "react-redux";


const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      overflowX: "auto",
      marginTop: 0,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    center: {
        textAlign:'center',
        margin:'auto'
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    container: {
      display:
        "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
      flexWrap: "wrap",
    },
    topbar: {
      background: "#FFFFFF",
      height: "66px",
      padding: "4px 4px",
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
    },
    largeinput: {
      color: "#142A39 !important",
      minWidth: "512px",
      fontFamily: "Work Sans !important",
      "& input::placeholder": {
        fontFamily: "Work Sans !important",
        fontWeight: 400,
        color: "#142A39 !important",
        opacity: 1,
        textAlign: "left",
      },
      "&:focus": {
        borderColor: "#2D9F86",
      },
    },
    largeinputtablet: {
      color: "#142A39 !important",
      minWidth: "212px",
      fontFamily: "Work Sans !important",
      "& input::placeholder": {
        fontFamily: "Work Sans !important",
        fontSize: "9px",
        fontWeight: 400,
        color: "#142A39 !important",
        opacity: 1,
        textAlign: "left",
      },
      "&:focus": {
        borderColor: "#2D9F86",
      },
    },
  }));

const muitheme = createMuiTheme({
    palette: {
      primary: {
        main: "#2D9F86"
      }
    },
    typography: {
      fontFamily: 'Work Sans',
    },
  });
  function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || "xs"
    );
  };

function AdminSessionInfo() {
    const classes = useStyles();
    const { t } = useTranslation();
    const clinicianId = useSelector((state) => state.user.profile.id);
    const teamId = useSelector((state) => state.user.team.id);
    const [searchInput, setSearchInput] = React.useState("");
    const [sessionUrl,setsessionUrl] = React.useState("");
    const [isrecordingAvailable,setisrecordingAvailable] = React.useState(false);
    const [message,setmessage] = React.useState("No Session Info Available");
    const mediawidth = useWidth();
    const [isLoading,setloading] = React.useState(false);
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const updateSearchInputValue = (e) => {
        setSearchInput(e.target.value);
      };
    
    const sessionHandler =()=>{
        if(regexExp.test(searchInput.trim())){
            const params = {
                appointmentId : searchInput
            }
            setloading(true);
            API.sessionURL(teamId,clinicianId,searchInput,params).subscribe(res=>{
                console.log(res.data.result)
                setisrecordingAvailable(res.data.result.recordingConsent);
                setsessionUrl(res.data.result.sessionURL);
                setmessage(res.data.result.message);
                setloading(false);
             },
             (error)=>{
                 setloading(false);
                 setmessage("Error Occurred. Try again")
             });
        }
        else{
            setsessionUrl("");
            setloading(false);
            setisrecordingAvailable(false);
            setmessage("AppointmentId is not Valid ");
        }
        
    }

  return (
        <div className="row no-gutters all_appointments">
            <div className="col-12">
                <Container maxWidth="xl">
                    <Box mt={2} />
                    <Grid alignItems="center" container justify="space-between">
                    <Grid item>
                        <MuiTypography
                        fontSize="34px"
                        fontFamily="Work Sans"
                        lineHeight="40px"
                        letterSpacing="0.25px"
                        fontWeight={500}
                        color="#000000"
                        >
                        {t("Session Info")} 
                        </MuiTypography>

                        <BottomLineTitle />
                    </Grid>
                    </Grid>
                    <Box mt={2} />
                    <Grid
                        alignItems="center"
                        container
                        className={classes.topbar}
                        >
                        <Grid item>
                            <ThemeProvider theme={muitheme}>
                            <Input
                                className={
                                mediawidth === "xl" || mediawidth === "lg"
                                    ? classes.largeinput
                                    : classes.largeinputtablet
                                }
                                id="standard-adornment-password"
                                placeholder={t("Enter AppointmentId")}
                                value={searchInput}
                                onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    sessionHandler(); // here was the mistake
                                }
                                }}
                                onChange={(e) => updateSearchInputValue(e)}
                                startAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                    classes={{
                                        root:
                                        mediawidth === "xl" || mediawidth === "lg"
                                            ? ""
                                            : classes.smallpaddediconButton,
                                    }}
                                    aria-label="toggle password visibility"
                                    onClick={sessionHandler}
                                    >
                                    <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            </ThemeProvider>
                        </Grid>
                        <Grid item >
                        <div className="telekardia">
                            <button
                            type="button"
                            className="btn m-2 btn_teal py-2 px-4"
                            onClick={sessionHandler}
                            >
                            
                            {t("SEARCH")}
                            </button>
                        </div>
                    </Grid>
                    </Grid>
                    <Box mt={2} />
                    <Grid alignItems="center" container justify="space-between">
                    {isrecordingAvailable && sessionUrl.length !==0 && !isLoading?
                        <video className="video-js"
                            controls
                            preload="auto"
                            controlsList="nodownload"
                            // poster="//vjs.zencdn.net/v/oceans.png"
                            style={{height:"70vh",width:"100%"}}
                        >
                            <source src={sessionUrl} type="video/mp4"></source>
                        </video>
                        :
                        isLoading ? 
                        <div className={classes.center}>
                            <EcgLoader />
                        </div>
                            
                            :
                        <Box display="flex" justify="center" alignItems="center" className={classes.center}>
                        <MuiTypography
                            fontSize="12px"
                            fontFamily="Work Sans"
                            lineHeight="20px"
                            letterSpacing="-0.2px"
                            textTransform="uppercase"
                            color="#536063"
                            textAlign="center"
                            >
                            {message} 
                        </MuiTypography>
                        </Box>
                        }
                    </Grid>
                </Container>
            </div>
        </div>
        )
}

export default AdminSessionInfo