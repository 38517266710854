/* eslint-disable  */
import React, { useEffect } from "react";
import NexmoClient from "nexmo-client";
import CallSharpIcon from "@material-ui/icons/CallSharp";
import * as API from "../../../Services/API/actions";
import { useSelector } from "react-redux";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import EndConsultationPopUp from "./EndConsultationPopUp";

export default function VonageCall(props) {
  const [start, setStart] = React.useState(false);
  const [vonageApp, setvonageApp] = React.useState(null);
  const [callStatus, setcallStatus] = React.useState(null);
  const [jwt, setjwt] = React.useState(null);
  const [callInProgress, setProgress] = React.useState("InComplete");
  const [member, setmember] = React.useState(null);
  const [mute, setmute] = React.useState(false);
  const [startcall, setstartcall] = React.useState(false);
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const [callStart, setcallStart] = React.useState(false);
  const [endcallconfirmationpopup, setendcallconfirmationpopup] =  React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  useEffect(() => {
    if(!props.pstin_Enabled){

      API.jwtTokenVonage(teamId, profileId).subscribe(
        (res) => {
          // setjwt(res.data["result"]);
          VonageInit(res.data["result"]);
          setjwt(res.data["result"]);
          
          
        },
        (error) => {
          console.log(error);
        }
      );
    }
    else{
      API.fallBack_jwtTokenVonage(teamId, profileId,props.appointmentId).subscribe(
        (res) => {
          // setjwt(res.data["result"]);
          VonageInit(res.data["result"]);
          setjwt(res.data["result"]);
          
          
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, []);


  useEffect(()=>{
   props.disableHandler(true);
    if(vonageApp !== null)
    {
      switchHandler();
    }
    //vonageApp.callServer(props.number);
    //props.disableHandler(true);
  },[vonageApp]);
  const callMetrics=(conversationId)=>{
    // let queryParams = {
    //   conversationUUID : conversationId
    // }
    API.fallBack_CallMetrics(teamId, profileId,props.appointmentId,conversationId).subscribe(
      (res) => {
       //update callMetrics response
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const vonage_reconnect =()=>{
    API.vonage_reconnect(teamId, profileId,props.appointmentId).subscribe(
      (res) => {
       console.log("PSTN CALL reconnection Intiatied")
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const VonageInit = (token) => {
    new NexmoClient({ debug: true, nexmo_api_url: "https://api-us-1.nexmo.com",  url: "wss://ws-us-1.nexmo.com",  ips_url: "https://api-us-1.nexmo.com/v1/image" })
      .login(token)
      .then((app) => {
        app.on("member:call", (member, call) => {
          setcallStart(true);
          setcallStatus(call);
          setmember(member);
          callMetrics(call.conversation.id);
          app.getConversation(call.conversation.id).then((conversation) => {
            conversation.on("member:left", (event) => {
              call.hangUp();
            });
          });
        });
        setstartcall(true);
        setvonageApp(app);        
        app.on("call:status:changed", (call) => {
          if (call.status === call.CALL_STATUS.STARTED) {
            setProgress("Connecting");
          } else if (call.status === call.CALL_STATUS.RINGING)
            setProgress("Ringing");
          else if (call.status === call.CALL_STATUS.CANCELLED) {
            setProgress("Cancelled");
            setstartcall(false);
            call.hangUp();
            props.disableHandler(false);
          } else if (call.status === call.CALL_STATUS.BUSY) {
            setProgress("Busy");
            setstartcall(false);
            setStart(false);
            call.hangUp();
            props.disableHandler(false);
          } else if (call.status === call.CALL_STATUS.FAILED) {
            setProgress("Failed");
            setstartcall(false);
            setStart(false);
            call.hangUp();
            props.disableHandler(false);
          } else if (call.status === call.CALL_STATUS.TIMEOUT) {
            setProgress("TimeOut");
            setstartcall(false);
            setStart(false);
            call.hangUp();
            props.disableHandler(false);
          } else if (call.status === call.CALL_STATUS.REJECTED) {
            setProgress("Rejected");
            setstartcall(false);
            setStart(false);
            call.hangUp();
            props.disableHandler(false);
          } else if (call.status === call.CALL_STATUS.ANSWERED)
            setProgress("Answered");
          else if (call.status === call.CALL_STATUS.COMPLETED) {
            setProgress("Completed");
            setstartcall(false);
            setStart(false);
            call.hangUp();
          } else {
            setProgress("UnAnswered");
            setstartcall(false);
            call.hangUp();
            setStart(false);
            props.disableHandler(false);
          }
        });
      })
      .catch((err) => console.log(err));
  };
  const muteHandler = () => {
    if (!mute) {
      member.mute(true);
      setmute(true);
    } else {
      member.mute(false);
      setmute(false);
    }
  };
  const endconsultationpopuphandler = (event) => {
    setAnchorEl(event.currentTarget);
    setendcallconfirmationpopup(true);
  }
  const continuesessionconfirmationclick = () => {
    setendcallconfirmationpopup(false);
  };
  const endsessionconfirmationclick = () => {
    setendcallconfirmationpopup(false);
    switchHandler();
  };
  const switchHandler = () => {
      setStart(true);
    setmute(false);
    props.disableHandler(true);
    let number = props.mobile;
    if (
      (callInProgress === "InComplete" || callInProgress !== "Completed") &&
      number !== "" &&
      !start
    ) {
      
      vonageApp.callServer(number);
      vonage_reconnect();
      if (
        callInProgress === "Cancelled" ||
        callInProgress === "TimeOut" ||
        callInProgress === "Failed" ||
        callInProgress === "Busy"
      ) {
        setProgress("InComplete");
      }
    } else if (callInProgress !== "Completed" && callStart) {
      callStatus.hangUp();
      setStart(callInProgress === "InComplete" ? true : false);
      setcallStart(false);
      props.disableHandler(false);
    } else if (callInProgress === "Completed") {
      // VonageInit(jwt);
      vonageApp.callServer(number);
      vonage_reconnect();
      props.disableHandler(true);
    }
  };
  let calling = null;

  if (!start && callInProgress === "InComplete") {
    calling = (
      <div className="d-flex flex-row align-items-center">
        <button
          type="button"
          className="btn text-uppercase h40 ml-auto btn_green min-width163"
          onClick={switchHandler}
          disabled={startcall ? false : true}
        >
          <CallSharpIcon className="f30" /> START CALL
        </button>
      </div>
    );
  } else if (
    callInProgress === "Cancelled" ||
    callInProgress === "TimeOut" ||
    callInProgress === "Failed" ||
    callInProgress === "Busy"
  ) {
    calling = (
      <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column mr-auto">
          <span className="weight_600 f16 font_white">
            {callInProgress === "Busy"
              ? "Call not answered"
              : "Call not connected"}
          </span>
        </div>
        <button
          type="button"
          className="btn text-uppercase h40 ml-auto btn_green min-width163"
          onClick={switchHandler}
        >
          <CallSharpIcon className="f30" /> RETRY
        </button>
      </div>
    );
  } else {
    if (start && callInProgress !== "Completed") {
      props.disableHandler(true);
      calling = (
        <div className="d-flex align-items-center">
          {callInProgress !== "Answered" ? (
            <div className="d-flex flex-row weight_600 f16 font_white align-content-center">
              Calling
              <span title={props.patientName}
                className="text-truncate pl-2"
                style={{ maxWidth: "45px" }}
              >
                {props.patientName}
              </span>
            </div>
          ) : (
            <div className="d-flex flex-column mr-auto">
              <span className="weight_600 f16 font_white">
                Call in progress...
              </span>
            </div>
          )}
          <div className="d-flex flex-row ml-auto">
            {!mute ? (
              <div>
                <button
                  type="button"
                  className="btn text-uppercase h40 min-width127 fwhite"
                  onClick={muteHandler}
                  disabled={callStart ? false : true}
                >
                  <MicIcon className="f30 font_white" /> MUTE
                </button>
              </div>
            ) : (
              <div>
                <button
                  type="button"
                  className="btn text-uppercase h40 min-width127 fwhite"
                  onClick={muteHandler}
                  disabled={callStart ? false : true}
                >
                  <MicOffIcon className="f30 font_white" /> UNMUTE
                </button>
              </div>
            )}
            <button
              type="button"
              className="btn text-uppercase h40 bg_red min-width163 ml-2"
              onClick={endconsultationpopuphandler}
              disabled={callStart ? false : true}
            >
              <CallEndIcon className="f30" /> END CALL
            </button>
          </div>
        </div>
      );
    } else {
      props.disableHandler(false);
      calling = (
        <>
          <div className="d-flex">
            <div className="d-flex flex-column mr-auto">
              <span className="weight_600 f16 font_white">Call ended</span>
            </div>
            <div>
              <button
                type="button"
                className="btn text-uppercase h40 bg_white min-width163 ml-2"
                onClick={switchHandler}
              >
                <PhoneCallbackIcon className="f30" /> RECONNECT
              </button>
            </div>
          </div>
        </>
      );
    }
  }
  
  return (
    <>
      <div className="col-12 mb-3">
        <div className="row no-gutters">
          <div className="col-12 p-3 bg_darkblue">{calling}</div>
          {
                    <EndConsultationPopUp
                      open={endcallconfirmationpopup}
                      anchorEl={anchorEl}
                      continueSession={continuesessionconfirmationclick}
                      endSession={endsessionconfirmationclick}
                    />
                  }
        </div>
      </div>
    </>
  );
}
