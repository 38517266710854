/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from "./../../Services/API/actions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../Store/actions/index";
import swal from 'sweetalert'
import moment from "moment";
import * as Constants from "./../../Utilities/Constants";
import { getAge,getTimezoneName } from "../../Utilities/Utilities";
import HourglassEmptySharpIcon from "@material-ui/icons/HourglassEmptySharp";
import AppointmentStatusModal from "./AppointmentStatus/AppointmentStatus";
import Alert from "./../Shared/Alert/Alerts";
import { Button, Grid, SvgIcon } from "@material-ui/core";
import _get from "lodash.get";
import EKGPreview from "../chart/EKGPreview";
import EkgPreviewModal from "./../Shared/EKG-preview/ekgPreview";
import Modal from '../Appointments/Calendar/AppointmentDetailsModal'
import AppointmentContent from '../AppointmentDetails/appointmentContent'
import '../../Assets/styles/bootstrap.scss';
import ReAssignAppointment from './ReAssignAppointment';
import { useTranslation } from "react-i18next";
import Authorize from "../Authorize";

const pattern = "YYYY-MM-DDTHH:mm:ss.SSS"
const useStyles = makeStyles((theme) => ({
  root: {},
  verticalgrid: {
    minHeight: 600,
  },
  cardHeader: {
    fontWeight: "600",
  },
  blackGrid: {
    fontWeight: "600",
    alignItems: "center",
    minHeight: "58px",
    padding: theme.spacing(1),
    background: "#E2E7E3",
    color: "#000000",
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  boldFont: {
    fontWeight: "600",
  },
  bolderFont: {
    fontWeight: "700",
  },
  cardlayout: {
    minHeight: 600,
    background: "#F1F1F1",
  },
  columngrid: {
    padding: 10,
    // overflow: "auto",
    background: "#FFFFFF",
  },
  cardlayoutmini: {
    minHeight: 280,
    background: "#F1F1F1",
  },
  bpm: {
    fontWeight: 700,
    color: "#000000",
    fontSize: "14px",
    fontFamily: "anonymous-pro",
  },
  bpmspan: {
    fontWeight: 600,
    color: "#c0c0c0",
    fontSize: "12px",
    paddingLeft: "4px",
  },
}));
const cssbutton = {
  position: "relative",
  top: 50,
  left: 97,
  right: 0,
  margin: "0 auto",
  height: "30px",
  width: "86px",
  display:
    "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
  justify: "space-around",
  alignItems: "center",
  border: "1px solid #EFEFEF",
  background: "#F2F4F8",
  paddingLeft: "5px",
  paddingRight: "5px",
  "--box-shadow": "0px 3px 4px 0px light-grey",
  "--border-bottom-left-radius": "8px",
  "--border-bottom-right-radius": "8px",
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#067F6F"),
    backgroundColor: "#067F6F",
    "&:hover": {
      backgroundColor: "#067F6F",
    },
  },
}))(Button);

const DarkColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#142A39"),
    backgroundColor: "#142A39",
    "&:hover": {
      backgroundColor: "#142A39",
    },
  },
}))(Button);

export default function DashBoardMiddleSection(props) {
  const { t } = useTranslation(); 
  const [showModal, setModal] = useState(false);
  const [startConsultationModal, setConsulationModal] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [recording,setRecording]= useState({})
  const patientDetailsntwrk = useSelector(
    (state) => state.appointments.patientDetails
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [reassignmodalOpen,setreassignmodal] = useState(false);
  const [consultationloader,setconsulationloader] = useState(false);
  const [heartConditions, setheartConditions] = React.useState([]);
  const [IsDisabled,setIsDisabled] = useState(false);
 
  useEffect(() => {
        if (patientDetailsntwrk.patient) {
      patientDetailsntwrk.patient["age"] = getAge(
        patientDetailsntwrk.patient.dob
      );
      if (patientDetailsntwrk.patient["height"])
        patientDetailsntwrk.patient["height"] = parseFloat(
          patientDetailsntwrk.patient["height"]
        ).toFixed(2);
      if (patientDetailsntwrk.patient["weight"])
        patientDetailsntwrk.patient["weight"] = parseFloat(
          patientDetailsntwrk.patient["weight"]
        ).toFixed(2);
    }
    if (patientDetailsntwrk.patientAppointment) {
      patientDetailsntwrk.patientAppointment["rangestring"] =
        moment(
          patientDetailsntwrk.patientAppointment.scheduledStartTime
        ).format("hh:mm A") +
        " to " +
        moment(patientDetailsntwrk.patientAppointment.scheduledEndTime).format(
          "hh:mm A"
        ) +
        " (" +
        (
          (new Date(
            patientDetailsntwrk.patientAppointment.scheduledEndTime
          ).getTime() -
            new Date(
              patientDetailsntwrk.patientAppointment.scheduledStartTime
            ).getTime()) /
          60000
        ).toString() +
        " Minutes)";
    }
    setPatientDetails(patientDetailsntwrk);
    setRecording(props.recording)
  }, [patientDetailsntwrk,props.recording]);
  useEffect(() => {
    let subscription1;
    if(heartConditions.length ===0)
    {
      subscription1 = API.heartConditions().subscribe(res => {
        if(res.data.result!=null){
            let conditions = [{
                id:0,
                heartCondition:"Select EKG Interpretation",
                heartConditionAdditionalText:"",
                
              },...res.data.result];
              setheartConditions(conditions);
        }
          let heartConditions = res.data.result.sort(function(a, b){
            return a.id - b.id;
        });
        // setoverReadReport(heartConditions[0].heartConditionAdditionalText?res.data.result[0].heartConditionAdditionalText:'');
        // setlastoverheadreportnote(heartConditions[0].heartConditionAdditionalText?res.data.result[0].heartConditionAdditionalText:'');
      }, error => {
          console.log;
       })
      }
  return () => {
      if (subscription1)
          subscription1.unsubscribe()
  }

}, [])


useEffect(() => {
  let subscription;
  if (Object(props.id)!== props.id && props.id) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
     subscription = API.getAppointmentNotes(
      teamId,
      clinicianId,
      props.id
    ).subscribe(
      (res) => {
        if (res.data.appointmentNote) {
          setconsultationNote(decodeURI(res.data.appointmentNote));
          setlastconsultationNote(decodeURI(res.data.appointmentNote));
        } else setconsultationNote("");
      },
      // eslint-disable-next-line no-console
      (error) => console.log(error)
    );
  }

  return () => {
    if (subscription) subscription.unsubscribe();
  };
}, [props.id]);


  const handleClose = () => {
      setModal(false);
  };

  const handleConsultationClose = () => {
    setconsulationloader(true);
    if (
      (overReadReport.length > 0 && consultationSummary.length > 0) || 
      (overReadReport.length > 0 && seriespattern!=="Completed") ||
      (overReadReport.length>0 &&(_get(patientDetails,"patientAppointment.closedReasonType") ===2 || 
      _get(patientDetails,"patientAppointment.closedReasonType") ===3))) {
      let body = {
        appointmentStatus:
          seriespattern === "Completed"
            ? Constants.AppointmentStatusTypes.Completed
            : Constants.AppointmentStatusTypes.closed,
        consultationSummaryNotes: encodeURI(consultationSummary),
        followup: period,
        heartConditionId: id,
        overReadReportNotes: encodeURI(overReadReport),
        patientId: _get(patientDetails, "patient.patientId"),
        treatmentPlan: encodeURI(targetPlan),
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
      };
      setIsDisabled(true);
      API.postConsultation(teamId,clinicianId, props.id, body).subscribe(
        (res) => {
          setIsDisabled(false);
          if("detailed_error" in res.data){
            props.error(res.data["detailed_error"]);
            setconsulationloader(false);
          }
          else{
            setconsulationloader(false);
            swal({
              closeOnClickOutside: false,
              icon: "success",
              title:"Consultation Completed" ,
              text: "Your consultation has been successfully completed!",
              className:"telekardia",
              buttons: {
                confirm: {
                  text: "OKAY",
                  visible: true,
                  className: "swal_ok swal_green",
                  closeModal: true,
                },
              },
            }).then((value) => {
              if (value) {
                setConsulationModal(false);
                let appointmentqueryParams = {
                  startDateTime: moment(moment().startOf("day")._d).format(pattern),
                  endDateTime: moment(moment().endOf("day")._d).format(pattern),
                  offsetInMinutes: new Date().getTimezoneOffset(),
                  timeZone:getTimezoneName(),
                  appointmentStatus: "1,2,3,6,7,9",
                };
                let queryParams = {
                  startDate: moment(moment().startOf("day")._d).format(pattern),
                  endDate: moment(moment().endOf("day")._d).format(pattern),
                  offsetInMinutes: new Date().getTimezoneOffset(),
                  timeZone:getTimezoneName(),
                };
                dispatch(
                  actions.getAppointments(teamId,clinicianId, appointmentqueryParams)
                );
                dispatch(
                  actions.getCliniciansAppointments(
                    teamId,
                    clinicianId,
                    queryParams
                  ));
                props.loader();
              }
            });
          }
        
        },
        (error) => {
          setconsulationloader(false);
          setIsDisabled(false);
          props.error("Unable to process this request due to internal server error.")
        }
      );
     
    } else {
      setconsulationloader(false);
      seterrorAlert(
        <Alert
          date={new Date()}
          message={seriespattern!=="Completed" || _get(patientDetails,"patientAppointment.closedReasonType")===2 
          || _get(patientDetails,"patientAppointment.closedReasonType")===3 || id ===0
          ?"Please select the EKG interpretation to proceed to the next step.":"Please enter the EKG Summary and Patient Note to proceed to the next step."}
        /> 
      );
      if(id ===0 )
        setIsError(true);
    }
  };
  const setError=(message)=>{
    seterrorAlert(
      <Alert
        date={new Date()}
        message={message}
      />
    );
  }
  const setIsError=(error)=>{
    setisError(error);
  }
  const openHandler = () => {
    setModal(true);
  };
  let modal = null;
  if (showModal && recording)
    modal = (
      <EkgPreviewModal
        open={showModal}
        recording={recording}
        handleClose={handleClose}
      />
    );
  const [errorAlert, seterrorAlert] = useState(null);
  let event_time =
      "patientAppointment" in patientDetails
        ? patientDetails.patientAppointment.scheduledStartTime
        : null;
  const deleteAppointmentHandler = () => {
    let current_time = new Date();
    if (new Date(current_time) > new Date(event_time)) {
      props.error("Past consultations cannot be cancelled");
    } else {
      setreassignmodal(false);
          let queryParams = {
            startDateTime: moment(moment().startOf("day")._d).format(pattern),
            endDateTime: moment(moment().endOf("day")._d).format(pattern),
            offsetInMinutes: new Date().getTimezoneOffset(),
            timeZone:getTimezoneName(),
          };
          let appointmentqueryParams = {
            startDateTime: moment(moment().startOf("day")._d).format(pattern),
            endDateTime: moment(moment().endOf("day")._d).format(pattern),
            offsetInMinutes: new Date().getTimezoneOffset(),
            timeZone:getTimezoneName(),
            appointmentStatus: "1,2,3,6,7,9",
          };
          let dashboardmetricsParams = {
            startDate: moment(moment().startOf("day")._d).format(pattern),
            endDate: moment(moment().endOf("day")._d).format(pattern),
            offsetInMinutes: new Date().getTimezoneOffset(),
            timeZone:getTimezoneName(),
          };
          let deleteparams ={
            version:patientDetails.version
          }
          dispatch(
            actions.deleteAppointment(
              props.id,
              clinicianId,
              teamId,
              queryParams,
              appointmentqueryParams,
              "dashboardmiddlesection",
              dashboardmetricsParams,
              deleteparams
            )
          );
          API.getMetrics(
            teamId,
            clinicianId,
            dashboardmetricsParams
          ).subscribe();
          setConsulationModal(false);
          props.loader();
    }
  };
  const reassignAppointment =async ()=>{
    let version = "patientAppointment" in patientDetails
    ? patientDetails.patientAppointment.version
    : null;
    await API.appointmentStatusCheck(teamId,clinicianId,props.id,version).subscribe(
      (res) => {  
        if (res.data.result.reassigned || res.data.result.rescheduled 
          || res.data.result.cancelled || res.data.result.closed) {
          swal({
            closeOnClickOutside: false,
            title: res.data.result.title,
            text: res.data.result.subTitle,
            className: "telekardia",
            buttons: {
              cancel: {
                visible: true,
                text:"",
                className: "swal_cancel swal_black swal_close",
                closeModal: true,
              },
              confirm: {
                text: "OKAY",
                visible: true,
                className: "swal_ok swal_green",
                closeModal: true,
              },
            },
          }).then((res) => {
           
              props.loader();
           
          });
        }       
        else{
          setreassignmodal(true);
        }
          },
          (error) => props.error("Unable to process this request due to internal server error."));
  }
  const reassignmodalHandler =()=>{
    setreassignmodal(false);
    setConsulationModal(false);
  }
  const changetabvalue =(event, newValue)=>{
    settabvalue(newValue);
  }
  
  const alertHandler = async() => {
    let version = "patientAppointment" in patientDetails
    ? patientDetails.patientAppointment.version
    : null;
    let event_time =
      "patientAppointment" in patientDetails
        ? patientDetails.patientAppointment.scheduledStartTime
        : null;
    let event_endTime =
      "patientAppointment" in patientDetails
        ? patientDetails.patientAppointment.scheduledEndTime
        : null;
    let current_time = new Date();
    await API.appointmentStatusCheck(teamId,clinicianId,props.id,version).subscribe(
      (res) => {     
    
     if (res.data.result.reassigned || res.data.result.rescheduled 
      || res.data.result.cancelled || res.data.result.closed) {
      swal({
        closeOnClickOutside: false,
        title: res.data.result.title,
        text: res.data.result.subTitle,
        className: "telekardia",
        buttons: {
          cancel: {
            visible: true,
            text:"",
            className: "swal_cancel swal_black swal_close",
            closeModal: true,
          },
          confirm: {
            text: "OKAY",
            visible: true,
            className: "swal_ok swal_green",
            closeModal: true,
          },
        },
      }).then((res) => {
       
          props.loader();
       
      });
    }
    else{// if(current_time.getTime()>new Date(event_time).getTime()){
      swal({
        closeOnClickOutside: false,
        title: checkStatus === "Scheduled" ? t("Start Consultation") : t("Continue Consultation"),
        text: checkStatus === "Scheduled" ? t("Are you sure you want to start the consultation with") +"  "+ _get(patientDetails,"patient.firstName") +" "+ _get(patientDetails,"patient.lastName") +"?"
        : t("Are you sure you want to continue the consultation with") +"  " +_get(patientDetails,"patient.firstName") +" "+ _get(patientDetails,"patient.lastName") +"?",
        className: "telekardia",
        buttons: {
          cancel: {
            visible: true,
            text:"",
            className: "swal_cancel swal_black swal_close",
            closeModal: true,
          },
          confirm: {
            text: checkStatus === "Scheduled" ? t("START CONSULTATION") : t("CONTINUE CONSULTATION"),
            visible: true,
            className: "swal_ok swal_green",
            closeModal: true,
          },
        },
      }).then((res) => {
        if (res) {
          if (stepper === 1 && checkStatus === "Scheduled") {
            setStepper(1);
  
            // update appointment status
            let body = {
              appointmentStatus:
                Constants.AppointmentStatusTypes.Physician_Review,
              timeZoneOffset: new Date().getTimezoneOffset(),
              timeZone:getTimezoneName(),
            };
  
            API.updateAppointmentStatus(teamId,clinicianId,props.id, body).subscribe(
              (res) => {},
              (error) => {}
            );
            setConsulationModal(true);
          } else {
            setConsulationModal(true);
          }
        }
      });
    }
    // else{
    //   let message= "You cannot start the consultation now. You can only start the consultation at "+ moment(event_time).format('LT')
    //   props.error(message);
    // }
      },
      (error) => props.error("Unable to process this request due to internal server error."));
    };
  const [consultationNote, setconsultationNote] = useState("");
  const [overReadReport, setoverReadReport] = useState("");
  const [consultationSummary, setconsultationSummary] = useState("");
  const [targetPlan, settargetPlan] = useState("");
  const [lastconsultationNote, setlastconsultationNote] = React.useState("");
  const [lastoverheadreportnote, setlastoverheadreportnote] = React.useState("");
  const [lasttreatmentPlannote, setlasttreatmentPlannote] = React.useState("");
  const [lastconsultationSummaryNotes, setlastconsultationSummaryNotes] = React.useState("");
  const [period, setperiod] = React.useState("No Follow-up");
  const [id, setid] = React.useState(0);
  const [isError,setisError] = useState(false);
  const updateConsultationNote = (note) => {
    setconsultationNote(note.target.value);
  };
  //AppoinmentStatusRelated Content

  const [statusModal, setstatusModal] = useState(false);
  const [stepper, setStepper] = useState(1);
  const [tabvalue, settabvalue] = useState(0);
  const [seriespattern, setseriespattern] = useState("Completed");
  const [Reason, setReason] = useState("Patient did not turn up");
  const handleRadioChange = (event) => {
    setseriespattern(event.target.value);
  };
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };
  const checkStatus = _get(
    patientDetails,
    "patientAppointment.appointmentStatus"
  ) || null;
  const getAppointmentId = _get(patientDetails, "appointmentId");

  const AUTOSAVE_INTERVAL = 3000;
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (lastconsultationNote != consultationNote) {
             //consultation notes save
        API.setAppointmentNotes(
          teamId,
          clinicianId,
          patientDetails.appointmentId,
          decodeURI(consultationNote)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastconsultationNote(consultationNote);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [consultationNote]);

  useEffect(() => {
    const timer2 = setTimeout(() => {
      if (lastoverheadreportnote != overReadReport) {
        API.setOverReadReport(
          teamId,
          clinicianId,
          patientDetails.appointmentId,
          decodeURI(overReadReport)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastoverheadreportnote(overReadReport);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer2);
  }, [overReadReport]);

  useEffect(() => {
    const timer3 = setTimeout(() => {
      if (lasttreatmentPlannote != targetPlan) {
        API.settreatmentPlan(
          teamId,
          clinicianId,
          patientDetails.appointmentId,
          decodeURI(targetPlan)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlasttreatmentPlannote(targetPlan);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer3);
  }, [targetPlan]);


  useEffect(() => {
    const timer4 = setTimeout(() => {
      if (lastconsultationSummaryNotes != consultationSummary) {
             //consultation notes save
        API.setconsultationSummaryNotes(
          teamId,
          clinicianId,
          patientDetails.appointmentId,
          decodeURI(consultationSummary)
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        setlastconsultationSummaryNotes(consultationSummary);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer4);
  }, [consultationSummary]);


  
  
  const stepperNext = () => {
    
    if (stepper === 1 && checkStatus !== "InProgress") {
      setStepper(stepper + 1);
      //update status to  in progress
      let body = {
        appointmentStatus: Constants.AppointmentStatusTypes.InProgress,
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
      };

      API.updateAppointmentStatus(teamId,clinicianId,props.id, body).subscribe(
        (res) => {},
        (error) => {}
      );
    } else if (stepper === 2 || checkStatus === "InProgress") {
      setstatusModal(true);
    } else {
      setStepper(1);
    }
  };

  const handlestatusConfirm = () => {
    let body = null;
    if ((Reason === "Patient did not turn up" || Reason ===  "Patient declined consultation") && seriespattern !== "Completed") {
      body = {
        appointmentReasonType: Constants.AppointmentClosedReasons[Reason],
        appointmentStatus: Constants.AppointmentStatusTypes.closed,
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
      };

      API.updateAppointmentStatus(teamId,clinicianId,props.id, body).subscribe(
        (res) => {},
        (error) => {}
      );
      let params = {
        startDateTime: moment(moment().startOf("day")._d).format(pattern),
        endDateTime: moment(moment().endOf("day")._d).format(pattern),
        offsetInMinutes: new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
        appointmentStatus: "1,2,3,6,7,9",
      };
      statusHandleClose();
      setConsulationModal(false);
      setTimeout(() => {
        dispatch(actions.getAppointments(teamId,clinicianId, params));
        props.loader();
      }, 1500);
    } else {
      body = {
        appointmentStatus:
          Constants.AppointmentStatusTypes.Encounter_Summary_Preparation,
        appointmentReasonType:
          seriespattern !== "Completed"
            ? Constants.AppointmentClosedReasons[Reason]
            : "",
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
      };

      API.updateAppointmentStatus(teamId,clinicianId,props.id, body).subscribe(
        (res) => {},
        (error) => {}
      );
      setStepper(3);
      statusHandleClose();
    }
  };
  const statusHandleClose = () => {
    setstatusModal(false);
  };
  let appointmentStatusModal = null;
  if (statusModal===true) {
    appointmentStatusModal = (
      <AppointmentStatusModal
        open={statusModal}
        close={statusHandleClose}
        onConfirm={handlestatusConfirm}
        seriespattern={seriespattern}
        Reason={Reason}
        handleRadioChange={handleRadioChange}
        handleReasonChange={handleReasonChange}
      />
    );
  }

  
  const handleidChange = (event) => {
    setid(event.target.value);
    if(event.target.value!==0){
      API.setheartConditionId(
        teamId,
        clinicianId,
        patientDetails.appointmentId,
        event.target.value
      ).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error)
      );
      let selectedCondition = heartConditions.filter(x=>x.id===event.target.value);
      setoverReadReport(selectedCondition[0].heartConditionAdditionalText?selectedCondition[0].heartConditionAdditionalText:'');
    }
    else{
      setoverReadReport("");
    }
  };
  
  const handleperiodChange = (event) => {
    API.setdurationText(
      teamId,
      clinicianId,
      patientDetails.appointmentId,
      event.target.value
    ).subscribe(
      (res) => {
        // do nothing
      },
      (error) => console.log(error)
    );
    setperiod(event.target.value);
  };

  const updateoverReadReport = (note) => {
    setoverReadReport(note.target.value);
  };
  
  const updateconsultationSummary = (note) => {
    // auto save logic here
    setconsultationSummary(note.target.value);
  };

  const updatetargetplan = (note) => {
    // auto save logic here for update treatment plan
    settargetPlan(note.target.value);
  };
  //consultationModalRelated
  const [startcalldisable,setstartcalldisable] = useState(false);
  const disableHandler = (prps)=>{
      setstartcalldisable(prps);
  }
  let consultationmodal = null;
  if ("patientAppointment" in patientDetails && startConsultationModal===true) {
    consultationmodal = (
      <Modal
        show={startConsultationModal}
        handleClose={handleConsultationClose}
        view={"consultation"}
        clicked={stepperNext}
        onSubmit={reassignAppointment}
        consultationloader={consultationloader}
        steppervalue={stepper}
        status={checkStatus}
        disableHandler={startcalldisable}
        disable={new Date() > new Date(event_time)?true:false}
      >
        <div className="right_content">
          <div className="container-fluid">
            <div className="row m-0 mt-2">
              <AppointmentContent
                {...props}
                clicked={stepperNext}
                steppervalue={stepper}
                status={checkStatus}
                appointmentStatus={checkStatus}
                setConsultationNote={updateConsultationNote}
                consultationNote={consultationNote}
                id={id}
                IsDisabled={IsDisabled}
                setError={setError}
                isError={isError}
                setisError={setIsError}
                Reason={Reason}
                appointmentId={getAppointmentId}
                handleidChange={handleidChange}
                period={period}
                handleperiodChange={handleperiodChange}
                overReadReport={overReadReport}
                updateoverReadReport={updateoverReadReport}
                consultationSummary={consultationSummary}
                updateconsultationSummary={updateconsultationSummary}
                targetPlan={targetPlan}
                updatetargetplan={updatetargetplan}
                alert={errorAlert}
                consultationloader={consultationloader}
                disableHandler={disableHandler}
                startcalldisable={startcalldisable}
                heartConditions={heartConditions}
                disable={new Date() > new Date(event_time)?true:false}
                handleClose={handleConsultationClose}
                onSubmit={reassignAppointment}
                tabvalue={tabvalue}
                changetabvalue={changetabvalue}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  
  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      setModal(false);
      
      setConsulationModal(false);
      setstatusModal(false);
      setreassignmodal(false);
    };
  }, []);

  return (
    <div className="telekardia">
      {((props.appointments && props.appointments.length > 0) && props.patientDetails) ?(
        <div className="mx-2 mb-4 mt-md-4 mt-lg-4 mt-xl-5 height-100v">
          <div className="row no-gutters mb-md-2 mb-lg-2 mb-3">
            <div className="order-md-2 order-lg-2 order-xl-1 col-lg-12 col-xl-5">
              <h5 className="head">
                {(_get(patientDetails, "patient.firstName") || "-") +
                  " " +
                  (_get(patientDetails, "patient.lastName") || "-")}
              </h5>
            </div>
            <div className="order-md-1 order-lg-1 order-xl-2 col-lg-12 col-xl-7 mb-md-3 mb-lg-3 text-right">
              <Authorize>
              <button
                  type="button"
                  className="btn m-1 px-3 btn-outline-primary text-uppercase" onClick={reassignAppointment} disabled={
                "patientAppointment" in patientDetails &&
                new Date(
                    patientDetails.patientAppointment.scheduledStartTime
                ).getTime() > new Date().getTime() && (_get(patientDetails, "patientAppointment.appointmentStatus")==="Scheduled" 
                || _get(patientDetails, "patientAppointment.appointmentStatus")==="Physician-Review")
                    ? false
                    : true
              }>
                {t("REASSIGN")} <span className="d-none d-lg-none d-xl-inline-block">{t("CONSULTATION")}</span>
              </button>
              </Authorize>
              <button
                  type="button"
                  className="btn m-1 btn_teal py-2 px-4" onClick={alertHandler} 
              >
                {checkStatus === "Completed" || checkStatus === "Scheduled" || checkStatus===null
                    ? t("START CONSULTATION")
                    : t("CONTINUE CONSULTATION")}
              </button>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-xl-3">
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t("Age")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(patientDetails, "patient.age")?_get(patientDetails, "patient.age") + " years" : "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t("Gender")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(patientDetails, "patient.gender") || "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t("Weight")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                  {_get(patientDetails, "patient.weight")?_get(patientDetails, "patient.weight")<= 0 ?"0": (_get(patientDetails, "patient.weight") + " lb") : "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t("Height")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {" "}
                    {patientDetails && _get(patientDetails, "patient.height")
                      ? Math.floor(
                          _get(patientDetails, "patient.height") / 30.48
                        ) +
                        "'" +
                        " " +
                        (Math.floor(
                          _get(patientDetails, "patient.height") / 2.54
                        ) -
                          12 *
                            Math.floor(
                              _get(patientDetails, "patient.height") / 30.48
                            )) +
                        '"'
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t("BMI")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(patientDetails, "patient.bmi") || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-9">
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t("Email")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {" "}
                    {_get(patientDetails, "patient.email") || "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t("Contact")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {" "}
                    {_get(patientDetails, "patient.phone") || "-"}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t("Location")}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {" "}
                    {_get(patientDetails, "patient.location") || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row no-gutters">
            <div className="col-12 col-xl-4 mb-4">
              <h5 className="heading">{t("Consultation Time")}</h5>
              <div>
                {_get(patientDetails, "patientAppointment.rangestring") ||
                  "-"}
              </div>
            </div>
            <div className="col-12 col-xl-4 mb-4">
              <h5 className="heading">{t("Method of Consultation")}</h5>
              <div>
                {_get(
                  patientDetails,
                  "patientAppointment.appointmentChannelType"
                ) || "-"}
              </div>
            </div>
            <div className="col-12 col-xl-4 mb-4">
              <h5 className="heading">{t("Type of Consultation")}</h5>
              <div>
                {_get(patientDetails, "patientAppointment.appointmentType") ||
                  "-"}
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 mb-4">
              <h5 className="heading">{t("Reason for Consultation")}</h5>
              <div>{_get(patientDetails, "visitReason") || "-"}</div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12">
              <h5 className="heading">{t("Symptoms")}</h5>
              <div>
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{t("Symptom")}</th>
                      <th scope="col">{t("Severity")} </th>
                      <th scope="col">{t("Duration")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(patientDetails).length > 0 &&
                    patientDetails.symptoms &&
                    patientDetails.symptoms.length > 0 ? (
                      patientDetails.symptoms.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.severity}</td>
                          <td>{item.duration}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>{"-"}</td>
                        <td>{"-"}</td>
                        <td>{"-"}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />
          <div className="row no-gutters">
            <div className="col-12 mb-4 home_EKGreading">
              <h5 className="heading">EKG Reading</h5>
              {recording && recording.previewData && (
                <Button
                  variant="contained"
                  color="default"
                  style={cssbutton}
                  startIcon={
                    <SvgIcon
                      style={{
                        margin: "5px",
                        width: "25px",
                        height: "25px",
                      }}
                      // color="#ffff"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#d0021b"
                        d="M17,0c-1.9,0-3.7,0.8-5,2.1C10.7,0.8,8.9,0,7,0C3.1,0,0,3.1,0,7c0,6.4,10.9,15.4,11.4,15.8 c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2C13.1,22.4,24,13.4,24,7C24,3.1,20.9,0,17,0z"
                      />
                    </SvgIcon>
                  }
                >
                  <Grid container direction="column">
                    <Grid item className={classes.bpm}>{_get(recording,"bpm")
                        ? _get(recording,"bpm")
                        : "-"}</Grid>
                    <Grid item className={classes.bpmspan}>bpm</Grid>
                  </Grid>
                </Button>
              )}
              <div className="my-3" onClick={recording && recording.previewData?openHandler:null}>
                {recording && recording.previewData ? (
                  <div>
                    <EKGPreview
                      width={650}
                      height={80}
                      previewData={recording.previewData}
                    />
                  </div>
                ) : (
                  <div className="noekgtext">{t("EKG recording not found")}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-xl-3 mb-4">
              <h5 className="heading">{t("EKG Tags")}</h5>
              <div>
                {" "}
                {recording &&
                recording.tags &&
                recording.tags.length > 0
                  ? recording.tags.map((item, index) => (
                      <ul className="list-unstyled mb-0" key={index}>
                        <li className="my-2">{item}</li>
                      </ul>
                    ))
                  : "-"}
              </div>
            </div>
            <div className="col-12 col-xl-5 mb-0 mb-xl-4">
              <h5 className="heading">{t("EKG Details")}</h5>
              <div>
                <ul className="list-unstyled mb-0">
                  <li className="my-2">
                    Heart Rate:
                    <span>
                      {" "}
                      {_get(recording,"bpm")
                        ? _get(recording,"bpm") +" BPM"
                        : "-"}{" "}
                    </span>
                  </li>
                  <li className="my-2">
                  {t("Recorded Date")}:{" "}
                    <span>
                      {" "}
                      {_get(recording,"recordedAt")? 
                        moment(recording.recordedAt).format(
                            "MM-DD-yyyy"
                          )
                        : "-"}
                    </span>
                  </li>
                  <li className="my-2">
                  {t("Recorded Time")}: <span>{_get(recording,"recordedAt")? 
                        moment(recording.recordedAt).format(
                            "LT"
                          )
                        : "-"}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-4 mb-0 mb-xl-4">
              <h5 className="heading">{t("EKG Notes")}</h5>
              <div>
                {" "}
                {recording &&
                recording.note 
                  ? recording.note
                  : "-"}
              </div>
            </div>
          </div>
          <hr />
          <div className="row no-gutters">
            <div className="col-12 col-xl-6 pr-1 mb-4">
              <h5 className="heading">{t("Medical History")}</h5>
              <div>{_get(patientDetails, "medicalHistory") || "-"}</div>
            </div>
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t("Family History")}</h5>
              <div>
                <ul className="list-unstyled mb-0">
                  <li className="my-2">
                    {_get(patientDetails, "familyMedicalHistory") || "-"}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-6 mb-4 pr-1">
              <h5 className="heading">{t("Social History")}</h5>
              <div>
                <ul className="list-unstyled mb-0">
                  {Object.keys(patientDetails).length > 0 &&
                   patientDetails.lifeStyleHistoryStr ? (
                    patientDetails.lifeStyleHistoryStr
                  // patientDetails.lifeStyleHistory &&
                  // patientDetails.lifeStyleHistory.length > 0 ? (
                  //   patientDetails.lifeStyleHistory.map((item, index) => (
                  //     <li className="my-2" key={index}>
                  //       {item.name}
                  //     </li>
                  //   ))
                  ) : (
                    <li className="my-2"> - </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t("Notes from patient")}</h5>
              <div>
                <ul className="list-unstyled mb-0">
                  <li className="my-2">
                    {_get(patientDetails, "patientNotes") || "-"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row no-gutters pr-1">
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t("Allergies")}</h5>
              <div>{_get(patientDetails, "allergyDetails") || "-"}</div>
            </div>
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t("Surgeries")}</h5>
              <div>{_get(patientDetails, "surgeryDetails") || "-"}</div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12">
              <h5 className="heading">{t("Medications")}</h5>
              <div>
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{t("Medication Name")}</th>
                      <th scope="col">{t("Dose")} </th>
                      <th scope="col">{t("Unit")}</th>
                      <th scope="col">{t("Frequency")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(patientDetails).length > 0 &&
                    patientDetails.medicationsArray &&
                    patientDetails.medicationsArray.length > 0 ? (
                      patientDetails.medicationsArray.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.dose}</td>
                          <td>{item.unit}</td>
                          <td>{item.frequency}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>{"-"}</td>
                        <td>{"-"}</td>
                        <td>{"-"}</td>
                        <td>{"-"}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          {modal}
          {consultationmodal}
          {appointmentStatusModal}
          {reassignmodalOpen===true &&
          <ReAssignAppointment show={reassignmodalOpen}
          teamId={teamId}
           handleClose={reassignmodalHandler} onCancel={deleteAppointmentHandler} 
           patientDetails={patientDetails} {...props}/>
          }
        </div>
      ) : (
        <div className="row no-gutters noappt_content">
          <div className="col-12 col-md-8 col-lg-8 col-xl-6 mx-auto text-center">
            <div className="hour_glass bg_green w176 mx-auto">
              <HourglassEmptySharpIcon />
            </div>
            <h5 className="heading my-3">{t("No Consultations")}</h5>
            <p>{t("You do not have any consultations scheduled for today")}</p>
          </div>
        </div>
      )}
    </div>
  );
}
